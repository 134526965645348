import React, { useState } from "react";
import { RemarkMessage } from "./messageListItems/RemarkMessage";
import { MultiChoiceMessage } from "./messageListItems/MultiChoiceMessage";
import { KansouSupportChatMessages } from "~/utils/kansouSupportChat";
import { MessageEnum } from "~/helpers/constants/kansouSupportChat";

export type KansouSupportChatListItemProps = {
  item: KansouSupportChatMessages;
  latestMessageID?: string;
  onUpload: () => void;
  onSubmit: (event: React.MouseEvent, value: string) => void;
};

export const KansouSupportChatListItem: React.FC<
  KansouSupportChatListItemProps
> = ({ item, latestMessageID, onUpload, onSubmit }) => {
  const [values, setValues] = useState<number[]>([]);

  if (item.type === MessageEnum.choice) {
    return (
      <MultiChoiceMessage
        message={item}
        latestMessageID={latestMessageID}
        buttonText="OK"
        onSubmit={(e: React.MouseEvent) => {
          if (item.choices) {
            const choices = values.map((v) => {
              if (!item.choices) {
                return;
              }
              return item.choices[v].choice;
            });
            const choice = choices.join("");
            onSubmit(e, choice);
          }
        }}
        onCheck={(i) => {
          if (values.indexOf(i) > -1) {
            setValues(values.filter((v) => v !== i));
          } else {
            setValues([...values, i]);
          }
        }}
        values={values}
      />
    );
  }
  return (
    <RemarkMessage
      message={item}
      latestMessageID={latestMessageID}
      onUpload={onUpload}
    />
  );
};
