import React from "react";
import styled from "styled-components";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "~/i18n";

export type TutorialHasUsedKansouSupportChatModalProps = {
  isOpen: boolean;
  loading: boolean;
  onNext: () => void;
  onClose: () => void;
};

export const TutorialHasUsedKansouSupportChatModal: React.FC<
  TutorialHasUsedKansouSupportChatModalProps
> = ({ isOpen, loading, onNext, onClose }) => {
  const { t } = useTranslation();

  return (
    <BaseModal open={isOpen} onClose={onClose}>
      <_Wrapper rowGap="16px">
        <Text fontSize="LG" lineHeight="MD" bold>
          {t<TranslateKeys>(
            "ペンギンせんぱいと  お話しながら、感想が  書けるよ"
          )}
        </Text>
        <Text fontSize="SM" lineHeight="EQ">
          {t<TranslateKeys>("1日  2回まで  つかえるよ！")}
        </Text>
        <Img src="/img/tutorial/kansou_support_chat.png" />
        <_ButtonWrapper>
          <LargeButton onClick={onNext} disabled={loading}>
            つかってみる（テストばん）
          </LargeButton>
          <LargeButton onClick={onClose} color="tertiary" variant="blank">
            やめる
          </LargeButton>
        </_ButtonWrapper>
      </_Wrapper>
    </BaseModal>
  );
};

const _Wrapper = styled(Stack)`
  width: 100%;
  max-width: 400px;
`;

const _ButtonWrapper = styled(Stack)``;

const Img = styled.img`
  width: 100%;
  height: auto;
`;
