import React from "react";
import styled from "styled-components";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Text, TextProps } from "~/components/atoms/texts/Text";
import { PaddingProps } from "~/styles/design_token/padding";
import { Row } from "~/components/atoms/layout/Row";
import { Stack } from "~/components/atoms/layout/Stack";

export type CharacterIconTextButtonProps = TextProps & {
  onClick?: (e: React.MouseEvent) => void;
  text: string;
  imageUrl: string;
  tagText?: string;
  disabled?: boolean;
} & PaddingProps;

export const CharacterIconTextButton: React.FC<
  CharacterIconTextButtonProps
> = ({ onClick, text, imageUrl, tagText, disabled, ...textProps }) => {
  return (
    <LargeButton color="primary" onClick={onClick} disabled={disabled}>
      <_Row alignItems="center" columnGap="16px">
        <CharacterIcon>
          <img src={imageUrl} />
        </CharacterIcon>
        <_Stack>
          {tagText && (
            <TagIcon fontSize="SM" lineHeight={"EQ"} bold>
              {tagText}
            </TagIcon>
          )}
          <_Text {...textProps}>{text}</_Text>
        </_Stack>
      </_Row>
    </LargeButton>
  );
};

const _Row = styled(Row)``;

const _Stack = styled(Stack)`
  display: inline-flex;
  align-items: flex-start;
`;

const CharacterIcon = styled.div`
  width: 80px;
  height: 80px;
  padding: 10px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.primitive.monotone.m0};
  border-radius: 50%;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const _Text = styled(Text)`
  width: 200px;
  color: inherit;
  text-align: left;
`;

const TagIcon = styled(Text)`
  padding: 4px 12px;
  border-radius: 100px;
  color: ${({ theme }) => theme.colors.primitive.orange.o600};
  background-color: ${({ theme }) => theme.colors.primitive.monotone.m0};
`;
