export enum MessageEnum {
  image = "IMAGE",
  kansou = "KANSOU",
  choice = "CHOICE",
}

export const MESSAGE: Record<MessageEnum, string> = {
  IMAGE: "$image-upload$",
  KANSOU: "$kansou$",
  CHOICE: "$choice$",
};

export type MessageType = keyof typeof MESSAGE;

export enum MessageRoleEnum {
  user = "USER",
  assistant = "ASSISTANT",
}

export const MESSAGE_ROLE: Record<MessageRoleEnum, string> = {
  USER: "user",
  ASSISTANT: "assistant",
};

export type MessageRoleType = keyof typeof MESSAGE_ROLE;

export const messageTypeRegex = /(\$image-upload\$|\$kansou\$|\$choice\$)/g;

export const choiceRegex = /(\d\.\s.*)/g;

export const kansouRegex =
  /〜〜〜〜〜〜〜〜〜〜〜〜([\s\S]*)〜〜〜〜〜〜〜〜〜〜〜〜/;
