import React from "react";
import styled from "styled-components";
import { KansouPresentTemplateProps } from "./type";
import { ProgressBar } from "~/components/atoms/ProgressBar";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { NavContainer, Wrapper } from "../../Common";
import { PostReadLogBodyWrapper } from "../Common/style";
import { KansouSupportChatPresent } from "~/components/organisms/PostReadLog/KansouSupportChatPresent";
import { KansouSupportChatPresentLoading } from "~/components/organisms/PostReadLog/KansouSupportChatPresentLoading";

export const KansouPresentTemplate: React.FC<KansouPresentTemplateProps> = ({
  progress,
  imageUrl,
  kansou,
  nickname,
  onNext,
}) => {
  return (
    <>
      <NavContainer>
        <BackNavbar titleName="プレゼント" disabled={true} onClick={() => {}} />
      </NavContainer>
      <Wrapper>
        <PostReadLogBodyWrapper>
          {imageUrl ? (
            <>
              <ProgressCon>
                <ProgressBar progress={progress} backgroundColor="base.gray" />
              </ProgressCon>
              <KansouSupportChatPresent
                imageUrl={imageUrl}
                kansou={kansou}
                nickname={nickname}
                onNext={onNext}
              />
            </>
          ) : (
            <KansouSupportChatPresentLoading />
          )}
        </PostReadLogBodyWrapper>
      </Wrapper>
    </>
  );
};

const ProgressCon = styled.div`
  margin-bottom: 20px;
`;
