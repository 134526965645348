import React, { FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReadLogsProps } from "~/components/organisms/ReadLogs";
import { LoadingPage } from "~/components/templates/Loading/LoadingPage";
import { BookDetailInfoTemplate } from "~/components/templates/BookDetailInfo";
import { config } from "../../../config";
import {
  RecommendStatus,
  TutorialFlagKey,
  useGetOneRecommendInfoQuery,
} from "../../generated/graphql";
import { useCalil } from "../../store/bookViewer/useCalil";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";

export const ViewRecommendStatus: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { calilSearchWordModal, handleClickGoToCalil } = useCalil();

  const begin = 0;
  const navigate = useNavigate();
  const { loading, error, data } = useGetOneRecommendInfoQuery({
    variables: {
      recommendID: Number(id),
      begin: begin,
      end: begin + config.LIST_LENGTH,
    },
    fetchPolicy: "no-cache",
  });

  const { tutorialFlags, getTutorialFlag, saveTutorialFlags } =
    React.useContext(CurrentUserContext);

  const handlePostReadLog = useCallback(() => {
    const book = data?.me.recommends.get[0].book;
    if (!book) {
      return;
    }

    const searchParams = new URLSearchParams({
      recommendID: id,
      title: book.title ? book.title : "",
      ISBN: book.isbn,
      flow: "confirmReadStatus",
    });

    navigate(`/postreadlog/init?${searchParams.toString()}`);
  }, [data, id, navigate]);

  const handleRemoveRecommend = useCallback(() => {
    const book = data?.me.recommends.get[0].book;
    if (!book) {
      return;
    }
    const searchParams = new URLSearchParams({
      title: book.title ? book.title : "",
      ISBN: book.isbn,
    });

    navigate(`/remove_recommend/${id}?${searchParams.toString()}`);
  }, [data, id, navigate]);

  const handleEditReadLog = (logID: number) => {
    navigate(`/editreadlog/${logID}`);
  };

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <>Error! {error.message}</>;
  }

  if (!data) {
    return null;
  }

  const readLogs = {
    logs: data.me.recommends.get[0].readLog.get,
    handleEditReadLog,
  };

  const isReadingOrDefault =
    data.me.recommends.get[0].recommendStatus === RecommendStatus.Default ||
    data.me.recommends.get[0].recommendStatus === RecommendStatus.Reading;

  return (
    <>
      {calilSearchWordModal}
      <BookDetailInfoTemplate
        book={data.me.recommends.get[0].book}
        isRecommend={!data.me.recommends.get[0].self}
        recommendStatus={data.me.recommends.get[0].recommendStatus}
        recommendComment={data.me.recommends.get[0].recommendComment || ""}
        handleBack={() => navigate(-1)}
        handleAlertClose={
          tutorialFlags &&
          !getTutorialFlag(TutorialFlagKey.HowToGetNextRecommendAlertClosed) &&
          isReadingOrDefault
            ? () =>
                saveTutorialFlags(
                  TutorialFlagKey.HowToGetNextRecommendAlertClosed
                )
            : undefined
        }
        readLogsProps={readLogs as Omit<ReadLogsProps, "handlePostingReadLog">}
        footerProps={
          isReadingOrDefault
            ? {
                handlePostReadLog,
                handleRemoveRecommend,
              }
            : undefined
        }
        handleClickGoToCalil={handleClickGoToCalil}
      />
    </>
  );
};
