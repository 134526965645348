import React, { useEffect } from "react";
import { KansouPresentTemplate } from "~/components/templates/PostReadLog/KansouPresent";
import { usePostReadLogState } from "../usePostReadLogState";
import { useCreateImageFromKansouMutation } from "~/generated/graphql";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";

export const KansouPresent: React.FC = () => {
  const {
    state: { review, kansouPresent, questionNumber, lastProgressNumber },
    dispatch,
  } = usePostReadLogState();
  const { currentUser } = React.useContext(CurrentUserContext);
  const nickname = currentUser?.general.nickname || "";

  const handleNext = (): void => {
    dispatch({
      type: "setQuestionNumber",
      payload: currentQuestionNumber + 1,
    });
    dispatch({
      type: "setFlow",
      payload: "kansouPreview",
    });
  };

  const [createImageFromKansou] = useCreateImageFromKansouMutation();

  useEffect(() => {
    createImageFromKansou({
      variables: { kansou: review },
      onCompleted: (res) => {
        dispatch({
          type: "setKansouPresent",
          payload: res.createImageFromKansou.url,
        });
      },
    });
  }, [review]);

  const currentQuestionNumber = questionNumber ? questionNumber : 1;

  const progress = Math.floor(
    (currentQuestionNumber / lastProgressNumber) * 100
  );

  return (
    <KansouPresentTemplate
      progress={progress}
      imageUrl={kansouPresent}
      kansou={review}
      nickname={nickname}
      onNext={handleNext}
    />
  );
};
