import React, { useMemo, VFC } from "react";
import styled from "styled-components";
import { ConfirmLengthTemplateProps } from "./type";
import { EmojiButton } from "~/components/molecules/buttons/EmojiButton";
import { ProgressBar } from "~/components/atoms/ProgressBar";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { NavContainer, Wrapper } from "../../Common";
import {
  postReadLogEmojiButtonStyle,
  PostReadLogHeading,
  PostReadLogBodyWrapper,
  PostReadLogMarginInterpolation,
  PostReadLogCharacterImg,
} from "../Common/style";
import { PostReadLogChoiceType } from "../Common/type";
import { TFunction, useTranslation } from "~/i18n";

export const useLengthChoices = (t: TFunction): PostReadLogChoiceType[] => {
  const LengthChoices = useMemo(
    (): PostReadLogChoiceType[] => [
      { ja: t<TranslateKeys>("短い"), emoji: "🐣" },
      { ja: t<TranslateKeys>("ちょっと短い"), emoji: "🐹" },
      { ja: "ぴったり！", emoji: "🦉" },
      { ja: t<TranslateKeys>("ちょっと長い"), emoji: "🐍" },
      { ja: t<TranslateKeys>("長い"), emoji: "🐘" },
    ],
    [t]
  );

  return LengthChoices;
};

export const ConfirmLengthTemplate: VFC<ConfirmLengthTemplateProps> = ({
  progress,
  handleBack,
  onClicks,
}) => {
  const { t } = useTranslation();

  const lengthChoices = useLengthChoices(t);

  return (
    <>
      <NavContainer>
        <BackNavbar titleName="かんそうをかく" onClick={handleBack} />
      </NavContainer>
      <Wrapper>
        <PostReadLogBodyWrapper>
          <ProgressCon>
            <ProgressBar progress={progress} backgroundColor="base.gray" />
          </ProgressCon>
          <PostReadLogHeading fontSize={"LG"} lineHeight={"MD"} bold>
            {t<TranslateKeys>("当てはまるものをえらんでね！")}
          </PostReadLogHeading>
          <CharacterCon>
            <PostReadLogCharacterImg src="/img/sensei/standup_2.svg" />
          </CharacterCon>
          <ButtonCon>
            {onClicks.map((onClick, index) => (
              <EmojiButton
                {...postReadLogEmojiButtonStyle}
                key={index}
                emoji={lengthChoices[index].emoji}
                text={lengthChoices[index].ja}
                onClick={onClick}
              />
            ))}
          </ButtonCon>
        </PostReadLogBodyWrapper>
      </Wrapper>
    </>
  );
};

const CharacterCon = styled.div`
  ${PostReadLogMarginInterpolation.Narrow}
  display: flex;
  justify-content: center;
`;

const ProgressCon = styled.div`
  margin-bottom: 20px;
`;

const ButtonCon = styled.div`
  ${PostReadLogMarginInterpolation.Narrow}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > * {
    margin: ${({ theme }) => theme.size.XS} 0;
  }
`;
