export default {
  "ミニレッスンを再開する": "ミニレッスンをさいかいする",
  "図書館で予約": "としょかんでよやく",
  "本の詳細": "ほんのしょうさい",
  "本の友の感想が見られるようになるよ": "ほんのとものかんそうがみられるようになるよ",
  "レベルを上げるには？": "レベルをあげるには？",
  "ミニレッスンを受けたり、本の感想を出したりすると、レベルが上がるよ": "ミニレッスンをうけたり、ほんのかんそうをだしたりすると、レベルがあがるよ",
  "しばらくすると、他の子の感想を この画面から 見られるようになるよ！": "しばらくすると、ほかのこのかんそうを このがめんから みられるようになるよ！",
  "本の友": "ほんのとも",
  "本棚": "ほんだな",
  "ヨンデミー先生": "ヨンデミーせんせい",
  "おすすめの本": "おすすめのほん",
  "おすすめ冊数": "おすすめさっすう",
  "冊": "さつ",
  "次の選書まであと": "つぎのせんしょまであと",
  "冊読もう": "さつよもう",
  "本が大好きなAIのせんせい。\n図書館の司書をしていて、読書家のみんなに、ぴったりの本をおすすめすることがとくい。\nあたまに本を乗せているので、水がにがて。": "ほんがだいすきなAIのせんせい。\nとしょかんのししょをしていて、どくしょかのみんなに、ぴったりのほんをおすすめすることがとくい。\nあたまにほんをのせているので、みずがにがて。",
  "さいしょは本を食べてばかりいたが、ヨンデミーで本を読むことをおぼえたようだ。": "さいしょはほんをたべてばかりいたが、ヨンデミーでほんをよむことをおぼえたようだ。",
  "ながいねむりから目が覚めたあと、ヨンデミーのおすすめ本で自分の仲間がぜつめつしたことを知った。": "ながいねむりからめがさめたあと、ヨンデミーのおすすめぼんでじぶんのなかまがぜつめつしたことをしった。",
  "その昔、人間にちしきをさずけてくれた、伝説のりんご。\n実は、どくりんごである。": "そのむかし、にんげんにちしきをさずけてくれた、でんせつのりんご。\nじつは、どくりんごである。",
  "ふだんは、なんきょくに住んでいる。冬にはむれで、おんどく会をするらしい。": "ふだんは、なんきょくにすんでいる。ふゆにはむれで、おんどくかいをするらしい。",
  "本をよんで、なぜ自分はほかの動物をたべるのかがわからなくなり、草食になった。": "ほんをよんで、なぜじぶんはほかのどうぶつをたべるのかがわからなくなり、そうしょくになった。",
  "本を読んで想像力を膨らませるとモコモコが膨らむ。眠れないときに数えるひつじの中に混ざっているらしい。": "ほんをよんでそうぞうりょくをふくらませるとモコモコがふくらむ。ねむれないときにかぞえるひつじのなかにまざっているらしい。",
  "紙職人の家に生まれたが、家出した一匹オオカミ。オオカミがお話の中でワルモノになりがちなことを実は少し気にしている。": "かみしょくにんのいえにうまれたが、いえでしたいっぴきオオカミ。オオカミがおはなしのなかでワルモノになりがちなことをじつはすこしきにしている。",
  "生まれつきピンクのキリン。長い首を伸ばして長い本を読みきるのが得意なようだ。": "うまれつきピンクのキリン。ながいくびをのばしてながいほんをよみきるのがとくいなようだ。",
  "じゅれいは3000年を超える。世界ではじめてしゅっぱんされた本は、校長先生のえだからつくられたという。": "じゅれいは3000ねんをこえる。せかいではじめてしゅっぱんされたほんは、こうちょうせんせいのえだからつくられたという。",
  "校長先生": "こうちょうせんせい",
  "本の虫": "ほんのむし",
  "太古のきょうりゅう": "たいこのきょうりゅう",
  "りんごちゃん": "りんごちゃん",
  "ペンギンせんぱい": "ペンギンせんぱい",
  "ベジタライオン": "ベジタライオン",
  "モコひつじ": "モコひつじ",
  "オオカミスミス": "オオカミスミス",
  "よみキリン": "よみキリン",
  "その昔、人間に 知恵をさずけてくれた、伝説のりんご。": "そのむかし、にんげんに ちえをさずけてくれた、でんせつのりんご。",
  "ふだんは、南極に住んでいる。冬にはむれで、音読会を するらしい。": "ふだんは、なんきょくにすんでいる。ふゆにはむれで、おんどくかいを するらしい。",
  "さいしょは 本を食べてばかりいたが、ヨンデミーで 本を読むことを おぼえた。": "さいしょは ほんをたべてばかりいたが、ヨンデミーで ほんをよむことを おぼえた。",
  "本を読んで、なぜ自分は 肉を食べるのか わからなくなり、ベジタリアンになった。": "ほんをよんで、なぜじぶんは にくをたべるのか わからなくなり、ベジタリアンになった。",
  "？？？": "？？？",
  "ヨンデミッションをクリアしてヨンデミーストーリーを進めると、新しいアイコンがもらえるよ！次は誰がもらえるかな？": "ヨンデミッションをクリアしてヨンデミーストーリーをすすめると、あたらしいアイコンがもらえるよ！つぎはだれがもらえるかな？",
  "保存して完了": "ほぞんしてかんりょう",
  "アイコン変更": "アイコンへんこう",
  "ニックネームが書かれていないよ。": "ニックネームがかかれていないよ。",
  "きみの名前を教えてね！": "きみのなまえをおしえてね！",
  "すてきな名前があふれちゃう！": "すてきななまえがあふれちゃう！",
  "{{ max }}文字まで使えるよ！": "{{ max }}もじまでつかえるよ！",
  "{{ max }}文字までで決めてね": "{{ max }}もじまでできめてね",
  "プロフィール変更": "プロフィールへんこう",
  "これに決める": "これにきめる",
  "感想編集": "かんそうへんしゅう",
  "ごめんなさい！送ることができませんでした。もう一度試してみてね": "ごめんなさい！おくることができませんでした。もういちどためしてみてね",
  "読書記録": "どくしょきろく",
  "感想提出": "かんそうていしゅつ",
  "連続中": "れんぞくちゅう",
  "読んだ本": "よんだほん",
  "読んだ文字数": "よんだもじすう",
  "字": "じ",
  "設定": "せってい",
  "当てはまるものをえらんでね！": "あてはまるものをえらんでね！",
  "この本を読んだときの、": "このほんをよんだときの、",
  "さんの気持ちに近いものは？": "さんのきもちにちかいものは？",
  "短すぎる": "みじかすぎる",
  "短い": "みじかい",
  "ちょうどいい": "ちょうどいい",
  "長い": "ながい",
  "長すぎる": "ながすぎる",
  "ちょっと長い": "ちょっとながい",
  "ちょっと短い": "ちょっとみじかい",
  "この本はどうだった？": "このほんはどうだった？",
  "にがて": "にがて",
  "あまり好きではない": "あまりすきではない",
  "好き": "すき",
  "とても好き": "とてもすき",
  "もういちど読みたい": "もういちどよみたい",
  "ちょっと好き": "ちょっとすき",
  "とちゅうまで": "とちゅうまで",
  "最後まで": "さいごまで",
  "ほんだなから消す": "ほんだなからけす",
  "どれくらいまで読んだ？": "どれくらいまでよんだ？",
  "読み聞かせで読んだ": "よみきかせでよんだ",
  "まだ読んだことがないお友達におすすめしたい、": "まだよんだことがないおともだちにおすすめしたい、",
  "1番お気に入りのシーン・セリフ・フレーズを教えてね！": "1ばんおきにいりのシーン・セリフ・フレーズをおしえてね！",
  "思いつかないときは、空欄のままにしてね。": "おもいつかないときは、くうらんのままにしてね。",
  "お話の中で、音が聞こえてきそうなところはあったかな？👂\n\nトントン、ザーザー、ぴょこぴょこ……いろんな音がなっているかも🤔\n\nよーく読んで、思い描いてみよう✨": "おはなしのなかで、おとがきこえてきそうなところはあったかな？👂\n\nトントン、ザーザー、ぴょこぴょこ……いろんなおんがなっているかも🤔\n\nよーくよんで、おもいえがいてみよう✨",
  "どんな見た目のものが出てきたかな？\n\n綺麗なもの、へんてこなもの、おいしそうなもの……本には いろいろなものが 出てくるよね🙌\n\n細かいところまで、思い描いてみよう✨": "どんなみためのものがでてきたかな？\n\nきれいなもの、へんてこなもの、おいしそうなもの……ほんには いろいろなものが でてくるよね🙌\n\nこまかいところまで、おもいえがいてみよう✨",
  "この本のどこか1ページに潜り込めるとしたらどのページに潜り込みたいかな？\n\n潜り込んで何をやってみたい？\n\n自分が本の世界に入り込んだつもりで思い描いてみよう✨": "このほんのどこか1ページにもぐりこめるとしたらどのページにもぐりこみたいかな？\n\nもぐりこんでなにをやってみたい？\n\nじぶんがほんのせかいにはいりこんだつもりでおもいえがいてみよう✨",
  "登場人物とお話しできるとしたら、誰と話してみたい？\n\nどんな話をしようかな？ワクワクするね\n\n登場人物がなんていうのか思い描いてみよう✨": "とうじょうじんぶつとおはなしできるとしたら、だれとはなしてみたい？\n\nどんなはなしをしようかな？ワクワクするね\n\nとうじょうじんぶつがなんていうのかおもいえがいてみよう✨",
  "好きなページはあった？ そのページで、登場人物はどんな気持ちになったのかな？\n\n楽しい、嬉しい、悲しい……いろんな感情がありそうだよね☺\n\n登場人物になりきって思い描いてみよう✨": "すきなページはあった？ そのページで、とうじょうじんぶつはどんなきもちになったのかな？\n\nたのしい、うれしい、かなしい……いろんなかんじょうがありそうだよね☺\n\nとうじょうじんものになりきっておもいえがいてみよう✨",
  "もし自分が 登場人物だったら 何をしただろう？🤔\n\n同じことをする？それとも、違うことをする？ ヨンデミー先生に教えてほしいなぁ☺\n\n本の中と自分のすることをつなげてみよう✨": "もしじぶんが とうじょうじんぶつだったら なにをしただろう？🤔\n\nおなじことをする？それとも、ちがうことをする？ ヨンデミーせんせいにおしえてほしいなぁ☺\n\nほんのなかとじぶんのすることをつなげてみよう✨",
  "自分が 登場人物だったら どんな気持ちになったかな？\n\n自分だったら 同じ気持ちになった？ それとも 違う気持ちになった？いろんな気持ちに なりそうだよね😉\n\n自分の気持ちと本の人物の気持ちをつなげてみよう✨": "じぶんが とうじょうじんぶつだったら どんなきもちになったかな？\n\nじぶんだったら おなじきもちになった？ それとも ちがうきもちになった？いろんなきもちに なりそうだよね😉\n\nじぶんのきもちとほんのじんぶつのきもちをつなげてみよう✨",
  "これまでに読んだおはなしで似ているお話はあったかな？\n\nどんなお話だったか、どんな ものや ひとが 出てきたか、いろんなポイントがありそうだね☺\n\n前に読んだお話とこのお話をつなげてみよう✨": "これまでによんだおはなしでにているおはなしはあったかな？\n\nどんなおはなしだったか、どんな ものや ひとが でてきたか、いろんなポイントがありそうだね☺\n\nまえによんだおはなしとこのおはなしをつなげてみよう✨",
  "このお話に出てくる登場人物と、にている人は身近にいるかな？\n\nどんなところが似ているか考えてヨンデミー先生に教えてね☺\n\n身近な人物とお話の人物をつなげてみよう✨": "このおはなしにでてくるとうじょうじんぶつと、にているひとはみぢかにいるかな？\n\nどんなところがにているかかんがえてヨンデミーせんせいにおしえてね☺\n\nみぢかなじんぶつとおはなしのじんぶつをつなげてみよう✨",
  "すでに知っていること、やったことのあることはでてきたかな？\n\nどんなことを知っていたのか思い出して書いてみてね\n\n自分の知識と本の知識をつなげてみよう✨": "すでにしっていること、やったことのあることはでてきたかな？\n\nどんなことをしっていたのかおもいだしてかいてみてね\n\nじぶんのちしきとほんのちしきをつなげてみよう✨",
  "不思議だな、わからないなと思ったことはあったかな？🤔\n\nどんなところで思ったかも教えてね\n\n本のわからないところに質問してみよう✨": "ふしぎだな、わからないなとおもったことはあったかな？🤔\n\nどんなところでおもったかもおしえてね\n\nほんのわからないところにしつもんしてみよう✨",
  "この本の登場人物に一つ質問ができるとしたら、どんな質問をしてみたい？\n\n戦っていたときの気持ち、ご飯の味の感想など、登場人物の体験をもっと詳しく知りたいね。\n\n登場人物の気持ちを質問してみよう✨": "このほんのとうじょうじんぶつにひとつしつもんができるとしたら、どんなしつもんをしてみたい？\n\nたたかっていたときのきもち、ごはんのあじのかんそうなど、とうじょうじんぶつのたいけんをもっとくわしくしりたいね。\n\nとうじょうじんぶつのきもちをしつもんしてみよう✨",
  "このお話の中で、もっと知りたい！と思ったところはあった？\n\n本に書かれていることでもいいし、書かれていないことだって聞いてみてもいいかもね😉\n\nお話の中で気になったことを質問してみよう✨": "このおはなしのなかで、もっとしりたい！とおもったところはあった？\n\nほんにかかれていることでもいいし、かかれていないことだってきいてみてもいいかもね😉\n\nおはなしのなかできになったことをしつもんしてみよう✨",
  "言葉の意味がわからないところはあったかな？\n\n漢字や言葉遣いなど、文の意味を読み取るのに難しいところもあるよね🤔\n\n言葉の意味を質問してみよう✨": "ことばのいみがわからないところはあったかな？\n\nかんじやことばづかいなど、ぶんのいみをよみとるのにむずかしいところもあるよね🤔\n\nことばのいみをしつもんしてみよう✨",
  "登場人物が どうしてそうしたのか わからないところがあったかな？\n\nその人が何をどう思ったのか、考えてみてもおもしろそうだね。\n\n登場人物の 行動の理由を質問してみよう✨": "とうじょうじんぶつが どうしてそうしたのか わからないところがあったかな？\n\nそのひとがなにをどうおもったのか、かんがえてみてもおもしろそうだね。\n\nとうじょうじんぶつの こうどうのりゆうをしつもんしてみよう✨",
  "お話を読みながら 思ったことはあったかな？それはお話の続きと 同じだった？それとも、違った？\n\n先のお話を考えながら 読んだところがあれば教えてね😉\n\nお話の展開を予想しよう✨": "おはなしをよみながら おもったことはあったかな？それはおはなしのつづきと おなじだった？それとも、ちがった？\n\nさきのおはなしをかんがえながら よんだところがあればおしえてね😉\n\nおはなしのてんかいをよそうしよう✨",
  "意味を知らない言葉や文はあったかな？そこはどういう意味だと思う？\n\nわかる文をヒントにして考えてみてね。\n\nわからないところの意味を予想しよう✨": "いみをしらないことばやぶんはあったかな？そこはどういういみだとおもう？\n\nわかるぶんをヒントにしてかんがえてみてね。\n\nわからないところのいみをよそうしよう✨",
  "文章に書かれていないけれど、実はこうじゃないかなと思ったことはあったかな？\n\nどんなことを感じたか、ヨンデミー先生に教えてね☺\n\n隠されている 本当のことを予想しよう✨": "ぶんしょうにかかれていないけれど、じつはこうじゃないかなとおもったことはあったかな？\n\nどんなことをかんじたか、ヨンデミーせんせいにおしえてね☺\n\nかくされている ほんとうのことをよそうしよう✨",
  "もしこのお話に続きがあるとしたら、どんなお話になると思う？\n\n幸せなお話、悲しいお話、どんなお話でも大丈夫！\n\nお話の先を予想しよう✨": "もしこのおはなしにつづきがあるとしたら、どんなおはなしになるとおもう？\n\nしあわせなおはなし、かなしいおはなし、どんなおはなしでもだいじょうぶ！\n\nおはなしのさきをよそうしよう✨",
  "このお話を読みながら思っていたことと、全然違ったことはあったかな？\n\n思っていたことと全然違ったらびっくりするね！どこが違ったのか教えてね☺\n\nお話を読みながら先の展開を予想しよう✨": "このおはなしをよみながらおもっていたことと、ぜんぜんちがったことはあったかな？\n\nおもっていたこととぜんぜんちがったらびっくりするね！どこがちがったのかおしえてね☺\n\nおはなしをよみながらさきのてんかいをよそうしよう✨",
  "自分に一番似ていると思うキャラクターは誰だろう？\n\nどういうところでそう思ったのかも、ヨンデミー先生に聞かせてほしいな☺\n\n自分とキャラクターの似ているところを認めよう✨": "じぶんにいちばんにているとおもうキャラクターはだれだろう？\n\nどういうところでそうおもったのかも、ヨンデミーせんせいにきかせてほしいな☺\n\nじぶんとキャラクターのにているところをみとめよう✨",
  "自分に 一番似ていないと思うキャラクターは誰かな？\n\nその人とどういうところが似ていないかな？その人がどんな気持ちだったか、考えてみよう🤔\n\nその人の気持ちやものの見方を認めよう✨": "じぶんに いちばんにていないとおもうキャラクターはだれかな？\n\nそのひととどういうところがにていないかな？そのひとがどんなきもちだったか、かんがえてみよう🤔\n\nそのひとのきもちやもののみかたをみとめよう✨",
  "すごく印象に残ったところはどこですか？\n\nどうして印象に残ったのか、考えてみよう\n\n自分にとって大事なことを認めよう✨": "すごくいんしょうにのこったところはどこですか？\n\nどうしていんしょうにのこったのか、かんがえてみよう\n\nじぶんにとってだいじなことをみとめよう✨",
  "これからはこうしよう、または、こうしないようにしよう、と思ったことはあるかな？\n\nどうしてそう思ったのかも、ヨンデミー先生は気になるよ！\n\n自分の良かったところ、もっと良くできるところを認めよう✨": "これからはこうしよう、または、こうしないようにしよう、とおもったことはあるかな？\n\nどうしてそうおもったのかも、ヨンデミーせんせいはきになるよ！\n\nじぶんのよかったところ、もっとよくできるところをみとめよう✨",
  "自分の思っていたことと、違ったところはあったかな？\n\n違う考えや気持ちに対してどう感じたかな？\n\n自分と本の内容との違いを認めよう✨": "じぶんのおもっていたことと、ちがったところはあったかな？\n\nちがうかんがえやきもちにたいしてどうかんじたかな？\n\nじぶんとほんのないようとのちがいをみとめよう✨",
  "心に残っているセリフはあったかな？\n\nそのセリフは、どうして心に残ったんだろう？🤔感動したから、かっこいいから、かわいいから……いろんな理由がありそうだね☺\n\n自分にとって大事なセリフを見極めよう✨": "こころにのこっているセリフはあったかな？\n\nそのセリフは、どうしてこころにのこったんだろう？🤔かんどうしたから、かっこいいから、かわいいから……いろんなりゆうがありそうだね☺\n\nじぶんにとってだいじなセリフをみきわめよう✨",
  "好きなところ、面白いところはどういうところだったかな？どうして好きだと思ったかも教えてくれると嬉しいな☺自分にとって面白いところを見極めよう✨": "すきなところ、おもしろいところはどういうところだったかな？どうしてすきだとおもったかもおしえてくれるとうれしいな☺じぶんにとっておもしろいところをみきわめよう✨",
  "新しく知ったことはあったかな？どんなことを新しく知ったのか、ヨンデミー先生も知りたいな！\n\n新しい知識を見極めよう✨": "あたらしくしったことはあったかな？どんなことをあたらしくしったのか、ヨンデミーせんせいもしりたいな！\n\nあたらしいちしきをみきわめよう✨",
  "他の人に教えたい！と思ったことはあったかな？\n\n教えたいところを書いて、ヨンデミー先生に見せてほしいなぁ☺\n\nおすすめポイントを見極めよう✨": "ほかのひとにおしえたい！とおもったことはあったかな？\n\nおしえたいところをかいて、ヨンデミーせんせいにみせてほしいなぁ☺\n\nおすすめポイントをみきわめよう✨",
  "作者が伝えたかったことはなんだと思う？\n\n本から感じ取れるメッセージを書いて、ヨンデミー先生に教えてね😉\n\n作者の伝えたいことを見極めよう✨": "さくしゃがつたえたかったことはなんだとおもう？\n\nほんからかんじとれるメッセージをかいて、ヨンデミーせんせいにおしえてね😉\n\nさくしゃのつたえたいことをみきわめよう✨",
  "物語の最後で主人公はどんな気持ちだったかな？\n\n嬉しい？悲しい？そう思ったのはどうしてだろう？🤔\n\n主人公の気持ちを解釈しよう✨": "ものがたりのさいごでしゅじんこうはどんなきもちだったかな？\n\nうれしい？かなしい？そうおもったのはどうしてだろう？🤔\n\nしゅじんこうのきもちをかいしゃくしよう✨",
  "作者はどうしてこの本を書いたんだろう？\n\nいったいどんなメッセージを伝えたかったのかな🤔\n\n作者の意図を解釈しよう✨": "さくしゃはどうしてこのほんをかいたんだろう？\n\nいったいどんなメッセージをつたえたかったのかな🤔\n\nさくしゃのいとをかいしゃくしよう✨",
  "この本をおすすめするとしたら、どう説明するかな？\n\n何が面白かった？どんな人におすすめ？考えて書いて、ヨンデミー先生にもおすすめしてね！\n\nおすすめの理由をまとめて、解釈しよう✨": "このほんをおすすめするとしたら、どうせつめいするかな？\n\nなにがおもしろかった？どんなひとにおすすめ？かんがえてかいて、ヨンデミーせんせいにもおすすめしてね！\n\nおすすめのりゆうをまとめて、かいしゃくしよう✨",
  "自分にとって大切だと思うことはあったかな？\n\nどうしてそれが大切なんだろう？\n\n大切だと思うことを考えて、解釈しよう✨": "じぶんにとってたいせつだとおもうことはあったかな？\n\nどうしてそれがたいせつなんだろう？\n\nたいせつだとおもうことをかんがえて、かいしゃくしよう✨",
  "この本はどんなお話だったかな？簡単に説明してみよう\n\n話の内容を 短く説明するとしたら どうなるかな？ヨンデミー先生に説明してくれると嬉しいな☺\n\nお話をまとめて、内容を解釈してみよう✨": "このほんはどんなおはなしだったかな？かんたんにせつめいしてみよう\n\nはなしのないようを みじかくせつめいするとしたら どうなるかな？ヨンデミーせんせいにせつめいしてくれるとうれしいな☺\n\nおはなしをまとめて、ないようをかいしゃくしてみよう✨",
  "『{{bookTitle}}』の あまり好きではなかったところは どこだろう？": "『{{bookTitle}}』の あまりすきではなかったところは どこだろう？",
  "あまり好きではなかった本の 感想は「本の友」には 流れません！": "あまりすきではなかったほんの かんそうは「ほんのとも」には ながれません！",
  "ヨンデミー先生 にだけ こっそり教えてね": "ヨンデミーせんせい にだけ こっそりおしえてね",
  "『{{bookTitle}}』の": "『{{bookTitle}}』の",
  "一言感想を教えてください！": "ひとことかんそうをおしえてください！",
  "こわいシーンが 多くて、夢に でできそうで いやだった。": "こわいシーンが おおくて、ゆめに でできそうで いやだった。",
  "思いつかないときは、ヒントガチャを引いてみよう。": "おもいつかないときは、ヒントガチャをひいてみよう。",
  "ヒントガチャを引く": "ヒントガチャをひく",
  "声で入力するには": "こえでにゅうりょくするには",
  "今回は書かない": "こんかいはかかない",
  "紙で書くには": "かみでかくには",
  "この内容ですすむ": "このないようですすむ",
  "ほかのヒントを選ぶ": "ほかのヒントをえらぶ",
  "好きな感想を書いてみてね": "すきなかんそうをかいてみてね",
  "1024文字以内で入力してください": "1024もじいないでにゅうりょくしてください",
  "空白の行があるよ！改行は1度に1行までにしてね。": "くうはくのぎょうがあるよ！かいぎょうは1どに1ぎょうまでにしてね。",
  "絵文字が文字より多くなっているよ！文字を使って感想を書いてみよう": "えもじがもじよりおおくなっているよ！もじをつかってかんそうをかいてみよう",
  "ISBNとタイトルを入力してください": "ISBNとタイトルをにゅうりょくしてください",
  "※ISBNは本のうらがわに書いてある、13けたの数字のことです。": "※ISBNはほんのうらがわにかいてある、13けたのすうじのことです。",
  "ハナシテミーが使えるよ": "ハナシテミーがつかえるよ",
  "今日のぶんのハナシテミーは使いおわったよ。また明日きてね！": "きょうのぶんのハナシテミーはつかいおわったよ。またあしたきてね！",
  "これは1000人中500人だけです！\n明日も読めば1000人中300人に入れるよ": "これは1000にんちゅう500にんだけです！\nあしたもよめば1000にんちゅう300にんにはいれるよ",
  "1000人中300人！\n5日連続で1000人中200人に入れるよ！": "1000にんちゅう300にん！\n5にちれんぞくで1000にんちゅう200にんにはいれるよ！",
  "1000人中200人だ！\n1週間連続で1000人中100人だよ": "1000にんちゅう200にんだ！\n1しゅうかんれんぞくで1000にんちゅう100にんだよ",
  "1週間連続で読んだのは、1000人中100人だけです！": "1しゅうかんれんぞくでよんだのは、1000にんちゅう100にんだけです！",
  "1000人中50人まで来たよ！\nもうすぐ2週間連続だ": "1000にんちゅう50にんまできたよ！\nもうすぐ2しゅうかんれんぞくだ",
  "2週間連続！？\nすごい！\n1000人中30人だよ": "2しゅうかんれんぞく！？\nすごい！\n1000にんちゅう30にんだよ",
  "1000人中20人しか3週連続で読めた人はいないよ！": "1000にんちゅう20にんしか3しゅうれんぞくでよめたひとはいないよ！",
  "ここまで来たのは1000人に10人だけ！\nとうとうトップ10だ！": "ここまできたのは1000にんに10にんだけ！\nとうとうトップ10だ！",
  "なんと、10000人に50人まで来ちゃったよ！\n次は何人に入れるかな？": "なんと、10000にんに50にんまできちゃったよ！\nつぎはなんにんにはいれるかな？",
  "6週連続で読んだのは10000人に20人だけだよ！": "6しゅうれんぞくでよんだのは10000にんに20にんだけだよ！",
  "すごすぎる！\n10000人に10人だけだよ！": "すごすぎる！\n10000にんに10にんだけだよ！",
  "10000人に5人だよ！！\n片手の指で数えられちゃうね！": "10000にんに5にんだよ！！\nかたてのゆびでかぞえられちゃうね！",
  "10000人に2人！\nもうちょっとで10000人のトップになれるよ！": "10000にんに2り！\nもうちょっとで10000にんのトップになれるよ！",
  "すごい！\n10000人に1人の読書家だ！": "すごい！\n10000にんに1りのどくしょかだ！",
  "頑張った{{user}}さんに四葉のクローバーをプレゼント！": "がんばった{{user}}さんによつばのクローバーをプレゼント！",
  "砂漠のオアシス発見級のすごさ！": "さばくのオアシスはっけんきゅうのすごさ！",
  "{{user}}さんのために流れ星が降り注ぐよ！": "{{user}}さんのためにながれぼしがふりそそぐよ！",
  "ティラノサウルスの化石を掘りあてたくらいすごい！": "ティラノサウルスのかせきをほりあてたくらいすごい！",
  "読書オリンピックで金メダルだね😉": "どくしょオリンピックできんめだるだね😉",
  "エベレスト級の本の山のてっぺんだ！": "エベレストきゅうのほんのやまのてっぺんだ！",
  " {{user}}さんの読んだ本でピラミッドが作れちゃう！": " {{user}}さんのよんだほんでピラミッドがつくれちゃう！",
  "本を読み続けて1年！！\nすっごく成長したね！": "ほんをよみつづけて1ねん！！\nすっごくせいちょうしたね！",
  "読書星人も{{user}}さんとお友達になりたがるよ！": "どくしょせいじんも{{user}}さんとおともだちになりたがるよ！",
  "あまりのすごさにモナ・リザも笑顔になっちゃった！": "あまりのすごさにモナ・リザもえがおになっちゃった！",
  "びっくりしてアリが象を持ち上げちゃった！": "びっくりしてアリがぞうをもちあげちゃった！",
  "ピサの斜塔がびっくりしてまっすぐになっちゃった！": "ピサのしゃとうがびっくりしてまっすぐになっちゃった！",
  "クジラも一緒に本を読みたくて本を噴き上げちゃったよ！": "クジラもいっしょにほんをよみたくてほんをふきあげちゃったよ！",
  "モアイ像がおめでとうの歌を歌い出したみたいだよ！": "もあいぞうがおめでとうのうたをうたいだしたみたいだよ！",
  "ここまで辿り着けるのは、選ばれた伝説の読書家だけです！": "ここまでたどりつけるのは、えらばれたでんせつのどくしょかだけです！",
  "日連続": "日れんぞく",
  "どの本の感想をかきますか？": "どのほんのかんそうをかきますか？",
  "自分で\n選んだ本の\n感想を送る": "じぶんで\nえらんだほんの\nかんそうをおくる",
  "感想を とどけて いいですか？": "かんそうを とどけて いいですか？",
  "難しかった？": "むずかしかった？",
  "長さは？": "ながさは？",
  "好きだった？": "すきだった？",
  "気持ち": "きもち",
  "書きなおす": "かきなおす",
  "とどける": "とどける",
  "どこまで読んだ？": "どこまでよんだ？",
  "読み切っていない場合、おすすめの本リストからは削除されません": "よみきっていないばあい、おすすめのほんリストからはさくじょされません",
  "一言感想": "ひとことかんそう",
  "中学生向け": "ちゅうがくせいむけ",
  "6年生向け": "6ねんせいむけ",
  "5年生向け": "5ねんせいむけ",
  "4年生向け": "4ねんせいむけ",
  "3年生向け": "3ねんせいむけ",
  "2年生むけ": "2ねんせいむけ",
  "1年生むけ（ひらがなのみ）": "1ねんせいむけ（ひらがなのみ）",
  "1ヶ月": "1かげつ",
  "2ヶ月": "2かげつ",
  "3ヶ月": "3かげつ",
  "読書習慣": "どくしょしゅうかん",
  "感想": "かんそう",
  "一緒": "いっしょ",
  "本": "ほん",
  "読んで": "よんで",
  "楽しんで": "たのしんで",
  "全部": "ぜんぶ",
  "回": "かい",
  "先週": "せんしゅう",
  "{{ year }}年{{ month }}月": "{{ year }}ねん{{ month }}がつ",
  "読んだ本の冊数": "よんだほんのさっすう",
  "読書時間": "どくしょじかん",
  "連続読書日数": "れんぞくどくしょにっすう",
  "初めての読者": "はじめてのどくしゃ",
  "これまでに{{nickname}}さんが読んだ本の文字数の合計です。": "これまでに{{nickname}}さんがよんだほんのもじすうのごうけいです。",
  "これまでに{{nickname}}さんが読んだ本の数です。": "これまでに{{nickname}}さんがよんだほんのかずです。",
  "これまでに{{nickname}}さんが本を読んだ時間の合計です。": "これまでに{{nickname}}さんがほんをよんだじかんのごうけいです。",
  "{{nickname}}さんの感想を出した日数の連続記録です。": "{{nickname}}さんのかんそうをだしたにっすうのれんぞくきろくです。",
  "ヨンデミーの生徒の中で{{nickname}}さんがはじめて読んだ本の数です。": "ヨンデミーのせいとのなかで{{nickname}}さんがはじめてよんだほんのかずです。",
  "読書家の書": "どくしょかのしょ",
  "{{ userName }}の書": "{{ userName }}のしょ",
  "削除する理由を": "さくじょするりゆうを",
  "教えてください": "おしえてください",
  "途中まで読んだときは": "とちゅうまでよんだときは",
  "まずは感想を送る": "まずはかんそうをおくる",
  "本を おすすめ本リストから 削除します": "ほんを おすすめぼんリストから さくじょします",
  "戻る": "もどる",
  "本を削除できなかったよ！もう一度試してみてね！": "ほんをさくじょできなかったよ！もういちどためしてみてね！",
  "いいねを送る": "いいねをおくる",
  "を読み終わりました！": "をよみおわりました！",
  "を読んでいます！": "をよんでいます！",
  "を読むのをやめました！": "をよむのをやめました！",
  "読みたい 本を 自分で 見つけられて すごいね！": "よみたい ほんを じぶんで みつけられて すごいね！",
  "この本も {{ user }}さんに 選んでもらえて うれしそう🥰": "このほんも {{ user }}さんに えらんでもらえて うれしそう🥰",
  "とっても 面白そうな 本！ 気になるなぁ👀": "とっても おもしろそうな ほん！ きになるなぁ👀",
  "どんな 本 なんだろう？読んでみたいな〜！": "どんな ほん なんだろう？よんでみたいな～！",
  "すてきな 本だね！感想が 楽しみ😆✨": "すてきな ほんだね！かんそうが たのしみ😆✨",
  "この 本のこと、ヨンデミー先生にも 教えてね🤗": "この ほんのこと、ヨンデミーせんせいにも おしえてね🤗",
  "答えを書いてね": "こたえをかいてね",
  "使って": "つかって",
  "送る": "おくる",
  "選び直す": "えらびなおす",
  "体の 感覚を 使って 頭の中に イメージを 描く": "からだの かんかくを つかって あたまのなかに イメージを えがく",
  "自分の 知っている事と つなげて 読む": "じぶんの しっていることと つなげて よむ",
  "質問して 本の意味を 深く 理解する": "しつもんして ほんのいみを ふかく りかいする",
  "実際には 書かれていない 行間の意味を 読み取る": "じっさいには かかれていない ぎょうかんのいみを よみとる",
  "自分の 正しさや 間違いを 見つけて 考えを深める": "じぶんの ただしさや まちがいを みつけて かんがえをふかめる",
  "本の 大事な所を 見つけながら 読む": "ほんの だいじなところを みつけながら よむ",
  "本の内容を まとめたり、自分の考えを 付け加えたりする": "ほんのないようを まとめたり、じぶんのかんがえを つけくわえたりする",
  "使った": "つかった",
  "力": "ちから",
  "満ちた": "みちた",
  "届けよう": "とどけよう",
  "本の友は準備中です！": "ほんのともはじゅんびちゅうです！",
  "明日までお待ちください。\n{{nickname}}さんと気が合いそうな読書仲間の感想を見られるように準備しています。": "あしたまでおまちください。\n{{nickname}}さんときがあいそうなどくしょなかまのかんそうをみられるようにじゅんびしています。",
  "明日": "あした",
  "来てね": "きてね",
  "1日1回": "1にち1かい",
  "押せるよ": "おせるよ",
  "みんなのいいね": "みんなのいいね",
  "まだいいねはありません！": "まだいいねはありません！",
  "感想を書くと、\nみんながいいねをくれるかも……!?": "かんそうをかくと、\nみんながいいねをくれるかも……!?",
  "みんなの感想を見る": "みんなのかんそうをみる",
  "他": "ほか",
  "受け取った": "うけとった",
  "行く": "いく",
  "閉じる": "とじる",
  "作者": "さくしゃ",
  "作画者": "さくがしゃ",
  "出版社": "しゅっぱんしゃ",
  "文字数": "もじすう",
  "ページ数": "ぺーじすう",
  "出版年月日": "しゅっぱんねんがっぴ",
  "詳しい情報": "くわしいじょうほう",
  "今食べたいのは？": "いまたべたいのは？",
  "ハンバーグ": "ハンバーグ",
  "パンケーキ": "パンケーキ",
  "野菜カレー": "やさいカレー",
  "フルーツサンド": "フルーツサンド",
  "好きなことは何？": "すきなことはなに？",
  "体を動かすこと": "からだをうごかすこと",
  "音楽を聴くこと": "おんがくをきくこと",
  "お絵描きや工作": "おえかきやこうさく",
  "ファッション": "ファッション",
  "空いた時間は何したい？": "あいたじかんはなにしたい？",
  "友だちと遊ぶ": "ともだちとあそぶ",
  "おうちでまったり": "おうちでまったり",
  "のんびりおでかけ": "のんびりおでかけ",
  "好きなことをとことん": "すきなことをとことん",
  "この中で欲しい力は？": "このなかでほしいちからは？",
  "なかまをすくう力": "なかまをすくうちから",
  "人とはなす力": "ひととはなすちから",
  "つづける力": "つづけるちから",
  "すばやくうごく力": "すばやくうごくちから",
  "自分はどういう性格だと思う？": "じぶんはどういうせいかくだとおもう？",
  "パワフル": "パワフル",
  "クール": "クール",
  "リラックス": "リラックス",
  "ラブリー": "ラブリー",
  "自分を表してる音は何？": "じぶんをあらわしてるおとはなに？",
  "モリモリ": "モリモリ",
  "しんしん": "しんしん",
  "ほわほわ": "ほわほわ",
  "シャキシャキ": "シャキシャキ",
  "好きな場所は？": "すきなばしょは？",
  "海": "うみ",
  "山": "やま",
  "草原": "そうげん",
  "街": "まち",
  "好きな時間帯は？": "すきなじかんたいは？",
  "朝": "あさ",
  "夜": "よる",
  "夕方": "ゆうがた",
  "昼": "ひる",
  "好きな季節は？": "すきなきせつは？",
  "夏": "なつ",
  "冬": "ふゆ",
  "春": "はる",
  "秋": "あき",
  "お金を無限に使えたら何をする？": "おかねをむげんにつかえたらなにをする？",
  "大きい家をつくる": "おおきいいえをつくる",
  "体育館を作る": "たいいくかんをつくる",
  "面白い芸を見る": "おもしろいげいをみる",
  "健康グッズを買う": "けんこうグッズをかう",
  "音楽を聴きまくる": "おんがくをききまくる",
  "家族にプレゼント": "かぞくにプレゼント",
  "本を作る": "ほんをつくる",
  "一人旅に出る": "ひとりたびにでる",
  "特に使わない": "とくにつかわない",
  "みんなにあげる": "みんなにあげる",
  "工作しまくる": "こうさくしまくる",
  "みんなでパーティー": "みんなでパーティー",
  "勉強に使う": "べんきょうにつかう",
  "一人で豪遊": "ひとりでごうゆう",
  "服を買う": "ふくをかう",
  "旅行に行く": "りょこうにいく",
  "好きな香りは何？": "すきなかおりはなに？",
  "食べ物の香り": "たべもののかおり",
  "岩の香り": "いわのかおり",
  "木の香り": "きのかおり",
  "古本の香り": "ふるほんのかおり",
  "布団の香り": "ふとんのかおり",
  "花の香り": "はなのかおり",
  "紙の香り": "かみのかおり",
  "インクの香り": "インクのかおり",
  "草の香り": "くさのかおり",
  "土の香り": "つちのかおり",
  "絵の具の香り": "えのぐのかおり",
  "海の香り": "うみのかおり",
  "フルーツの香り": "フルーツのかおり",
  "雨の香り": "あめのかおり",
  "化粧品の香り": "けしょうひんのかおり",
  "ハーブの香り": "ハーブのかおり",
  "{{nickname}}さんと\nおんなじ！ワクワクするぞー！": "{{nickname}}さんと\nおんなじ！ワクワクするぞー！",
  "冒険　ワクワク　勇気": "ぼうけん　ワクワク　ゆうき",
  "明るくて 元気な ムードメーカー": "あかるくて げんきな ムードメーカー",
  "元気いっぱいの": "げんきいっぱいの",
  "ドキドキハラハラする 冒険の本": "ドキドキハラハラする ぼうけんのほん",
  "オイラのおすすめの本、ヨンデミー！": "オイラのおすすめのほん、ヨンデミー！",
  "ワタシと同じなんて\n{{nickname}}さんも なかなかやるわね": "ワタシとおなじなんて\n{{nickname}}さんも なかなかやるわね",
  "知識　ノンフィクション　説明文": "ちしき　ノンフィクション　せつめいぶん",
  "口が悪いけど 自分に甘えない 努力家": "くちがわるいけど じぶんにあまえない どりょくか",
  "いつも 一生懸命な": "いつも いっしょうけんめいな",
  "新しいことが 学べる本": "あたらしいことが まなべるほん",
  "ワタシのおすすめの本、ヨンデミー！": "ワタシのおすすめのほん、ヨンデミー！",
  "{{nickname}}さんと おそろいだ！\nいっしょに 音読がしたいな": "{{nickname}}さんと おそろいだ！\nいっしょに おんどくがしたいな",
  "ファンタジー　芸術　不思議": "ファンタジー　げいじゅつ　ふしぎ",
  "不思議なことが 大好きな 芸術家": "ふしぎなことが だいすきな げいじゅつか",
  "想像力あふれる": "そうぞうりょくあふれる",
  "不思議な魅力が いっぱいの本": "ふしぎなみりょくが いっぱいのほん",
  "ぼくのおすすめの本、ヨンデミー！": "ぼくのおすすめのほん、ヨンデミー！",
  "おやぁ？{{nickname}}さんと 自分、似てるんだねぇ😊": "おやぁ？{{nickname}}さんと じぶん、にてるんだねぇ😊",
  "哲学　自然　動物": "てつがく　しぜん　どうぶつ",
  "優しくて 哲学好きの のんびり屋さん": "やさしくて てつがくずきの のんびりやさん",
  "マイペースな": "マイペースな",
  "自然の動物たちに 触れられる本や、哲学の本": "しぜんのどうぶつたちに ふれられるほんや、てつがくのほん",
  "自分のおすすめの本、ヨンデミー！": "じぶんのおすすめのほん、ヨンデミー！",
  "モコォ/// {{nickname}}さんと 同じで 嬉しいです": "モコォ/// {{nickname}}さんと おなじで うれしいです",
  "ほっこり　カワイイ　日常モノ": "ほっこり　カワイイ　にちじょうモノ",
  "おとなしくて かわいいもの好きの 気遣い屋さん": "おとなしくて かわいいものずきの きづかいやさん",
  "心優しい": "こころやさしい",
  "ほっこりできる 日常の本": "ほっこりできる にちじょうのほん",
  "うちのおすすめの本、ヨンデミー！": "うちのおすすめのほん、ヨンデミー！",
  "オレと {{nickname}}さんが 同じ……悪くないな": "オレと {{nickname}}さんが おなじ……わるくないな",
  "詩・ポエム　愛　孤独": "し・ポエム　あい　こどく",
  "気高くて 優しい 一匹狼": "けだかくて やさしい いっぴきおおかみ",
  "クールな": "クールな",
  "外の世界との 関わりについて 考えられる本": "そとのせかいとの かかわりについて かんがえられるほん",
  "オレのおすすめの本、ヨンデミー！": "オレのおすすめのほん、ヨンデミー！",
  "ボクと {{nickname}}さん、気が合いそうじゃん👍": "ボクと {{nickname}}さん、きがあいそうじゃん👍",
  "友達　エッセイ　多様性": "ともだち　エッセイ　たようせい",
  "自分も 友達も 大切にする しっかりさん": "じぶんも ともだちも たいせつにする しっかりさん",
  "やりたいことに 真っ直ぐな": "やりたいことに まっすぐな",
  "いろんな生き方を えがいた本": "いろんないきかたを えがいたほん",
  "ホッホッホッ、\nワシと {{nickname}}さんは ズッ友じゃな！": "ホッホッホッ、\nワシと {{nickname}}さんは ズッともじゃな！",
  "笑える　昔話　ユーモア": "わらえる　むかしばなし　ユーモア",
  "面白いことが 大好きな おじいちゃん": "おもしろいことが だいすきな おじいちゃん",
  "お茶目な": "おちゃめな",
  "つい笑顔になれちゃう ユーモアたっぷりの本": "ついえがおになれちゃう ユーモアたっぷりのほん",
  "ワシのおすすめの本、ヨンデミー！": "ワシのおすすめのほん、ヨンデミー！",
  "本生物にたとえるなら......": "ほんせいぶつにたとえるなら......",
  "おすすめ理由": "おすすめりゆう",
  "と 仲良くなれそう！": "と なかよくなれそう！",
  "こんな本たちを おすすめするよ。": "こんなほんたちを おすすめするよ。",
  "他にも いろんな本を おすすめするので、楽しみにしていてね😉": "ほかにも いろんなほんを おすすめするので、たのしみにしていてね😉",
  "診断結果をとじる": "しんだんけっかをとじる",
  "答えてくれてありがとう！\nふむふむ……{{ nickname }}さんのキャラクタータイプは…？": "こたえてくれてありがとう！\nふむふむ……{{ nickname }}さんのキャラクタータイプは…？",
  "こんにちは！\nおすすめジャンル診断へようこそ！": "こんにちは！\nおすすめジャンルしんだんへようこそ！",
  "これからヨンデミー先生が{{nickname}}さんに質問をするよ！\n自分に一番近いものを選んで答えてね😉": "これからヨンデミーせんせいが{{nickname}}さんにしつもんをするよ！\nじぶんにいちばんちかいものをえらんでこたえてね😉",
  "すると、自分に近いヨンデミーキャラクターがわかるんだ！\nそのキャラクターのおすすめも、おすすめの本に入るようになるよ": "すると、じぶんにちかいヨンデミーキャラクターがわかるんだ！\nそのキャラクターのおすすめも、おすすめのほんにはいるようになるよ",
  "誰と近いタイプなのか、ワクワクするね！\nそれじゃあ早速始めるよ！": "だれとちかいタイプなのか、ワクワクするね！\nそれじゃあさっそくはじめるよ！",
  "YL相談をやめる": "YLそうだんをやめる",
  "相談を続ける": "そうだんをつづける",
  "相談をやめますか？": "そうだんをやめますか？",
  "ジャンル診断をやめますか？": "ジャンルしんだんをやめますか？",
  "診断をやめる": "しんだんをやめる",
  "診断を続ける": "しんだんをつづける",
  "YL相談所へ ようこそ！\nヨンデミー先生と 一緒に\n{{ nickname }}さんに\nぴったりの YLを 探してみよう": "YLそうだんじょへ ようこそ！\nヨンデミーせんせいと いっしょに\n{{ nickname }}さんに\nぴったりの YLを さがしてみよう",
  "今の おすすめの 本は\nかんたん？\nそれとも、むずかしい？": "いまの おすすめの ほんは\nかんたん？\nそれとも、むずかしい？",
  "パッと 文章を 見て、\nかんたんか むずかしいか\n教えてね！": "パッと ぶんしょうを みて、\nかんたんか むずかしいか\nおしえてね！",
  "ふむふむ、なるほど……\n{{ nickname }}さんに\nぴったりの YLを見つけたよ\nおすすめの本を 探しておくね": "ふむふむ、なるほど……\n{{ nickname }}さんに\nぴったりの YLをみつけたよ\nおすすめのほんを さがしておくね",
  "うまく受け取れませんでした\nもう一度、ボタンを押してね": "うまくうけとれませんでした\nもういちど、ボタンをおしてね",
  "本を削除しました。YLが合わないときは、\nYL相談をおすすめします。": "ほんをさくじょしました。YLがあわないときは、\nYLそうだんをおすすめします。",
  "相談する": "そうだんする",
  "答えてくれてありがとう！": "こたえてくれてありがとう！",
  "この文章は どうだった？": "このぶんしょうは どうだった？",
  "YL相談所へようこそ！\nヨンデミー先生と一緒に{{ nickname }}さんが読む本を探していこう！": "YLそうだんじょへようこそ！\nヨンデミーせんせいといっしょに{{ nickname }}さんがよむほんをさがしていこう！",
  "ヨンデミー先生は、{{ nickname }}さんのことを知ることで、よりパワーアップするんだ！": "ヨンデミーせんせいは、{{ nickname }}さんのことをしることで、よりパワーアップするんだ！",
  "ヨンデミー先生とお話ししてくれるかな？": "ヨンデミーせんせいとおはなししてくれるかな？",
  "ありがとう！\nそれじゃあ、これから出す質問に答えてね。": "ありがとう！\nそれじゃあ、これからだすしつもんにこたえてね。",
  "たくさん質問に答えてくれてありがとう！\n{{ nickname }}さんのこと、たくさん知れて嬉しいな！": "たくさんしつもんにこたえてくれてありがとう！\n{{ nickname }}さんのこと、たくさんしれてうれしいな！",
  "パワーアップしたおすすめの本、きっと面白いものがたくさんあるから、ぜひ読んでみてね！": "パワーアップしたおすすめのほん、きっとおもしろいものがたくさんあるから、ぜひよんでみてね！",
  "まだおすすめ・よみま書架の本がないよ。ヨンデミー先生のおすすめを待っててね。": "まだおすすめ・よみま書架のほんがないよ。ヨンデミーせんせいのおすすめをまっててね。",
  "お手紙が届いているよ👀✨": "おてがみがとどいているよ👀✨",
  "開けてみる": "あけてみる",
  "読書家の{{nickname}}さんへ": "どくしょかの{{nickname}}さんへ",
  "{{age}}才の": "{{age}}さいの",
  "お誕生日、おめでとう🎊": "おたんじょうび、おめでとう🎊",
  "お話しするのは 照れちゃうから、": "おはなしするのは てれちゃうから、",
  "お手紙で 気持ちを 伝えるよ😳": "おてがみで きもちを つたえるよ😳",
  "{{nickname}}さんと 出会って": "{{nickname}}さんと であって",
  "もう{{period}}日だね✨": "もう{{period}}にちだね✨",
  "まだ 出会ったばかり だけど": "まだ であったばかり だけど",
  "{{nickname}}さんの": "{{nickname}}さんの",
  "素敵なところ たくさん 見つけたよ": "すてきなところ たくさん みつけたよ",
  "とっても 楽しかったよ！": "とっても たのしかったよ！",
  "これからも、": "これからも、",
  "{{nickname}}さんと一緒に": "{{nickname}}さんといっしょに",
  "たくさん 本を 読みたいな🥰": "たくさん ほんを よみたいな🥰",
  "感想を": "かんそうを",
  "楽しみに 待っているね👀✨": "たのしみに まっているね👀✨",
  "{{nickname}}さんのことが大好きな": "{{nickname}}さんのことがだいすきな",
  "ヨンデミー先生より": "ヨンデミーせんせいより",
  "読んだ本はどんどん感想をかいて、自分専用の本だなを作ろう！": "よんだほんはどんどんかんそうをかいて、じぶんせんようのほんだなをつくろう！",
  "自分で選んだ本": "じぶんでえらんだほん",
  "すべての本": "すべてのほん",
  "ヨンデミー先生から お手紙が 来ています！": "ヨンデミーせんせいから おてがみが きています！",
  "{{user}}さん、こんにちは！\n会えて 嬉しいな😊\n今日の お話は これ！": "{{user}}さん、こんにちは！\nあえて うれしいな😊\nきょうの おはなしは これ！",
  "こんにちは！": "こんにちは！",
  "お話できて 楽しかったよ！\nこのまま ミニレッスンも 受けちゃう？": "おはなしできて たのしかったよ！\nこのまま ミニレッスンも うけちゃう？",
  "ミニレッスンを 受ける": "ミニレッスンを うける",
  "あとにする": "あとにする",
  "それじゃあ、今日も 本の世界を 楽しんでね😊": "それじゃあ、きょうも ほんのせかいを たのしんでね😊",
  "はーい！": "はーい！",
  "また後で！待ってるね！": "またあとで！まってるね！",
  "ヨンデミーの心得　その1": "ヨンデミーのこころえ　その1",
  "読まなくてもよし！": "よまなくてもよし！",
  "読みたい 本を 読みたい ときに 楽しんで 読もう✨": "よみたい ほんを よみたい ときに たのしんで よもう✨",
  "読みたいときに読む！": "よみたいときによむ！",
  "ヨンデミーの心得　その2": "ヨンデミーのこころえ　その2",
  "自分で選んでもよし！": "じぶんでえらんでもよし！",
  "{{user}}さんが 選んだ 本を ヨンデミー先生に 教えてね。": "{{user}}さんが えらんだ ほんを ヨンデミーせんせいに おしえてね。",
  "自分でも選ぶ！": "じぶんでもえらぶ！",
  "ヨンデミーの心得　その3": "ヨンデミーのこころえ　その3",
  "簡単な 本も 楽しんでよし！": "かんたんな ほんも たのしんでよし！",
  "簡単な 本を 深く 楽しむのも 立派な 読書家だよ": "かんたんな ほんを ふかく たのしむのも りっぱな どくしょかだよ",
  "簡単な本も 楽しむ！": "かんたんなほんも たのしむ！",
  "ヨンデミーの心得　その4": "ヨンデミーのこころえ　その4",
  "途中で 感想を 出してもよし！": "とちゅうで かんそうを だしてもよし！",
  "1日で 読み切らなくても、途中までの 感想を 教えてくれると 嬉しいな": "1にちで よみきらなくても、とちゅうまでの かんそうを おしえてくれると うれしいな",
  "途中でも 出していい！": "とちゅうでも だしていい！",
  "{{user}}さんは、いつも どんなときに 本を 読んでいるかな?\n夜 寝る前、ご飯の後 など、好きな タイミングで": "{{user}}さんは、いつも どんなときに ほんを よんでいるかな?\nよる ねるまえ、ごはんのあと など、すきな タイミングで",
  "毎日の 読書時間": "まいにちの どくしょじかん",
  "を 作ってみよう✨": "を つくってみよう✨",
  "ふむふむ": "ふむふむ",
  "レベル": "レベル",
  "は {{user}}さんの 読書家としての 頑張りが 積み重なって いくもの！\nレベル100まで いけるかな？": "は {{user}}さんの どくしょかとしての がんばりが つみかさなって いくもの！\nレベル100まで いけるかな？",
  "いくぞー！": "いくぞー！",
  "ヨンデミーレベル（YL）": "ヨンデミーレベル（YL）",
  "っていうのは、本の 難しさ のことだよ！\n{{user}}さんに ピッタリの ヨンデミーレベルの 本を 見つけてくるから、楽しみに していてね": "っていうのは、ほんの むずかしさ のことだよ！\n{{user}}さんに ピッタリの ヨンデミーレベルの ほんを みつけてくるから、たのしみに していてね",
  "なるほど！": "なるほど！",
  "ヨンデミー先生は いつも、": "ヨンデミーせんせいは いつも、",
  "{{user}}さんの 感想をもとに": "{{user}}さんの かんそうをもとに",
  "、次の おすすめの 本を 探しているよ✨": "、つぎの おすすめの ほんを さがしているよ✨",
  "そうなんだ": "そうなんだ",
  "{{user}}さんに ぴったりの 本を おすすめするために、": "{{user}}さんに ぴったりの ほんを おすすめするために、",
  "自分の 気持ちに 合った 感想を 選ぶ": "じぶんの きもちに あった かんそうを えらぶ",
  "ようにしてね😉": "ようにしてね😉",
  "はーい🙌": "はーい🙌",
  "今日は『": "きょうは『",
  "』を 読むって 言っていたね😊": "』を よむって いっていたね😊",
  "感想を 楽しみに 待ってるよ🙌": "かんそうを たのしみに まってるよ🙌",
  "読む💪": "よむ💪",
  "やったー😆": "やったー😆",
  "昨日 読んでいた『{{title}}』の {{user}}さんの 感想を 見ました！\nとっても {{review}}よ！\n今日も、本の 感想を 聞かせてくれると 嬉しいな😊": "きのう よんでいた『{{title}}』の {{user}}さんの かんそうを みました！\nとっても {{review}}よ！\nきょうも、ほんの かんそうを きかせてくれると うれしいな😊",
  "素敵だった": "すてきだった",
  "面白かった": "おもしろかった",
  "楽しかった": "たのしかった",
  "共感できた": "きょうかんできた",
  "やったー😀": "やったー😀",
  "今日は どの本を 読みたい？": "きょうは どのほんを よみたい？",
  "好きな 本を 選んで 読んでみよう👀": "すきな ほんを えらんで よんでみよう👀",
  "{{user}}さんが 選んだ本、おもしろそう！": "{{user}}さんが えらんだほん、おもしろそう！",
  "ぜひ、感想を 聞かせてね😉": "ぜひ、かんそうを きかせてね😉",
  "君の力で、図書館を助けよう！": "きみのちからで、としょかんをたすけよう！",
  "ストーリーをもう一度見る": "ストーリーをもういちどみる",
  "出そう": "だそう",
  "出した": "だした",
  "やろう": "やろう",
  "やった": "やった",
  "書こう": "かこう",
  "書いた": "かいた",
  "感想連続日数": "かんそうれんぞくにっすう",
  "で出そう": "でだそう",
  "で出した": "でだした",
  "ミニレッスンを受けると、スタンプがたまるよ！": "ミニレッスンをうけると、スタンプがたまるよ！",
  "今日のミニレッスンを受ける": "きょうのミニレッスンをうける",
  "スタンプを押すね！": "スタンプをおすね！",
  "やった😆": "やった😆",
  "わーい😊": "わーい😊",
  "明日も ミニレッスンを 受けると スタンプが 貯まっていくよ📚✨": "あしたも ミニレッスンを うけると スタンプが たまっていくよ📚✨",
  "貯めたい！✨": "ためたい！✨",
  "がんばるぞ💪": "がんばるぞ💪",
  "{{nickname}}さん、このまま本も読んじゃう？": "{{nickname}}さん、このままほんもよんじゃう？",
  "読む！": "よむ！",
  "後でね！": "あとでね！",
  "それじゃあ、本の世界へ出発📚✨": "それじゃあ、ほんのせかいへしゅっぱつ📚✨",
  "出発！": "しゅっぱつ！",
  "それじゃあ、本の世界で待ってるよ👋": "それじゃあ、ほんのせかいでまってるよ👋",
  "わかった！": "わかった！",
  "おすすめの本にない本の 感想も 送ることができるよ！": "おすすめのほんにないほんの かんそうも おくることができるよ！",
  "右下の えんぴつのボタンを 押してみよう": "みぎしたの えんぴつのボタンを おしてみよう",
  "ヨンデミー先生から 本のおすすめが 届くのを 待ってね！": "ヨンデミーせんせいから ほんのおすすめが とどくのを まってね！",
  "選んだ本をよみま書架から消しますか？": "えらんだほんをよみま書架からけしますか？",
  "消す": "けす",
  "「本の友」で他の人の感想を読んで、よみま書架に入れた本だよ": "「ほんのとも」でほかのひとのかんそうをよんで、よみま書架にいれたほんだよ",
  "まだよみま書架に本がないよ": "まだよみま書架にほんがないよ",
  "本の友に行って": "ほんのともにいって",
  "他の人の感想を見てみよう": "ほかのひとのかんそうをみてみよう",
  "本の友を見る": "ほんのともをみる",
  "感想を書く": "かんそうをかく",
  "{{user}}さんのために本を探してきたよ！\n読んでもいいか、教えてね！": "{{user}}さんのためにほんをさがしてきたよ！\nよんでもいいか、おしえてね！",
  "😆 読みたい！": "😆 よみたい！",
  "🙂 読んでもいいよ": "🙂 よんでもいいよ",
  "🤨 びみょうかなぁ": "🤨 びみょうかなぁ",
  "ぶんしょうで書いてみる": "ぶんしょうでかいてみる",
  "ももたろうが動物たちにきびだんごをあげるところ": "ももたろうがどうぶつたちにきびだんごをあげるところ",
  "すでに ヨンデミー先生に 提出した 感想を 書き直すことが できます！": "すでに ヨンデミーせんせいに ていしゅつした かんそうを かきなおすことが できます！",
  "お気に入りのシーン": "おきにいりのシーン",
  "これでOK": "これでOK",
  "あと{{rest}}冊": "あと{{rest}}さつ",
  "もうすぐ次のおすすめが届くよ。待っててね！": "もうすぐつぎのおすすめがとどくよ。まっててね！",
  "おすすめの本の": "おすすめのほんの",
  "残りが{{border}}冊": "のこりが{{border}}さつ",
  "になったら、次のおすすめが届くよ。": "になったら、つぎのおすすめがとどくよ。",
  "おすすめの本が届いたよ": "おすすめのほんがとどいたよ",
  "届いた本を開ける": "とどいたほんをあける",
  "読んでいるとちゅう": "よんでいるとちゅう",
  "まだ読んでない": "まだよんでない",
  "あと{{rest}}冊だよ！！": "あと{{rest}}さつだよ！！",
  "もうすぐ おすすめの本がとどきます。🎉": "もうすぐ おすすめのほんがとどきます。🎉",
  "しばらくすると 新しい本が届きます！": "しばらくすると あたらしいほんがとどきます！",
  "少々お待ちください！": "しょうしょうおまちください！",
  "おすすめの本の残りが{{border}}冊になったら、次のおすすめが届きます！": "おすすめのほんののこりが{{border}}さつになったら、つぎのおすすめがとどきます！",
  "くわしく見る": "くわしくみる",
  "新着": "しんちゃく",
  "ヨンデミー先生からおすすめされた本だよ！": "ヨンデミーせんせいからおすすめされたほんだよ！",
  "本を予約・管理する": "ほんをよやく・かんりする",
  "YL相談所": "YLそうだんじょ",
  "レベルアップ！": "レベルアップ！",
  "この調子！": "このちょうし！",
  "{{ nickname }}さんに、\nぜひ この 本たちを 助けてほしい！🤝✨": "{{ nickname }}さんに、\nぜひ この ほんたちを たすけてほしい！🤝✨",
  "図書館で 予約するか、本屋さんで 買ってみてね👀✨": "としょかんで よやくするか、ほんやさんで かってみてね👀✨",
  "次の本も、楽しんで 読んでね！🤝": "つぎのほんも、たのしんで よんでね！🤝",
  "{{nickname}}さん、お待たせしました！\n\n新しくおすすめをえらんだよ😆": "{{nickname}}さん、おまたせしました！\n\nあたらしくおすすめをえらんだよ😆",
  "ヨンデミー先生は まだまだたくさんの本を 知っているよ💪\n\n{{nickname}}さんに ピッタリの本を、たっぷり考えてみました😊": "ヨンデミーせんせいは まだまだたくさんのほんを しっているよ💪\n\n{{nickname}}さんに ピッタリのほんを、たっぷりかんがえてみました😊",
  "ヨンデミー先生は、「きっと{{nickname}}さんにピッタリだ！」って 思ってたんだ😊\n\n「{{like}}」って 教えてくれて、とてもうれしかった！": "ヨンデミーせんせいは、「きっと{{nickname}}さんにピッタリだ！」って おもってたんだ😊\n\n「{{like}}」って おしえてくれて、とてもうれしかった！",
  "この本が「{{like}}」ってことは……。\n\n次も とっておきの本を 思いついちゃったよ😎✨": "このほんが「{{like}}」ってことは……。\n\nつぎも とっておきのほんを おもいついちゃったよ😎✨",
  "この本は、{{nickname}}さんにとって 「{{feeling}}」な本だったんだね😊": "このほんは、{{nickname}}さんにとって 「{{feeling}}」なほんだったんだね😊",
  "{{ nickname }}さんにいのちを吹き込まれて とってもうれしそうだったよ💞": "{{ nickname }}さんにいのちをふきこまれて とってもうれしそうだったよ💞",
  "{{nickname}}さん、{{day}}読んだ『{{title}}』のことは 覚えている？👀✨": "{{nickname}}さん、{{day}}よんだ『{{title}}』のことは おぼえている？👀✨",
  "きっと {{ nickname }}さんに ピッタリだと 思っていたから、\n{{ like }}って 教えてくれて、とてもうれしかったよ！🥰": "きっと {{ nickname }}さんに ピッタリだと おもっていたから、\n{{ like }}って おしえてくれて、とてもうれしかったよ！🥰",
  "{{nickname}}さん、前回のおすすめには「好き」って思える本がなかったみたいだね🤔": "{{nickname}}さん、ぜんかいのおすすめには「すき」っておもえるほんがなかったみたいだね🤔",
  "でも 大丈夫😊\n\nもう一度、{{nickname}}さんにピッタリの本を 考えてみました😃": "でも だいじょうぶ😊\n\nもういちど、{{nickname}}さんにピッタリのほんを かんがえてみました😃",
  "でもね！\n{{ nickname }}さんの おかげで、たくさんの 本たちが 元気を取り戻したんだ📚✨": "でもね！\n{{ nickname }}さんの おかげで、たくさんの ほんたちが げんきをとりもどしたんだ📚✨",
  "さて、次のおすすめは……😏": "さて、つぎのおすすめは……😏",
  "さて、そんな{{nickname}}さんへおすすめする本は……😏": "さて、そんな{{nickname}}さんへおすすめするほんは……😏",
  "{{nickname}}さん、お待たせしました！\n\n{{nickname}}さんに とっておきの本を おすすめするよ😆": "{{nickname}}さん、おまたせしました！\n\n{{nickname}}さんに とっておきのほんを おすすめするよ😆",
  "さっきはヨンデミー先生に いろいろなことを 教えてくれて、ありがとう！\n\nおかげで、{{nickname}}さんには どんな本がぴったりかなー？と、楽しく考えることが できました😌": "さっきはヨンデミーせんせいに いろいろなことを おしえてくれて、ありがとう！\n\nおかげで、{{nickname}}さんには どんなほんがぴったりかなー？と、たのしくかんがえることが できました😌",
  "苦手": "にがて",
  "好きではない": "すきではない",
  "もう一度読みたい": "もういちどよみたい",
  "今日": "きょう",
  "昨日": "きのう",
  "一昨日": "いっさくじつ",
  "{{num}}日前に": "{{num}}にちまえに",
  "冒険のはじまり": "ぼうけんのはじまり",
  "おもいえがくのワザ": "おもいえがくのワザ",
  "新たな仲間たち": "あらたななかまたち",
  "つなげるのワザ": "つなげるのワザ",
  "ピンクのキリン": "ピンクのキリン",
  "しつもんするのワザ": "しつもんするのワザ",
  "図書館に力を送るんだ": "としょかんにちからをおくるんだ",
  "出して、": "だして、",
  "ミニレッスン": "ミニレッスン",
  "受けて、": "うけて、",
  "書いて、": "かいて、",
  "で出して、": "でだして、",
  "音が流れるから": "おとがながれるから",
  "音声をオンにしてね！": "おんせいをオンにしてね！",
  "伝説の読書家": "でんせつのどくしょか",
  "目指して": "めざして",
  "挑戦": "ちょうせん",
  "それじゃあ、明日から\n次のヨンデミッションがはじまるよ！\nお楽しみに〜": "それじゃあ、あしたから\nつぎのヨンデミッションがはじまるよ！\nおたのしみに～",
  "のアイコンが使えるようになったよ🎉": "のアイコンがつかえるようになったよ🎉",
  "音声の 再生が 許可されて いないようです。": "おんせいの さいせいが きょかされて いないようです。",
  "音声を 出して もう一度 見てみてね。": "おんせいを だして もういちど みてみてね。",
  "保護者のかたと 一緒に見てね！": "ほごしゃのかたと いっしょにみてね！",
  "本の友にいく！": "ほんのともにいく！",
  "新しく 使えるようになった「本の友」に 行ってみる？": "あたらしく つかえるようになった「ほんのとも」に いってみる？",
  "なにやら 本の虫が 本の友で 感想を 出しているみたい……": "なにやら ほんのむしが ほんのともで かんそうを だしているみたい……",
  "本の友に 一緒に 行ってみよう！": "ほんのともに いっしょに いってみよう！",
  "本の虫の 感想があるね！": "ほんのむしの かんそうがあるね！",
  "❤️ いいねを 送ってみよう！": "❤️ いいねを おくってみよう！",
  "いいねが 送れたよ！": "いいねが おくれたよ！",
  "本の虫に 気持ちが 伝わったね！": "ほんのむしに きもちが つたわったね！",
  "本の虫からも {{user}}さんの 感想に いいねが 届いているみたい……。": "ほんのむしからも {{user}}さんの かんそうに いいねが とどいているみたい……。",
  "通知を 開いてみよう！": "つうちを ひらいてみよう！",
  "本の友へ ようこそ！": "ほんのともへ ようこそ！",
  "この調子で、他の友達の 感想にも いいねを 送ってみよう！": "このちょうしで、ほかのともだちの かんそうにも いいねを おくってみよう！",
  "きっと 喜んでもらえるよ✨": "きっと よろこんでもらえるよ✨",
  "本の友にいく": "ほんのともにいく",
  "ペンギンせんぱいと  お話しながら、感想が  書けるよ": "ペンギンせんぱいと  おはなししながら、かんそうが  かけるよ",
  "1日  2回まで  つかえるよ！": "1にち  2かいまで  つかえるよ！",
  "「感想」のヒミツを教えます😎": "「かんそう」のヒミツをおしえます😎",
  "{{ nickname }}さんが 感想を たくさん送ってくれるほど、": "{{ nickname }}さんが かんそうを たくさんおくってくれるほど、",
  "{{ nickname }}さんに ピッタリの本を おすすめできるように なります😊": "{{ nickname }}さんに ピッタリのほんを おすすめできるように なります😊",
  "「苦手だなぁ」と思った 本の感想も、そのまま 教えてください！": "「にがてだなぁ」とおもった ほんのかんそうも、そのまま おしえてください！",
  "「苦手」もステキな{{ nickname }}さんの感想です😊": "「にがて」もステキな{{ nickname }}さんのかんそうです😊",
  "途中までしか 読めなかった日も 出して大丈夫！": "とちゅうまでしか よめなかったひも だしてだいじょうぶ！",
  "毎日 感想を出すと、バッジがもらえるかも…？": "まいにち かんそうをだすと、バッジがもらえるかも…？",
  "それじゃあ、さっそく 感想を 書いてみよう！": "それじゃあ、さっそく かんそうを かいてみよう！",
  "{{ nickname }}さんの 感想、とっても 楽しみに しているよ😊": "{{ nickname }}さんの かんそう、とっても たのしみに しているよ😊",
  "感想を 書く前に、": "かんそうを かくまえに、",
  "ヨンデミー先生から {{ nickname }}さんに お願いがあるんだ！": "ヨンデミーせんせいから {{ nickname }}さんに おねがいがあるんだ！",
  "なになに👀": "なになに👀",
  "{{ nickname }}さんが 本を 読んで、思ったことは そのまま 教えてね👍": "{{ nickname }}さんが ほんを よんで、おもったことは そのまま おしえてね👍",
  "「難しい」とか「苦手」も ステキな 感想です😊": "「むずかしい」とか「にがて」も ステキな かんそうです😊",
  "まかせて！": "まかせて！",
  "{{ nickname }}さんが 感想を 教えてくれると、": "{{ nickname }}さんが かんそうを おしえてくれると、",
  "ヨンデミー先生は もっとピッタリの 本をおすすめできるようになるよ😎✨": "ヨンデミーせんせいは もっとピッタリの ほんをおすすめできるようになるよ😎✨",
  "本たちも、": "ほんたちも、",
  "{{ nickname }}さんの 感想を 待っているよ📚💞": "{{ nickname }}さんの かんそうを まっているよ📚💞",
  "それじゃあ、さっそく 感想を 書いてみよう💪": "それじゃあ、さっそく かんそうを かいてみよう💪",
  "感想を 書く": "かんそうを かく",
  "感想を 書く！": "かんそうを かく！",
  "記録": "きろく",
  "記録を見る": "きろくをみる",
  "連続": "れんぞく",
  "個": "こ",
  "日": "にち",
  "提出": "ていしゅつ",
  "編集する": "へんしゅうする",
  "{{nickname}}さんに折り紙のプレゼント！": "{{nickname}}さんにおりがみのプレゼント！",
  "感想をもとに、こんな折り紙を作ってみたよ！": "かんそうをもとに、こんなおりがみをつくってみたよ！",
  "ペンギンせんぱいと書いた感想": "ペンギンせんぱいとかいたかんそう",
  "一緒にお話しながら作った感想だよ！": "いっしょにおはなしながらつくったかんそうだよ！",
  "ペンギンせんぱいが、プレゼントのおりがみを折っています…": "ペンギンせんぱいが、プレゼントのおりがみをおっています…",
  "※おりがみを折るのに、じかんがかかることがあります": "※おりがみをおるのに、じかんがかかることがあります",
  "ここは　カギが　かかっていて\n使えないよ。": "ここは　カギが　かかっていて\nつかえないよ。",
  "{{count}}回": "{{count}}かい",
  "出したら": "だしたら",
  "カギがあくみたい……": "カギがあくみたい……",
  "{{ user }}さん、\n約束通りに 感想をとどけてくれてありがとう！さすがだね😆✨": "{{ user }}さん、\nやくそくどおりに かんそうをとどけてくれてありがとう！さすがだね😆✨",
  "{{ user }}さん、\n感想をとどけてくれてありがとう！": "{{ user }}さん、\nかんそうをとどけてくれてありがとう！",
  "{{ user }}さん、『{{ title }}』はほかの子に 託したんだね！✨": "{{ user }}さん、『{{ title }}』はほかのこに たくしたんだね！✨",
  "感想を 教えてくれて、ありがとう🤗\nヨンデミー先生も、その本を 読んでみたく なっちゃった👀✨": "かんそうを おしえてくれて、ありがとう🤗\nヨンデミーせんせいも、そのほんを よんでみたく なっちゃった👀✨",
  "すてきな 本を 教えてくれて、うれしいな🥰\nこれからも 感想を 聞かせてね🙌": "すてきな ほんを おしえてくれて、うれしいな🥰\nこれからも かんそうを きかせてね🙌",
  "さすが {{ user }}さん！\nワクワクする とっても すてきな 感想だったよ😆✨": "さすが {{ user }}さん！\nワクワクする とっても すてきな かんそうだったよ😆✨",
  "新しい 本を 見つけると ワクワクするよね😊\nこれからも {{ user }}さんの 感想が 楽しみだ〜！": "あたらしい ほんを みつけると ワクワクするよね😊\nこれからも {{ user }}さんの かんそうが たのしみだ～！",
  "次は どの 本を たすけてくれるかな？✨": "つぎは どの ほんを たすけてくれるかな？✨",
  "スキップする": "スキップする",
  "{{ user }}さんがどんな本を選ぶのか、楽しみです！\nいつ感想をとどけてくれるかな👀✨\n約束の時間を一緒に決めよう": "{{ user }}さんがどんなほんをえらぶのか、たのしみです！\nいつかんそうをとどけてくれるかな👀✨\nやくそくのじかんをいっしょにきめよう",
  "『{{ nextbook }}』を読むんだね！\nいつ感想をとどけてくれるかな👀✨\n約束の時間を一緒に決めよう": "『{{ nextbook }}』をよむんだね！\nいつかんそうをとどけてくれるかな👀✨\nやくそくのじかんをいっしょにきめよう",
  "次はいつ感想をとどけてくれるかな👀✨\n約束の時間を一緒に決めよう": "つぎはいつかんそうをとどけてくれるかな👀✨\nやくそくのじかんをいっしょにきめよう",
  "なんと！\n読書家パワー全開だね!\n感想を楽しみに待ってるよ👀✨": "なんと！\nどくしょかパワーぜんかいだね!\nかんそうをたのしみにまってるよ👀✨",
  "{{dueDateMessage}}": "{{dueDateMessage}}",
  "も感想を届けてくれるんだね\n\nヨンデミー先生、楽しみにしているね😊\nまたね〜👋": "もかんそうをとどけてくれるんだね\n\nヨンデミーせんせい、たのしみにしているね😊\nまたね～👋",
  "わかった！\n教えてくれて ありがとう😊\n\n{{ user }}さんの次の感想も 楽しみにしてるよ📚✨": "わかった！\nおしえてくれて ありがとう😊\n\n{{ user }}さんのつぎのかんそうも たのしみにしてるよ📚✨",
  "わかった👍": "わかった👍",
  "今はしない！": "いまはしない！",
  "本当に約束しなくてもいい？": "ほんとうにやくそくしなくてもいい？",
  "今回はしない！": "こんかいはしない！",
  "やっぱり約束する💪": "やっぱりやくそくする💪",
  "今日はもっと読む": "きょうはもっとよむ",
  "明後日": "あさって",
  "明々後日": "しあさって",
  "他の子の 感想を送ります！　ぜひ読んでみてね♪": "ほかのこの かんそうをおくります！　ぜひよんでみてね♪",
  "自分の感想": "じぶんのかんそう",
  "感想を追加する": "かんそうをついかする",
  "次は": "つぎは",
  "目指そう！": "めざそう！",
  "何が見られるようになるかな......？": "なにがみられるようになるかな......？",
  "一言感想が書けるようになったよ🎉": "ひとことかんそうがかけるようになったよ🎉",
  "みんなの感想が見られるようになったよ🎉": "みんなのかんそうがみられるようになったよ🎉",
  "みんなの感想にいいねができるようになったよ🎉": "みんなのかんそうにいいねができるようになったよ🎉",
  "記録をシェアできるよ🎉": "きろくをシェアできるよ🎉",
  "スターを送れるよ🎉": "スターをおくれるよ🎉",
  "君の 読みたい本 専用の 本棚だよ": "きみの よみたいほん せんようの ほんだなだよ",
  "よみま書架": "よみま書架",
  "使えるよ": "つかえるよ",
  "表示": "ひょうじ",
  "隠す": "かくす",
  "読まなかった": "よまなかった",
  "途中": "とちゅう",
  "もう一度ボタンを押してください。": "もういちどボタンをおしてください。"
};