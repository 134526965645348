import React from "react";
import styled from "styled-components";
import { KansouSupportChatMessage } from "../../../KansouSupportChatMessage";
import { MessageItemText } from "~/components/molecules/texts/MessageItemText";
import { SmallButton } from "~/components/atoms/buttons/Button";
import { Text } from "~/components/atoms/texts/Text";
import { Stack } from "~/components/atoms/layout/Stack";
import { FlatRadioButton } from "~/components/atoms/forms/RadioButton/FlatRadioButton";
import { KansouSupportChatMessages } from "~/utils/kansouSupportChat";

export type MultiChoiceMessageProps = {
  message: KansouSupportChatMessages;
  latestMessageID?: string;
  buttonText?: string;
  onSubmit: (event: React.MouseEvent) => void;
  onCheck: (i: number) => void;
  values: number[];
};

export const MultiChoiceMessage: React.FC<MultiChoiceMessageProps> = ({
  message,
  latestMessageID,
  buttonText,
  onSubmit,
  onCheck,
  values,
}) => {
  return (
    <KansouSupportChatMessage role={message.role}>
      {message.value && (
        <MessageItemText
          textType="plain"
          showAll={true}
          onCompleted={() => {}}
          text={message.value}
        />
      )}
      <_Stack>
        {(message.choices || []).map((item, index) => (
          <FlatRadioButton
            selected={values.indexOf(index) > -1}
            onClick={() => onCheck(index)}
            disabled={latestMessageID !== message.id}
            color="pri"
            shape="Normal"
            key={index}
          >
            <Text fontSize="LG" lineHeight="LG">
              {item.choice}
            </Text>
          </FlatRadioButton>
        ))}
        <SmallButton
          disabled={latestMessageID !== message.id}
          onClick={onSubmit}
        >
          {buttonText}
        </SmallButton>
      </_Stack>
    </KansouSupportChatMessage>
  );
};

const _Stack = styled(Stack)`
  margin-top: 12px;
`;
