import React, { ReactNode } from "react";
import styled from "styled-components";
import { ChatBubble as BaseChatBubble } from "~/components/atoms/wrappers/ChatBubble";
import {
  DeletedUserOnTalkFragment,
  UserOnTalkFragment,
} from "~/generated/graphql";
import {
  MessageRoleType,
  MessageRoleEnum,
} from "~/helpers/constants/kansouSupportChat";

export type KansouSupportChatMessageProps = {
  user?: UserOnTalkFragment | DeletedUserOnTalkFragment;
  children: ReactNode;
  role: MessageRoleType;
};

export const KansouSupportChatMessage: React.FC<
  KansouSupportChatMessageProps
> = ({ children, role }) => {
  const isMyMessage = role === MessageRoleEnum.user;
  const chatBubbleColor = isMyMessage ? "orange" : "white";

  return (
    <ChatMessageContainer isMyMessage={isMyMessage}>
      <ChatMessageInner>
        {!isMyMessage && <UserIcon src={"/img/character/penguin.svg"} />}
        <MessageContainer>
          <ChatBubble variant={chatBubbleColor}>{children}</ChatBubble>
        </MessageContainer>
      </ChatMessageInner>
    </ChatMessageContainer>
  );
};

const ChatMessageContainer = styled.div<{
  isMyMessage: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: ${({ isMyMessage }) =>
    isMyMessage ? "flex-end" : "flex-start"};
`;

const ChatMessageInner = styled.div`
  display: flex;
  max-width: 80vw;
  @media screen and (min-width: 600px) {
    max-width: 460px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ChatBubble = styled(BaseChatBubble)`
  width: 100%;
`;

const UserIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 0 10px 0 0;
`;
