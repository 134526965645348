import React, { useMemo } from "react";
import styled from "styled-components";
import {
  ListItemProps,
  MessageList,
} from "~/components/molecules/lists/MessageList";
import { KansouSupportChatListItem } from "../KansouSupportChatListItem";
import { KansouSupportChatMessages } from "~/utils/kansouSupportChat";

export type KansouSupportChatListProps = {
  data?: KansouSupportChatMessages[];
  latestMessageID?: string;
  fetchAll: boolean;
  className?: string;
  firstItemIndex: number;
  setIsScrolling: ((isScrolling: boolean) => void) | undefined;
  onUpload: () => void;
  onSubmit: (event: React.MouseEvent, value: string) => void;
};

export type KansouSupportChatItem = KansouSupportChatMessages & {
  firstUnreadItem?: boolean;
};

export const KansouSupportChatList: React.FC<KansouSupportChatListProps> = ({
  data,
  latestMessageID,
  fetchAll,
  className,
  firstItemIndex,
  setIsScrolling,
  onUpload,
  onSubmit,
}) => {
  data;
  const items: KansouSupportChatItem[] = useMemo(() => {
    const _items = data as unknown as KansouSupportChatItem[];
    return _items;
  }, [data, firstItemIndex]);

  return (
    <MessageList
      className={className}
      items={items}
      fetchAll={fetchAll}
      onPrevLoading={() => Promise.resolve()}
      firstItemIndex={firstItemIndex}
      isScrolling={setIsScrolling}
      renderListItem={({ item }: ListItemProps<KansouSupportChatItem>) => {
        return (
          <_MessageListItemWrapper>
            <KansouSupportChatListItem
              key={item.id}
              latestMessageID={latestMessageID}
              item={item}
              onUpload={onUpload}
              onSubmit={onSubmit}
            />
          </_MessageListItemWrapper>
        );
      }}
    />
  );
};

const _MessageListItemWrapper = styled.div`
  padding: 5px 20px;
  @media screen and (min-width: 600px) {
    padding: 5px 60px;
  }
`;
