import React, { VFC } from "react";
import styled from "styled-components";
import { EnterReviewTemplateProps } from "./type";
import { ProgressBar } from "~/components/atoms/ProgressBar";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { NavContainer, Wrapper } from "../../Common";
import {
  PostReadLogHeading,
  PostReadLogBodyWrapper,
  PostReadLogErrorMessageCon,
} from "../Common/style";
import { TextArea } from "~/components/atoms/forms/TextArea";
import { ErrorMessage } from "~/components/molecules/texts/ErrorMessage";
import {
  IconTextAnchor,
  IconTextButton,
} from "~/components/molecules/buttons/IconTextButton";
import { VoiceModal } from "~/components/organisms/modals/VoiceModal";
import { difficultyChoices } from "../ConfirmDifficulty";
import { useLengthChoices } from "../ConfirmLength";
import { useLikeChoices } from "../ConfirmLike";
import { emotions } from "../ConfirmEmotions/type";
import { TranslateKeys, useTranslation } from "~/i18n";
import { SmallTag } from "~/components/atoms/indicators/SmallTag";
import { useHintModal } from "./HintModal";
import { Text } from "~/components/atoms/texts/Text";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { TechniqueDescriptionModal } from "~/components/organisms/modals/TechniqueDescriptionModal";
import { Stack } from "~/components/atoms/layout/Stack";
import { ReviewValidationMessage } from "~/pages/PostReadLog/PostReadLogFlows/EnterReview/const";

export const EnterReviewTemplate: VFC<EnterReviewTemplateProps> = ({
  progress,
  bookTitle,
  like,
  difficulty,
  length,
  emotionsObj,
  handleBack,
  onClickGoNext,
  goNextButtonDisabled,
  onClickSkip,
  skipButtonDisabled,
  value,
  onChange,
  errorMessages,
  voiceModalOpen,
  onClickVoiceModalOpen,
  onClickVoiceModalClose,
  hintModalOpen,
  onClickHintModalOpen,
  onClickHintModalClose,
}) => {
  const { t } = useTranslation();
  const likeChoices = useLikeChoices(t);
  const lengthChoices = useLengthChoices(t);
  const { HintModal, placeHolder } = useHintModal({
    onClose: onClickHintModalClose,
  });
  const [isOpenTechniqueDescriptionModal, setIsOpenTechniqueDescriptionModal] =
    React.useState<boolean>(false);

  const isNotLike = like < 2; //苦手と答えたユーザーには別のメッセージを表示する
  return (
    <>
      <NavContainer>
        <BackNavbar titleName="かんそうをかく" onClick={handleBack} />
      </NavContainer>
      <Wrapper>
        <_BodyWrapper>
          <ProgressCon>
            <ProgressBar progress={progress} backgroundColor="base.gray" />
          </ProgressCon>
          <PostReadLogHeading fontSize={"MD"} lineHeight={"MD"} bold>
            {isNotLike ? (
              <>
                {`${t<TranslateKeys>(
                  "『{{bookTitle}}』の あまり好きではなかったところは どこだろう？",
                  { bookTitle }
                )}🤔💭`}
                <br />
                {t<TranslateKeys>(
                  "あまり好きではなかった本の 感想は「本の友」には 流れません！"
                )}
                <br />
                {`${t<TranslateKeys>(
                  "ヨンデミー先生 にだけ こっそり教えてね"
                )}😊`}
              </>
            ) : (
              <>{t<TranslateKeys>("一言感想を教えてください！")}</>
            )}
          </PostReadLogHeading>
          <TextAreaCon>
            <ChoicesCon>
              <Row>
                <SmallTag {...likeChoices[like - 1]} color={"gray"} />
                <SmallTag
                  {...difficultyChoices[difficulty - 1]}
                  color={"gray"}
                />
                <SmallTag {...lengthChoices[length - 1]} color={"gray"} />
              </Row>
              <Row>
                {Object.keys(emotionsObj)
                  .filter((key) => emotionsObj[key] === true)
                  .map((key, index) => (
                    <SmallTag key={index} {...emotions[key]} color={"gray"} />
                  ))}
              </Row>
            </ChoicesCon>
            <TextArea
              onChange={onChange}
              value={value}
              error={errorMessages.length > 0}
              placeholder={t<TranslateKeys>(
                placeHolder || "好きな感想を書いてみてね"
              )}
              style={{ height: "200px", fontFamily: "Roboto" }}
            />
            {errorMessages.length > 0 &&
              errorMessages.map((message: ReviewValidationMessage, index) => (
                <PostReadLogErrorMessageCon key={index}>
                  <ErrorMessage>{t<TranslateKeys>(message)}</ErrorMessage>
                </PostReadLogErrorMessageCon>
              ))}
            <HintContainer>
              <Stack rowGap="4px" style={{ width: "100%" }}>
                <Text
                  lineHeight="MD"
                  fontSize="XS"
                  fontColor="semantic.text.body"
                >
                  まよったら、どくしょかのワザのヒントガチャをひいてみよう！
                </Text>
                <LargeButton onClick={onClickHintModalOpen} variant="blank">
                  {t<TranslateKeys>("ヒントガチャを引く")}
                </LargeButton>
              </Stack>
              <IconTextButton
                iconPlace="pre"
                color="sec"
                onClick={() => setIsOpenTechniqueDescriptionModal(true)}
                text="どくしょかのワザとは"
                name="question"
                fontSize="XS"
                lineHeight="EQ"
              />
            </HintContainer>
          </TextAreaCon>
          <ButtonCon>
            <LargeButton
              onClick={onClickGoNext}
              disabled={goNextButtonDisabled}
            >
              {t<TranslateKeys>("この内容ですすむ")}
            </LargeButton>
            <LargeButton
              onClick={onClickSkip}
              disabled={skipButtonDisabled}
              color="tertiary"
              variant="blank"
            >
              {t<TranslateKeys>("今回は書かない")}
            </LargeButton>
            <AnnotationContainer>
              <IconTextAnchor
                href="https://yondemy.wraptas.site/worksheet"
                rel="noreferrer noopener"
                target="_blank"
                iconPlace="pre"
                name="file"
                text={t<TranslateKeys>("紙で書くには")}
                color="gray"
                fontSize="SM"
                lineHeight="MD"
              />
              <IconTextButton
                onClick={onClickVoiceModalOpen}
                iconPlace="pre"
                name="microphone"
                text={t<TranslateKeys>("声で入力するには")}
                color="gray"
                fontSize="SM"
                lineHeight="MD"
              />
            </AnnotationContainer>
          </ButtonCon>
        </_BodyWrapper>
      </Wrapper>
      {hintModalOpen && HintModal}
      <TechniqueDescriptionModal
        isOpen={isOpenTechniqueDescriptionModal}
        onClose={() => setIsOpenTechniqueDescriptionModal(false)}
      />
      <VoiceModal isOpen={voiceModalOpen} onClose={onClickVoiceModalClose} />
    </>
  );
};

const ProgressCon = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const ChoicesCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const ButtonCon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const TextAreaCon = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.S};
`;

const AnnotationContainer = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.size.M};
`;

const HintContainer = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  row-gap: 12px;
`;

const _BodyWrapper = styled(PostReadLogBodyWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.size.S};
`;
