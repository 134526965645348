import React from "react";
import styled from "styled-components";
import {
  BaseModal,
  BaseModalFooter,
  BaseModalHeader,
} from "~/components/atoms/wrappers/BaseModal";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";

export type ImagePreviewModalProps = {
  isOpen: boolean;
  file: File | null;
  loading: boolean;
  onSubmit: (event: React.MouseEvent) => void;
  onClose: () => void;
};

export const ImagePreviewModal: React.FC<ImagePreviewModalProps> = ({
  isOpen,
  file,
  loading,
  onSubmit,
  onClose,
}) => {
  return (
    <BaseModal
      open={isOpen}
      onClose={onClose}
      header={
        <BaseModalHeader>
          <Text fontSize="LG" lineHeight="MD" bold>
            このがぞうでいい？
          </Text>
        </BaseModalHeader>
      }
      footer={
        <BaseModalFooter>
          <Stack alignItems="center">
            <LargeButton onClick={onSubmit} disabled={loading}>
              いいよ
            </LargeButton>
            <LargeButton onClick={onClose} color="tertiary" variant="blank">
              やめる
            </LargeButton>
          </Stack>
        </BaseModalFooter>
      }
    >
      <_Wrapper>
        {file && <img src={URL.createObjectURL(file)} alt={file.name} />}
      </_Wrapper>
    </BaseModal>
  );
};

const _Wrapper = styled.div`
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;
