import { MissionOrder } from "~/generated/graphql";

type MissionStoryInfo = {
  storyNumber: number;
  storyTitle: string;
  storyImgSrc: string;
};

export const missionStoryList: Record<MissionOrder, MissionStoryInfo> = {
  MISSION_1: {
    storyNumber: 1,
    storyTitle: "冒険のはじまり",
    storyImgSrc: "/img/mission/start_story/story_1/scene_1.jpg",
  },
  MISSION_2: {
    storyNumber: 2,
    storyTitle: "おもいえがくのワザ",
    storyImgSrc: "/img/mission/start_story/story_2/scene_5.jpg",
  },
  MISSION_3: {
    storyNumber: 3,
    storyTitle: "新たな仲間たち",
    storyImgSrc: "/img/mission/start_story/story_3/scene_1.jpeg",
  },
  MISSION_4: {
    storyNumber: 4,
    storyTitle: "つなげるのワザ",
    storyImgSrc: "/img/mission/start_story/story_4/scene_1.jpeg",
  },
  MISSION_5: {
    storyNumber: 5,
    storyTitle: "ピンクのキリン",
    storyImgSrc: "/img/mission/complete_story/story_5/scene_7.jpeg",
  },
  MISSION_6: {
    storyNumber: 6,
    storyTitle: "しつもんするのワザ",
    storyImgSrc: "/img/mission/start_story/story_6/scene_5.jpeg",
  },
};
