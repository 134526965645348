import React from "react";
import styled from "styled-components";
import { MissionOrder } from "~/generated/graphql";

const _BaseDir = "/img/mission/next_mission_trailer/";

const nextMissionTrailerImgList: Record<MissionOrder, string> = {
  MISSION_1: _BaseDir + "mission_1.jpeg",
  MISSION_2: _BaseDir + "mission_2.jpeg",
  MISSION_3: _BaseDir + "mission_3.jpeg",
  MISSION_4: _BaseDir + "mission_4.jpeg",
  MISSION_5: _BaseDir + "mission_5.jpeg",
  MISSION_6: "",
};

export type NextMissionTrailerProps = {
  onNext: () => void;
  missionOrder: MissionOrder;
};

export const NextMissionTrailer: React.FC<NextMissionTrailerProps> = ({
  onNext,
  missionOrder,
}) => {
  React.useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      onNext();
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [onNext]);
  return (
    <_BG onClick={onNext}>
      <_Img src={nextMissionTrailerImgList[missionOrder]} />
    </_BG>
  );
};

const _BG = styled.div`
  width: 100vw;
  background-color: white;
  height: 100vh;
  max-height: 100dvh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

const _Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  @media (orientation: portrait) {
    width: 100vh;
    height: 100vw;
    max-width: 100svh;
    max-height: 100svw;
    transform: rotate(90deg);
  }
`;
