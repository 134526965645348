import React from "react";
import styled from "styled-components";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text as BaseText } from "~/components/atoms/texts/Text";
import { Loading } from "~/components/atoms/Loading";
import { useTranslation } from "~/i18n";

const origami = ["penguin", "cat", "giraffe", "fox", "bird_1", "bird_2"];

export const KansouSupportChatPresentLoading: React.FC = () => {
  const { t } = useTranslation();

  const origamiIndex = Math.floor(Math.random() * origami.length);
  const origamiValue = origami[origamiIndex];

  return (
    <Content rowGap="16px">
      <Text
        fontSize="LG"
        lineHeight="EQ"
        bold
        fontColor="primitive.monotone.m900"
      >
        {t<TranslateKeys>(
          "ペンギンせんぱいが、プレゼントのおりがみを折っています…"
        )}
      </Text>
      <Text fontSize="SM" lineHeight="EQ">
        {t<TranslateKeys>("※おりがみを折るのに、じかんがかかることがあります")}
      </Text>
      <Stack alignItems="center" rowGap="16px">
        <img
          src={`/img/origami/${origamiValue}.svg`}
          alt={origamiValue}
          width={160}
          height={160}
        />
        <Loading />
      </Stack>
    </Content>
  );
};

const Content = styled(Stack)`
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.size.L} 0;
  background: ${({ theme }) => theme.colors.base.background};
`;

const Text = styled(BaseText)`
  white-space: break-spaces;
`;
