import React from "react";
import styled from "styled-components";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Stack } from "~/components/atoms/layout/Stack";

export type KansouSupportChatFooterProps = {
  onClicks: ((event: React.MouseEvent) => void)[];
};

export const KansouSupportChatFooter: React.FC<
  KansouSupportChatFooterProps
> = ({ onClicks }) => {
  return (
    <_Stack alignItems="center">
      <LargeButton onClick={onClicks[0]}>いいよ</LargeButton>
    </_Stack>
  );
};

const _Stack = styled(Stack)`
  width: 100%;
  padding: 12px 2.5%;
  background-color: ${(props) =>
    props.theme.colors.semantic.layout.backgroundBox};
  border-top: 0.5px solid
    ${(props) => props.theme.colors.semantic.layout.borderPlus1};
`;
