import {
  PostReadLogResult,
  ReadStatusForPostReadLog,
} from "~/generated/graphql";

export const initialState: PostReadLogState = {
  flow: null as unknown as PostReadLogFlows,
  recommendID: null as unknown as number,
  bookISBN: "",
  title: "",
  like: 0,
  difficulty: 0,
  length: 0,
  readStatus: null as unknown as ReadStatusForPostReadLog,
  yomikikase: false,
  review: "",
  wakuwaku: false,
  sikusiku: false,
  dokidoki: false,
  nikoniko: false,
  nattoku: false,
  waraeru: false,
  bikkuri: false,
  punpun: false,
  kowai: false,
  dousite: false,
  questionNumber: null,
  lastProgressNumber: 7,
  bookImgItemtype: null,
  postReadLogResult: null,
  kansouSupportChatId: null as unknown as string,
  kansouPresent: "",
  imageUrl: "",
};

type PostReadLogFlows =
  | "selectBook"
  | "confirmReadStatus"
  | "confirmLike"
  | "confirmDifficulty"
  | "confirmLength"
  | "confirmEmotions"
  | "enterReview"
  //| "enterFavoriteScene"
  | "submitReadLog"
  | "postReadLogAnimation"
  | "enterUnregisteredBookIsbn"
  | "indicateExperiencePoint"
  | "indicateUnlock"
  | "indicatePraiseReadingStreak"
  | "indicateBadge"
  | "indicateFeedback"
  | "confirmDeleteReason"
  | "lockedReview"
  | "resetState"
  | "kansouSelectWriting"
  | "kansouSupportChat"
  | "kansouPresent"
  | "kansouPreview";

export type AllEmotionStates = {
  wakuwaku: boolean;
  sikusiku: boolean;
  dokidoki: boolean;
  nikoniko: boolean;
  nattoku: boolean;
  waraeru: boolean;
  bikkuri: boolean;
  punpun: boolean;
  kowai: boolean;
  dousite: boolean;
};

export type BookImgItemType = "OTHER" | "RECOMMEND" | "WANT_READ_BOOK";

export type PostReadLogState = {
  flow: PostReadLogFlows;
  recommendID: number;
  bookISBN: string;
  title: string;
  like: number;
  difficulty: number;
  length: number;
  readStatus: ReadStatusForPostReadLog;
  yomikikase: boolean;
  review: string;
  questionNumber: number | null;
  lastProgressNumber: number;
  bookImgItemtype: BookImgItemType | null;
  postReadLogResult: PostReadLogResult | null;
  kansouSupportChatId: string;
  kansouPresent: string;
  imageUrl: string;
} & AllEmotionStates;

type DispatchType =
  | "setFlow"
  | "setRecommendID"
  | "setBookISBN"
  | "setTitle"
  | "setLike"
  | "setDifficulty"
  | "setLength"
  | "setReadStatus"
  | "setYomikikase"
  | "setReview"
  | "setWakuwaku"
  | "setDokidoki"
  | "setHokkori"
  | "setNattoku"
  | "setSikusiku"
  | "setNikoniko"
  | "setBikkuri"
  | "setWaraeru"
  | "setPunpun"
  | "setKowai"
  | "setDousite"
  | "setFavoriteScene"
  | "setFavoritePage"
  | "setQuestionNumber"
  | "setPostReadLogResult"
  | "resetState"
  | "setLastProgressNumber"
  | "setBookImgItemType"
  | "setKansouSupportChatId"
  | "setKansouPresent"
  | "setImageUrl";

export type ActionType = {
  type: DispatchType;
  payload?: any; //eslint-disable-line
};

export const reducer = (
  state: PostReadLogState,
  action: ActionType
): PostReadLogState => {
  switch (action.type) {
    case "setFlow":
      return { ...state, flow: action.payload as PostReadLogFlows };
    case "setRecommendID":
      return { ...state, recommendID: action.payload };
    case "setBookISBN":
      return { ...state, bookISBN: action.payload };
    case "setTitle":
      return { ...state, title: action.payload };
    case "setLike":
      return { ...state, like: action.payload };
    case "setDifficulty":
      return { ...state, difficulty: action.payload };
    case "setLength":
      return { ...state, length: action.payload };
    case "setReadStatus":
      return { ...state, readStatus: action.payload };
    case "setYomikikase":
      state.yomikikase = !state.yomikikase;
      return { ...state };
    case "setReview":
      return { ...state, review: action.payload };
    case "setWakuwaku":
      state.wakuwaku = action.payload;
      return { ...state };
    case "setDokidoki":
      state.dokidoki = action.payload;
      return { ...state };
    case "setNattoku":
      state.nattoku = action.payload;
      return { ...state };
    case "setSikusiku":
      state.sikusiku = action.payload;
      return { ...state };
    case "setNikoniko":
      state.nikoniko = action.payload;
      return { ...state };
    case "setBikkuri":
      state.bikkuri = action.payload;
      return { ...state };
    case "setWaraeru":
      state.waraeru = action.payload;
      return { ...state };
    case "setPunpun":
      state.punpun = action.payload;
      return { ...state };
    case "setKowai":
      state.kowai = action.payload;
      return { ...state };
    case "setDousite":
      state.dousite = action.payload;
      return { ...state };
    case "setQuestionNumber":
      return { ...state, questionNumber: action.payload };
    case "setLastProgressNumber":
      return { ...state, lastProgressNumber: action.payload };
    case "setBookImgItemType":
      return { ...state, bookImgItemtype: action.payload };
    case "setPostReadLogResult":
      return {
        ...state,
        postReadLogResult: (action.payload as PostReadLogResult) || null,
      };
    case "resetState":
      return { ...initialState };
    case "setKansouSupportChatId":
      return { ...state, kansouSupportChatId: action.payload };
    case "setKansouPresent":
      return { ...state, kansouPresent: action.payload };
    case "setImageUrl":
      return { ...state, imageUrl: action.payload };
    default:
      return state;
  }
};
