import React from "react";
import styled from "styled-components";
import { KansouSupportChatTemplateProps } from "./type";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { NavContainer } from "../../Common";
import { MessageFooterForm } from "~/components/organisms/forms/MessageFooterForm";
import { KansouSupportChatList } from "~/components/organisms/PostReadLog/KansouSupportChatList";
import { KansouSupportChatFooter } from "~/components/organisms/PostReadLog/KansouSupportChatFooter";

export const KansouSupportChatTemplate: React.FC<
  KansouSupportChatTemplateProps
> = ({
  handleBack,
  onClicks,
  isKansou,
  footerMessageProps,
  kansouSupportChatListProps,
}) => {
  return (
    <>
      <NavContainer>
        <BackNavbar
          titleName="ハナシテミーでかく"
          onClick={handleBack}
          disabled={true}
        />
      </NavContainer>
      <KansouSupportChatContainer>
        <KansouSupportChatListContainer>
          <KansouSupportChatList {...kansouSupportChatListProps} />
        </KansouSupportChatListContainer>
        {isKansou ? (
          <KansouSupportChatFooter onClicks={onClicks} />
        ) : (
          <MessageFooterForm {...footerMessageProps} />
        )}
      </KansouSupportChatContainer>
    </>
  );
};

const KansouSupportChatContainer = styled.div`
  height: 100vh;
  max-height: 100svh;
  display: flex;
  flex-direction: column;
`;

const KansouSupportChatListContainer = styled.div`
  margin-top: 88px;
  height: calc(100% - 88px);
  position: relative;
`;
