import React from "react";
import styled from "styled-components";
import { Interpolation } from "~/styles/interpolation";
import { Stack } from "~/components/atoms/layout/Stack";
import { Row } from "~/components/atoms/layout/Row";
import { LargeButton, SmallButton } from "~/components/atoms/buttons/Button";
import { Loading } from "~/components/atoms/Loading";
import { BookshelfImage } from "~/components/atoms/images/BookshelfImage";
import { KansouPreviewTemplateProps } from "./type";
import { NavContainer, Wrapper } from "../../Common";
import { PostReadLogBodyWrapper } from "../Common/style";
import { ProgressBar } from "~/components/atoms/ProgressBar";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { Text } from "~/components/atoms/texts/Text";
import { SmallTag } from "~/components/atoms/indicators/SmallTag";
import { difficultyChoices } from "../ConfirmDifficulty";
import { useLengthChoices } from "../ConfirmLength";
import { useLikeChoices } from "../ConfirmLike";
import { emotions } from "../ConfirmEmotions/type";
import { TranslateKeys, useTranslation } from "~/i18n";
import { ReadStatusForPostReadLog } from "~/generated/graphql";

export const KansouPreviewTemplate: React.FC<KansouPreviewTemplateProps> = ({
  handleBack,
  onClickGoNext,
  loading,
  title,
  readStatus,
  like,
  difficulty,
  length,
  emotionsObj,
  review,
  imageUrl,
  progress,
  onClickReviewRewrite,
}) => {
  const { t } = useTranslation();
  const lengthChoices = useLengthChoices(t);
  const likeChoices = useLikeChoices(t);
  const readStatusDictionary: Record<
    ReadStatusForPostReadLog,
    React.ReactNode
  > = React.useMemo(() => {
    return {
      [ReadStatusForPostReadLog.End]: (
        <SmallTag ja={t<TranslateKeys>("最後まで")} emoji="📘" color="pri" />
      ),
      [ReadStatusForPostReadLog.Reading]: (
        <SmallTag
          ja={t<TranslateKeys>("とちゅうまで")}
          emoji="🔖"
          color="pri"
        />
      ),
    };
  }, [t]);

  return (
    <>
      <NavContainer>
        <BackNavbar
          titleName="かんそうをかくにん"
          onClick={handleBack}
          disabled={true}
        />
      </NavContainer>
      <Wrapper>
        <PostReadLogBodyWrapper>
          <ProgressCon>
            <ProgressBar progress={progress} backgroundColor="base.gray" />
          </ProgressCon>
          <_Stack rowGap="16px" alignItems="center">
            <_Container rowGap="24px">
              <Text fontSize="LG" lineHeight="EQ" bold>
                {title}
              </Text>
              {imageUrl && (
                <Stack alignItems="center">
                  <BookshelfImage url={imageUrl} alt={title} />
                </Stack>
              )}
            </_Container>
            <_Container rowGap="12px">
              <Text fontSize="LG" lineHeight="EQ" bold>
                {"ひとこと感想"}
              </Text>
              <Text fontSize={"MD"} lineHeight={"MD"}>
                {review}
              </Text>
              <Stack alignItems="center">
                <SmallButton onClick={onClickReviewRewrite} variant="blank">
                  かきなおす
                </SmallButton>
              </Stack>
            </_Container>
            <_Container rowGap="12px">
              <Text fontSize="LG" lineHeight="EQ" bold>
                {"感想"}
              </Text>
              <OneRow question={t<TranslateKeys>("どこまで読んだ？")}>
                {readStatusDictionary[readStatus]}
              </OneRow>
              <OneRow question={t<TranslateKeys>("難しかった？")}>
                <SmallTag
                  {...difficultyChoices[difficulty - 1]}
                  color={"pri"}
                />
              </OneRow>
              <OneRow question={t<TranslateKeys>("長さは？")}>
                <SmallTag {...lengthChoices[length - 1]} color={"pri"} />
              </OneRow>
              <OneRow question={t<TranslateKeys>("好きだった？")}>
                <SmallTag {...likeChoices[like - 1]} color={"pri"} />
              </OneRow>
              <OneRow question={t<TranslateKeys>("気持ち")}>
                {Object.keys(emotionsObj)
                  .filter(
                    (key) => emotionsObj[key] === true && key !== "yomikikase"
                  )
                  .map((key, index) => (
                    <SmallTag key={index} {...emotions[key]} color={"pri"} />
                  ))}
              </OneRow>
            </_Container>
            {loading ? (
              <Loading />
            ) : (
              <LargeButton onClick={onClickGoNext} disabled={loading}>
                とどける
              </LargeButton>
            )}
          </_Stack>
        </PostReadLogBodyWrapper>
      </Wrapper>
    </>
  );
};

const OneRow: React.FC<{ question: string; children: React.ReactNode }> = ({
  question,
  children,
}) => (
  <Row alignItems="center">
    <Col1>
      <Question fontSize={"SM"} lineHeight={"EQ"}>
        {question}
      </Question>
    </Col1>
    <Col2 alignItems="center">{children}</Col2>
  </Row>
);

const _Stack = styled(Stack)`
  max-width: 520px;
  margin: 0 auto;
  padding-bottom: 20px;
`;

const _Container = styled(Stack)`
  ${Interpolation.Border}
  width: 100%;
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: ${({ theme }) => theme.size.M};
`;

const ProgressCon = styled.div`
  margin-bottom: 20px;
`;

const Question = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t400};
`;

const Col1 = styled.div`
  width: 40%;
  margin-right: 2%;
`;

const Col2 = styled(Row)`
  width: 58%;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.size.XS};
`;
