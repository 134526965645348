import React, { useCallback, useState } from "react";
import { KansouSelectWritingTemplate } from "~/components/templates/PostReadLog/KansouSelectWriting";
import { usePostReadLogState } from "../usePostReadLogState";
import {
  useStartKansouSupportChatMutation,
  useCountKansouSupportChatQuery,
  TutorialFlagKey,
} from "~/generated/graphql";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { TutorialHasUsedKansouSupportChatModal } from "~/components/organisms/modals/TutorialHasUsedKansouSupportChatModal";
import { unlockLevel } from "~/store/unlock/useUnlock";

export const KansouSelectWriting: React.FC = () => {
  const {
    state: { questionNumber, lastProgressNumber, bookISBN, title },
    dispatch,
  } = usePostReadLogState();
  const { userExperience, tutorialFlags, getTutorialFlag, saveTutorialFlags } =
    React.useContext(CurrentUserContext);
  const [startKansouSupportChat] = useStartKansouSupportChatMutation();
  const [isOpenKansouSupportChatModal, setIsOpenKansouSupportChatModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const userLevel = userExperience?.userLevel || 0;

  const { data } = useCountKansouSupportChatQuery({
    fetchPolicy: "no-cache",
  });

  const handleNext = (): void => {
    dispatch({
      type: "setQuestionNumber",
      payload: currectQuestionNumber + 1,
    });
  };

  const handleBack = (): void => {
    dispatch({
      type: "setQuestionNumber",
      payload: currectQuestionNumber - 1,
    });
    dispatch({
      type: "setFlow",
      payload: "confirmEmotions",
    });
  };

  const handleStartKansouSupportChat = (): void => {
    setLoading(true);
    startKansouSupportChat({
      variables: {
        bookISBN: bookISBN,
        bookTitle: title,
      },
      fetchPolicy: "no-cache",
    }).then((res) => {
      setLoading(false);
      dispatch({
        type: "setFlow",
        payload: "kansouSupportChat",
      });
      dispatch({
        type: "setKansouSupportChatId",
        payload: res.data?.startKansouSupportChat.id,
      });
    });
  };

  const onClicks = [
    //じぶんでかく
    (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setFlow",
        payload: "enterReview",
      });
      handleNext();
    },
    //ハナシテミーでかく
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (
        tutorialFlags &&
        !getTutorialFlag(TutorialFlagKey.HasUsedKansouSupportChat)
      ) {
        onOpenKansouSupportChatModal();
      } else {
        handleStartKansouSupportChat();
        handleNext();
      }
    },
  ];

  const onOpenKansouSupportChatModal = useCallback(() => {
    setIsOpenKansouSupportChatModal(true);
  }, []);
  const onCloseKansouSupportChatModal = useCallback(() => {
    setIsOpenKansouSupportChatModal(false);
  }, []);
  const onNextKansouSupportChatModal = useCallback(() => {
    if (
      tutorialFlags &&
      !getTutorialFlag(TutorialFlagKey.HasUsedKansouSupportChat)
    ) {
      saveTutorialFlags(TutorialFlagKey.HasUsedKansouSupportChat);
    }
    handleStartKansouSupportChat();
    handleNext();
    setIsOpenKansouSupportChatModal(false);
  }, [saveTutorialFlags]);

  const currectQuestionNumber = questionNumber ? questionNumber : 1;
  const progress = Math.floor(
    (currectQuestionNumber / lastProgressNumber) * 100
  );

  const isLimited =
    data &&
    data?.countKansouSupportChat.dailyUsedCount >=
      data?.countKansouSupportChat.dailyUsageLimit;
  const isUnlock = userLevel < unlockLevel.kansouSupportChat;

  return (
    <>
      <TutorialHasUsedKansouSupportChatModal
        isOpen={isOpenKansouSupportChatModal}
        loading={loading}
        onClose={onCloseKansouSupportChatModal}
        onNext={onNextKansouSupportChatModal}
      />
      <KansouSelectWritingTemplate
        progress={progress}
        handleBack={handleBack}
        onClicks={onClicks}
        isLimited={isLimited}
        isUnlock={isUnlock}
        loading={loading}
      />
    </>
  );
};
