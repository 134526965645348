import { MissionOrder } from "~/generated/graphql";
import { SoundKey } from "../useAudio";
import { CompleteStory1 } from "./CompleteStory/CompleteStory1";
import { StartStory1 } from "./StartStory/StartStory1";
import { StartStory2 } from "./StartStory/StartStory2";
import { CompleteStory2 } from "./CompleteStory/CompleteStory2";
import { StartStory3 } from "./StartStory/StartStory3";
import { CompleteStory3 } from "./CompleteStory/CompleteStory3";
import { StartStory4 } from "./StartStory/StartStory4";
import { CompleteStory4 } from "./CompleteStory/CompleteStory4";
import { StartStory5 } from "./StartStory/StartStory5";
import { CompleteStory5 } from "./CompleteStory/CompleteStory5";
import { StartStory6 } from "./StartStory/StartStory6";
import { CompleteStory6 } from "./CompleteStory/CompleteStory6";

export type MissionStorySetting = {
  image: string;
  sound: SoundKey;
  endDelay?: number;
  startDelay?: number;
  pageTurn?: boolean;
  text?: string;
  ruby?: string[];
};

export const startStoryByMissionOrder: Record<
  MissionOrder,
  MissionStorySetting[]
> = {
  [MissionOrder.Mission_1]: StartStory1,
  [MissionOrder.Mission_2]: StartStory2,
  [MissionOrder.Mission_3]: StartStory3,
  [MissionOrder.Mission_4]: StartStory4,
  [MissionOrder.Mission_5]: StartStory5,
  [MissionOrder.Mission_6]: StartStory6,
};

export const completeStoryByMissionOrder: Record<
  MissionOrder,
  MissionStorySetting[]
> = {
  [MissionOrder.Mission_1]: CompleteStory1,
  [MissionOrder.Mission_2]: CompleteStory2,
  [MissionOrder.Mission_3]: CompleteStory3,
  [MissionOrder.Mission_4]: CompleteStory4,
  [MissionOrder.Mission_5]: CompleteStory5,
  [MissionOrder.Mission_6]: CompleteStory6,
};
