import { useTranslation } from "react-i18next";
import { LockedFeatures } from "~/store/unlock/useUnlock";

type modalContent = {
  title: string;
  image: string;
  advice?: string;
};

export const useCongratulateUnLockModalContents = (
  lockedFeature: LockedFeatures
): modalContent => {
  const { t } = useTranslation();

  const allContents: Record<LockedFeatures, modalContent> = {
    enterFreeReview: {
      title: t<TranslateKeys>("一言感想が書けるようになったよ🎉"),
      image: "/img/unlock/free_review.png",
      advice:
        t<TranslateKeys>("次は") +
        "、レベル11を" +
        t<TranslateKeys>("目指そう！") +
        t<TranslateKeys>("何が見られるようになるかな......？"),
    },
    bookFriend: {
      title: t<TranslateKeys>("みんなの感想が見られるようになったよ🎉"),
      image: "/img/unlock/book_friend.png",
      advice:
        t<TranslateKeys>("次は") +
        "、レベル12を" +
        t<TranslateKeys>("目指そう！") +
        t<TranslateKeys>("何が見られるようになるかな......？"),
    },
    kansouSupportChat: {
      title: "ハナシテミーがつかえるよ🎉",
      image: "/img/unlock/kansou_support_chat.png",
      advice:
        t<TranslateKeys>("次は") +
        "、レベル21を" +
        t<TranslateKeys>("目指そう！") +
        t<TranslateKeys>("何が見られるようになるかな......？"),
    },
    bfStar: {
      title: t<TranslateKeys>("スターを送れるよ🎉"),
      image: "/img/unlock/star.png",
      advice:
        t<TranslateKeys>("次は") +
        "、レベル25を" +
        t<TranslateKeys>("目指そう！") +
        t<TranslateKeys>("何が見られるようになるかな......？"),
    },
    wantReadBook: {
      title: "よみま書架（しょか）がつかえるよ🎉",
      image: "/img/unlock/want_read_book.png",
      advice: t<TranslateKeys>("君の 読みたい本 専用の 本棚だよ"),
    },
  };
  return allContents[lockedFeature];
};
