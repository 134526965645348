const baseDir = "/audio/mission_story";

const storyLevel = 0.8;

export const soundMap = {
  pageTurn: { src: `${baseDir}/pageTurn.mp3`, level: 0.3 },
  start1_scene1: {
    src: `${baseDir}/start_story/story_1/scene_1.mp3`,
    level: storyLevel,
  },
  start1_scene2: {
    src: `${baseDir}/start_story/story_1/scene_2.mp3`,
    level: storyLevel,
  },
  start1_scene3: {
    src: `${baseDir}/start_story/story_1/scene_3.mp3`,
    level: storyLevel,
  },
  start1_scene4: {
    src: `${baseDir}/start_story/story_1/scene_4.mp3`,
    level: storyLevel,
  },
  start1_scene5_1: {
    src: `${baseDir}/start_story/story_1/scene_5_1.mp3`,
    level: storyLevel,
  },
  start1_scene5_2: {
    src: `${baseDir}/start_story/story_1/scene_5_2.mp3`,
    level: storyLevel,
  },
  start1_scene6: {
    src: `${baseDir}/start_story/story_1/scene_6.mp3`,
    level: storyLevel,
  },
  start1_scene7: {
    src: `${baseDir}/start_story/story_1/scene_7.mp3`,
    level: storyLevel,
  },
  start1_scene8_1: {
    src: `${baseDir}/start_story/story_1/scene_8_1.mp3`,
    level: storyLevel,
  },
  start1_scene8_2: {
    src: `${baseDir}/start_story/story_1/scene_8_2.mp3`,
    level: storyLevel,
  },
  start1_scene9: {
    src: `${baseDir}/start_story/story_1/scene_9.mp3`,
    level: storyLevel,
  },
  start1_scene10: {
    src: `${baseDir}/start_story/story_1/scene_10.mp3`,
    level: storyLevel,
  },
  start1_scene11: {
    src: `${baseDir}/start_story/story_1/scene_11.mp3`,
    level: storyLevel,
  },
  complete1_scene1: {
    src: `${baseDir}/complete_story/story_1/scene_1.mp3`,
    level: storyLevel,
  },
  complete1_scene2: {
    src: `${baseDir}/complete_story/story_1/scene_2.mp3`,
    level: storyLevel,
  },
  complete1_scene3: {
    src: `${baseDir}/complete_story/story_1/scene_3.mp3`,
    level: storyLevel,
  },
  complete1_scene4_1: {
    src: `${baseDir}/complete_story/story_1/scene_4_1.mp3`,
    level: storyLevel,
  },
  complete1_scene4_2: {
    src: `${baseDir}/complete_story/story_1/scene_4_2.mp3`,
    level: storyLevel,
  },
  complete1_scene5: {
    src: `${baseDir}/complete_story/story_1/scene_5.mp3`,
    level: storyLevel,
  },
  complete1_scene6: {
    src: `${baseDir}/complete_story/story_1/scene_6.mp3`,
    level: storyLevel,
  },
  complete1_scene7: {
    src: `${baseDir}/complete_story/story_1/scene_7.mp3`,
    level: storyLevel,
  },
  complete1_scene8: {
    src: `${baseDir}/complete_story/story_1/scene_8.mp3`,
    level: storyLevel,
  },
  complete1_scene9: {
    src: `${baseDir}/complete_story/story_1/scene_9.mp3`,
    level: storyLevel,
  },
  complete1_scene10: {
    src: `${baseDir}/complete_story/story_1/scene_10.mp3`,
    level: storyLevel,
  },
  complete1_scene11: {
    src: `${baseDir}/complete_story/story_1/scene_11.mp3`,
    level: storyLevel,
  },
  complete1_scene12: {
    src: `${baseDir}/complete_story/story_1/scene_12.mp3`,
    level: storyLevel,
  },
  complete1_scene13: {
    src: `${baseDir}/complete_story/story_1/scene_13.mp3`,
    level: storyLevel,
  },
  complete1_scene14: {
    src: `${baseDir}/complete_story/story_1/scene_14.mp3`,
    level: storyLevel,
  },
  complete1_scene15: {
    src: `${baseDir}/complete_story/story_1/scene_15.mp3`,
    level: storyLevel,
  },
  start2_scene1: {
    src: `${baseDir}/start_story/story_2/scene_1.mp3`,
    level: storyLevel,
  },
  start2_scene2: {
    src: `${baseDir}/start_story/story_2/scene_2.mp3`,
    level: storyLevel,
  },
  start2_scene3: {
    src: `${baseDir}/start_story/story_2/scene_3.mp3`,
    level: storyLevel,
  },
  start2_scene4: {
    src: `${baseDir}/start_story/story_2/scene_4.mp3`,
    level: storyLevel,
  },
  start2_scene5: {
    src: `${baseDir}/start_story/story_2/scene_5.mp3`,
    level: storyLevel,
  },
  start2_scene6_1: {
    src: `${baseDir}/start_story/story_2/scene_6_1.mp3`,
    level: storyLevel,
  },
  start2_scene6_2: {
    src: `${baseDir}/start_story/story_2/scene_6_2.mp3`,
    level: storyLevel,
  },
  start2_scene7: {
    src: `${baseDir}/start_story/story_2/scene_7.mp3`,
    level: storyLevel,
  },
  start2_scene8: {
    src: `${baseDir}/start_story/story_2/scene_8.mp3`,
    level: storyLevel,
  },
  start2_scene9_1: {
    src: `${baseDir}/start_story/story_2/scene_9_1.mp3`,
    level: storyLevel,
  },
  start2_scene9_2: {
    src: `${baseDir}/start_story/story_2/scene_9_2.mp3`,
    level: storyLevel,
  },
  complete2_scene1_1: {
    src: `${baseDir}/complete_story/story_2/scene_1_1.mp3`,
    level: storyLevel,
  },
  complete2_scene1_2: {
    src: `${baseDir}/complete_story/story_2/scene_1_2.mp3`,
    level: storyLevel,
  },
  complete2_scene2: {
    src: `${baseDir}/complete_story/story_2/scene_2.mp3`,
    level: storyLevel,
  },
  complete2_scene3_1: {
    src: `${baseDir}/complete_story/story_2/scene_3_1.mp3`,
    level: storyLevel,
  },
  complete2_scene3_2: {
    src: `${baseDir}/complete_story/story_2/scene_3_2.mp3`,
    level: storyLevel,
  },
  complete2_scene4: {
    src: `${baseDir}/complete_story/story_2/scene_4.mp3`,
    level: storyLevel,
  },
  complete2_scene5_1: {
    src: `${baseDir}/complete_story/story_2/scene_5_1.mp3`,
    level: storyLevel,
  },
  complete2_scene5_2: {
    src: `${baseDir}/complete_story/story_2/scene_5_2.mp3`,
    level: storyLevel,
  },
  complete2_scene6_1: {
    src: `${baseDir}/complete_story/story_2/scene_6_1.mp3`,
    level: storyLevel,
  },
  complete2_scene6_2: {
    src: `${baseDir}/complete_story/story_2/scene_6_2.mp3`,
    level: storyLevel,
  },
  complete2_scene7_1: {
    src: `${baseDir}/complete_story/story_2/scene_7_1.mp3`,
    level: storyLevel,
  },
  complete2_scene7_2: {
    src: `${baseDir}/complete_story/story_2/scene_7_2.mp3`,
    level: storyLevel,
  },
  complete2_scene8: {
    src: `${baseDir}/complete_story/story_2/scene_8.mp3`,
    level: storyLevel,
  },
  complete2_scene9: {
    src: `${baseDir}/complete_story/story_2/scene_9.mp3`,
    level: storyLevel,
  },
  complete2_scene10: {
    src: `${baseDir}/complete_story/story_2/scene_10.mp3`,
    level: storyLevel,
  },
  complete2_scene11_1: {
    src: `${baseDir}/complete_story/story_2/scene_11_1.mp3`,
    level: storyLevel,
  },
  complete2_scene11_2: {
    src: `${baseDir}/complete_story/story_2/scene_11_2.mp3`,
    level: storyLevel,
  },
  complete2_scene12: {
    src: `${baseDir}/complete_story/story_2/scene_12.mp3`,
    level: storyLevel,
  },
  start3_scene1: {
    src: `${baseDir}/start_story/story_3/scene_1.mp3`,
    level: storyLevel,
  },
  start3_scene2: {
    src: `${baseDir}/start_story/story_3/scene_2.mp3`,
    level: storyLevel,
  },
  start3_scene3: {
    src: `${baseDir}/start_story/story_3/scene_3.mp3`,
    level: storyLevel,
  },
  start3_scene4: {
    src: `${baseDir}/start_story/story_3/scene_4.mp3`,
    level: storyLevel,
  },
  start3_scene5_1: {
    src: `${baseDir}/start_story/story_3/scene_5_1.mp3`,
    level: storyLevel,
  },
  start3_scene5_2: {
    src: `${baseDir}/start_story/story_3/scene_5_2.mp3`,
    level: storyLevel,
  },
  start3_scene6: {
    src: `${baseDir}/start_story/story_3/scene_6.mp3`,
    level: storyLevel,
  },
  start3_scene7_1: {
    src: `${baseDir}/start_story/story_3/scene_7_1.mp3`,
    level: storyLevel,
  },
  start3_scene7_2: {
    src: `${baseDir}/start_story/story_3/scene_7_2.mp3`,
    level: storyLevel,
  },
  start3_scene8: {
    src: `${baseDir}/start_story/story_3/scene_8.mp3`,
    level: storyLevel,
  },
  start3_scene9_1: {
    src: `${baseDir}/start_story/story_3/scene_9_1.mp3`,
    level: storyLevel,
  },
  start3_scene9_2: {
    src: `${baseDir}/start_story/story_3/scene_9_2.mp3`,
    level: storyLevel,
  },
  complete3_scene1: {
    src: `${baseDir}/complete_story/story_3/scene_1.mp3`,
    level: storyLevel,
  },
  complete3_scene2: {
    src: `${baseDir}/complete_story/story_3/scene_2.mp3`,
    level: storyLevel,
  },
  complete3_scene3_1: {
    src: `${baseDir}/complete_story/story_3/scene_3_1.mp3`,
    level: storyLevel,
  },
  complete3_scene3_2: {
    src: `${baseDir}/complete_story/story_3/scene_3_2.mp3`,
    level: storyLevel,
  },
  complete3_scene4_1: {
    src: `${baseDir}/complete_story/story_3/scene_4_1.mp3`,
    level: storyLevel,
  },
  complete3_scene4_2: {
    src: `${baseDir}/complete_story/story_3/scene_4_2.mp3`,
    level: storyLevel,
  },
  complete3_scene5_1: {
    src: `${baseDir}/complete_story/story_3/scene_5_1.mp3`,
    level: storyLevel,
  },
  complete3_scene5_2: {
    src: `${baseDir}/complete_story/story_3/scene_5_2.mp3`,
    level: storyLevel,
  },
  complete3_scene6_1: {
    src: `${baseDir}/complete_story/story_3/scene_6_1.mp3`,
    level: storyLevel,
  },
  complete3_scene6_2: {
    src: `${baseDir}/complete_story/story_3/scene_6_2.mp3`,
    level: storyLevel,
  },
  complete3_scene7: {
    src: `${baseDir}/complete_story/story_3/scene_7.mp3`,
    level: storyLevel,
  },
  complete3_scene8: {
    src: `${baseDir}/complete_story/story_3/scene_8.mp3`,
    level: storyLevel,
  },
  complete3_scene9: {
    src: `${baseDir}/complete_story/story_3/scene_9.mp3`,
    level: storyLevel,
  },
  complete3_scene10: {
    src: `${baseDir}/complete_story/story_3/scene_10.mp3`,
    level: storyLevel,
  },
  start4_scene1: {
    src: `${baseDir}/start_story/story_4/scene_1.mp3`,
    level: storyLevel,
  },
  start4_scene2: {
    src: `${baseDir}/start_story/story_4/scene_2.mp3`,
    level: storyLevel,
  },
  start4_scene3: {
    src: `${baseDir}/start_story/story_4/scene_3.mp3`,
    level: storyLevel,
  },
  start4_scene4: {
    src: `${baseDir}/start_story/story_4/scene_4.mp3`,
    level: storyLevel,
  },
  start4_scene5_1: {
    src: `${baseDir}/start_story/story_4/scene_5_1.mp3`,
    level: storyLevel,
  },
  start4_scene5_2: {
    src: `${baseDir}/start_story/story_4/scene_5_2.mp3`,
    level: storyLevel,
  },
  start4_scene5_3: {
    src: `${baseDir}/start_story/story_4/scene_5_3.mp3`,
    level: storyLevel,
  },
  start4_scene6_1: {
    src: `${baseDir}/start_story/story_4/scene_6_1.mp3`,
    level: storyLevel,
  },
  start4_scene6_2: {
    src: `${baseDir}/start_story/story_4/scene_6_2.mp3`,
    level: storyLevel,
  },
  start4_scene7: {
    src: `${baseDir}/start_story/story_4/scene_7.mp3`,
    level: storyLevel,
  },
  start4_scene8_1: {
    src: `${baseDir}/start_story/story_4/scene_8_1.mp3`,
    level: storyLevel,
  },
  start4_scene8_2: {
    src: `${baseDir}/start_story/story_4/scene_8_2.mp3`,
    level: storyLevel,
  },
  start4_scene9: {
    src: `${baseDir}/start_story/story_4/scene_9.mp3`,
    level: storyLevel,
  },
  start4_scene10: {
    src: `${baseDir}/start_story/story_4/scene_10.mp3`,
    level: storyLevel,
  },
  start4_scene11: {
    src: `${baseDir}/start_story/story_4/scene_11.mp3`,
    level: storyLevel,
  },
  start4_scene12: {
    src: `${baseDir}/start_story/story_4/scene_12.mp3`,
    level: storyLevel,
  },
  start4_scene13_1: {
    src: `${baseDir}/start_story/story_4/scene_13_1.mp3`,
    level: storyLevel,
  },
  start4_scene13_2: {
    src: `${baseDir}/start_story/story_4/scene_13_2.mp3`,
    level: storyLevel,
  },
  complete4_scene1_1: {
    src: `${baseDir}/complete_story/story_4/scene_1_1.mp3`,
    level: storyLevel,
  },
  complete4_scene1_2: {
    src: `${baseDir}/complete_story/story_4/scene_1_2.mp3`,
    level: storyLevel,
  },
  complete4_scene1_3: {
    src: `${baseDir}/complete_story/story_4/scene_1_3.mp3`,
    level: storyLevel,
  },
  complete4_scene2_1: {
    src: `${baseDir}/complete_story/story_4/scene_2_1.mp3`,
    level: storyLevel,
  },
  complete4_scene2_2: {
    src: `${baseDir}/complete_story/story_4/scene_2_2.mp3`,
    level: storyLevel,
  },
  complete4_scene3_1: {
    src: `${baseDir}/complete_story/story_4/scene_3_1.mp3`,
    level: storyLevel,
  },
  complete4_scene3_2: {
    src: `${baseDir}/complete_story/story_4/scene_3_2.mp3`,
    level: storyLevel,
  },
  complete4_scene3_3: {
    src: `${baseDir}/complete_story/story_4/scene_3_3.mp3`,
    level: storyLevel,
  },
  complete4_scene4_1: {
    src: `${baseDir}/complete_story/story_4/scene_4_1.mp3`,
    level: storyLevel,
  },
  complete4_scene4_2: {
    src: `${baseDir}/complete_story/story_4/scene_4_2.mp3`,
    level: storyLevel,
  },
  complete4_scene5_1: {
    src: `${baseDir}/complete_story/story_4/scene_5_1.mp3`,
    level: storyLevel,
  },
  complete4_scene5_2: {
    src: `${baseDir}/complete_story/story_4/scene_5_2.mp3`,
    level: storyLevel,
  },
  complete4_scene6: {
    src: `${baseDir}/complete_story/story_4/scene_6.mp3`,
    level: storyLevel,
  },
  complete4_scene7_1: {
    src: `${baseDir}/complete_story/story_4/scene_7_1.mp3`,
    level: storyLevel,
  },
  complete4_scene7_2: {
    src: `${baseDir}/complete_story/story_4/scene_7_2.mp3`,
    level: storyLevel,
  },
  complete4_scene8_1: {
    src: `${baseDir}/complete_story/story_4/scene_8_1.mp3`,
    level: storyLevel,
  },
  complete4_scene8_2: {
    src: `${baseDir}/complete_story/story_4/scene_8_2.mp3`,
    level: storyLevel,
  },
  complete4_scene9: {
    src: `${baseDir}/complete_story/story_4/scene_9.mp3`,
    level: storyLevel,
  },
  complete4_scene10_1: {
    src: `${baseDir}/complete_story/story_4/scene_10_1.mp3`,
    level: storyLevel,
  },
  complete4_scene10_2: {
    src: `${baseDir}/complete_story/story_4/scene_10_2.mp3`,
    level: storyLevel,
  },
  complete4_scene11_1: {
    src: `${baseDir}/complete_story/story_4/scene_11_1.mp3`,
    level: storyLevel,
  },
  complete4_scene11_2: {
    src: `${baseDir}/complete_story/story_4/scene_11_2.mp3`,
    level: storyLevel,
  },
  complete4_scene12: {
    src: `${baseDir}/complete_story/story_4/scene_12.mp3`,
    level: storyLevel,
  },
  complete4_scene13_1: {
    src: `${baseDir}/complete_story/story_4/scene_13_1.mp3`,
    level: storyLevel,
  },
  complete4_scene13_2: {
    src: `${baseDir}/complete_story/story_4/scene_13_2.mp3`,
    level: storyLevel,
  },
  start5_scene1: {
    src: `${baseDir}/start_story/story_5/scene_1.mp3`,
    level: storyLevel,
  },
  start5_scene2: {
    src: `${baseDir}/start_story/story_5/scene_2.mp3`,
    level: storyLevel,
  },
  start5_scene3: {
    src: `${baseDir}/start_story/story_5/scene_3.mp3`,
    level: storyLevel,
  },
  start5_scene4: {
    src: `${baseDir}/start_story/story_5/scene_4.mp3`,
    level: storyLevel,
  },
  start5_scene5: {
    src: `${baseDir}/start_story/story_5/scene_5.mp3`,
    level: storyLevel,
  },
  start5_scene6: {
    src: `${baseDir}/start_story/story_5/scene_6.mp3`,
    level: storyLevel,
  },
  start5_scene7: {
    src: `${baseDir}/start_story/story_5/scene_7.mp3`,
    level: storyLevel,
  },
  start5_scene8: {
    src: `${baseDir}/start_story/story_5/scene_8.mp3`,
    level: storyLevel,
  },
  start5_scene9: {
    src: `${baseDir}/start_story/story_5/scene_9.mp3`,
    level: storyLevel,
  },
  start5_scene10_1: {
    src: `${baseDir}/start_story/story_5/scene_10_1.mp3`,
    level: storyLevel,
  },
  start5_scene10_2: {
    src: `${baseDir}/start_story/story_5/scene_10_2.mp3`,
    level: storyLevel,
  },
  start5_scene11: {
    src: `${baseDir}/start_story/story_5/scene_11.mp3`,
    level: storyLevel,
  },
  complete5_scene1: {
    src: `${baseDir}/complete_story/story_5/scene_1.mp3`,
    level: storyLevel,
  },
  complete5_scene2: {
    src: `${baseDir}/complete_story/story_5/scene_2.mp3`,
    level: storyLevel,
  },
  complete5_scene3: {
    src: `${baseDir}/complete_story/story_5/scene_3.mp3`,
    level: storyLevel,
  },
  complete5_scene4: {
    src: `${baseDir}/complete_story/story_5/scene_4.mp3`,
    level: storyLevel,
  },
  complete5_scene5: {
    src: `${baseDir}/complete_story/story_5/scene_5.mp3`,
    level: storyLevel,
  },
  complete5_scene6: {
    src: `${baseDir}/complete_story/story_5/scene_6.mp3`,
    level: storyLevel,
  },
  complete5_scene7: {
    src: `${baseDir}/complete_story/story_5/scene_7.mp3`,
    level: storyLevel,
  },
  complete5_scene8: {
    src: `${baseDir}/complete_story/story_5/scene_8.mp3`,
    level: storyLevel,
  },
  complete5_scene9: {
    src: `${baseDir}/complete_story/story_5/scene_9.mp3`,
    level: storyLevel,
  },
  complete5_scene10_1: {
    src: `${baseDir}/complete_story/story_5/scene_10_1.mp3`,
    level: storyLevel,
  },
  complete5_scene10_2: {
    src: `${baseDir}/complete_story/story_5/scene_10_2.mp3`,
    level: storyLevel,
  },
  start6_scene1: {
    src: `${baseDir}/start_story/story_6/scene_1.mp3`,
    level: storyLevel,
  },
  start6_scene2_1: {
    src: `${baseDir}/start_story/story_6/scene_2_1.mp3`,
    level: storyLevel,
  },
  start6_scene2_2: {
    src: `${baseDir}/start_story/story_6/scene_2_2.mp3`,
    level: storyLevel,
  },
  start6_scene3: {
    src: `${baseDir}/start_story/story_6/scene_3.mp3`,
    level: storyLevel,
  },
  start6_scene4_1: {
    src: `${baseDir}/start_story/story_6/scene_4_1.mp3`,
    level: storyLevel,
  },
  start6_scene4_2: {
    src: `${baseDir}/start_story/story_6/scene_4_2.mp3`,
    level: storyLevel,
  },
  start6_scene5_1: {
    src: `${baseDir}/start_story/story_6/scene_5_1.mp3`,
    level: storyLevel,
  },
  start6_scene5_2: {
    src: `${baseDir}/start_story/story_6/scene_5_2.mp3`,
    level: storyLevel,
  },
  start6_scene6: {
    src: `${baseDir}/start_story/story_6/scene_6.mp3`,
    level: storyLevel,
  },
  start6_scene7: {
    src: `${baseDir}/start_story/story_6/scene_7.mp3`,
    level: storyLevel,
  },
  start6_scene8: {
    src: `${baseDir}/start_story/story_6/scene_8.mp3`,
    level: storyLevel,
  },
  start6_scene9: {
    src: `${baseDir}/start_story/story_6/scene_9.mp3`,
    level: storyLevel,
  },
  start6_scene10: {
    src: `${baseDir}/start_story/story_6/scene_10.mp3`,
    level: storyLevel,
  },
  start6_scene11_1: {
    src: `${baseDir}/start_story/story_6/scene_11_1.mp3`,
    level: storyLevel,
  },
  start6_scene11_2: {
    src: `${baseDir}/start_story/story_6/scene_11_2.mp3`,
    level: storyLevel,
  },
  complete6_scene1: {
    src: `${baseDir}/complete_story/story_6/scene_1.mp3`,
    level: storyLevel,
  },
  complete6_scene2_1: {
    src: `${baseDir}/complete_story/story_6/scene_2_1.mp3`,
    level: storyLevel,
  },
  complete6_scene2_2: {
    src: `${baseDir}/complete_story/story_6/scene_2_2.mp3`,
    level: storyLevel,
  },
  complete6_scene3: {
    src: `${baseDir}/complete_story/story_6/scene_3.mp3`,
    level: storyLevel,
  },
  complete6_scene4: {
    src: `${baseDir}/complete_story/story_6/scene_4.mp3`,
    level: storyLevel,
  },
  complete6_scene5: {
    src: `${baseDir}/complete_story/story_6/scene_5.mp3`,
    level: storyLevel,
  },
  complete6_scene6_1: {
    src: `${baseDir}/complete_story/story_6/scene_6_1.mp3`,
    level: storyLevel,
  },
  complete6_scene6_2: {
    src: `${baseDir}/complete_story/story_6/scene_6_2.mp3`,
    level: storyLevel,
  },
  complete6_scene7: {
    src: `${baseDir}/complete_story/story_6/scene_7.mp3`,
    level: storyLevel,
  },
  complete6_scene8_1: {
    src: `${baseDir}/complete_story/story_6/scene_8_1.mp3`,
    level: storyLevel,
  },
  complete6_scene8_2: {
    src: `${baseDir}/complete_story/story_6/scene_8_2.mp3`,
    level: storyLevel,
  },
  complete6_scene9: {
    src: `${baseDir}/complete_story/story_6/scene_9.mp3`,
    level: storyLevel,
  },
  complete6_scene10_1: {
    src: `${baseDir}/complete_story/story_6/scene_10_1.mp3`,
    level: storyLevel,
  },
  complete6_scene10_2: {
    src: `${baseDir}/complete_story/story_6/scene_10_2.mp3`,
    level: storyLevel,
  },
  complete6_scene11: {
    src: `${baseDir}/complete_story/story_6/scene_11.mp3`,
    level: storyLevel,
  },
};
