import * as React from "react";
import styled from "styled-components";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Text } from "~/components/atoms/texts/Text";
import { BaseModal } from "~/components/atoms/wrappers/BaseModal";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { useTranslation } from "~/i18n";

type CelebrateBirthdayModalProps = {
  open: boolean;
  onClose: () => void;
};

const calcAge = (birthday: string) => {
  const birthDate = new Date(birthday);
  const today = new Date();

  const birthYear = birthDate.getFullYear();
  const birthMonth = birthDate.getMonth();
  const birthDay = birthDate.getDate();

  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();

  let age = currentYear - birthYear;

  if (
    currentMonth < birthMonth ||
    (currentMonth === birthMonth && currentDay < birthDay)
  ) {
    age--;
  }

  return age;
};

const calcDaysSince = (dateString: string) => {
  const startDate = new Date(dateString);
  const today = new Date();

  const differenceInTime = today.getTime() - startDate.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays;
};

export const CelebrateBirthdayModal: React.FC<CelebrateBirthdayModalProps> = ({
  open,
  onClose,
}) => {
  const { currentUser } = React.useContext(CurrentUserContext);
  const nickname = currentUser?.general.nickname;
  const age = calcAge(currentUser?.birthday);
  const period = calcDaysSince(currentUser?.createdAt);

  const { t } = useTranslation();

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      closeOnOverlayClick={false}
      transparent={true}
      padding="0px"
    >
      <_Container>
        <div className="balloon">
          <div className="message">
            <_LetterText fontSize="SM" lineHeight="MD" bold>
              {t<TranslateKeys>("読書家の{{nickname}}さんへ", { nickname })}
            </_LetterText>
            <_LetterText fontSize="SM" lineHeight="MD" bold>
              {currentUser?.birthday
                ? `${t<TranslateKeys>("{{age}}才の", { age })}`
                : ""}

              {t<TranslateKeys>("お誕生日、おめでとう🎊")}
            </_LetterText>
            <_LetterText fontSize="SM" lineHeight="MD" bold>
              {t<TranslateKeys>("お話しするのは 照れちゃうから、")}
              <br />
              {t<TranslateKeys>("お手紙で 気持ちを 伝えるよ😳")}
            </_LetterText>

            {period > 30 ? (
              <_LetterText fontSize="SM" lineHeight="MD" bold>
                {t<TranslateKeys>("{{nickname}}さんと 出会って", { nickname })}
                <br />
                {t<TranslateKeys>("もう{{period}}日だね✨", { period })}
                <br />
                {t<TranslateKeys>("とっても 楽しかったよ！")}
              </_LetterText>
            ) : (
              <_LetterText fontSize="SM" lineHeight="MD" bold>
                {t<TranslateKeys>("まだ 出会ったばかり だけど")}
                <br />
                {t<TranslateKeys>("{{nickname}}さんの", { nickname })}
                <br />
                {t<TranslateKeys>("素敵なところ たくさん 見つけたよ")}
              </_LetterText>
            )}

            <_LetterText fontSize="SM" lineHeight="MD" bold>
              {t<TranslateKeys>("これからも、")}
              <br />
              {t<TranslateKeys>("{{nickname}}さんと一緒に", { nickname })}
              <br />
              {t<TranslateKeys>("たくさん 本を 読みたいな🥰")}
            </_LetterText>
            <_LetterText fontSize="SM" lineHeight="MD" bold>
              {t<TranslateKeys>("感想を")}
              <br />
              {t<TranslateKeys>("楽しみに 待っているね👀✨")}
            </_LetterText>
          </div>
          <img src="/img/celebrate_birthday.svg" style={{ width: "100%" }} />
          <div className="closing">
            <_LetterText fontSize="SM" lineHeight="MD" bold color="#723E31">
              {t<TranslateKeys>("{{nickname}}さんのことが大好きな", {
                nickname,
              })}
              <br />
              {t<TranslateKeys>("ヨンデミー先生より")}
            </_LetterText>
          </div>
        </div>
        <LargeButton color="primary" onClick={onClose}>
          {t<TranslateKeys>("閉じる")}
        </LargeButton>
      </_Container>
    </BaseModal>
  );
};

const _LetterText = styled(Text)`
  color: #723e31;
`;

const _Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 335px;
  row-gap: 24px;

  .message {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .closing {
    width: 100%;
    text-align: right;
  }

  .balloon {
    width: 100%;
    box-sizing: border-box;
    padding: 32px;
    border-radius: 16px;

    background-color: #fffcf8;

    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: center;

    background-image: linear-gradient(to bottom, #e8ddcc 1px, transparent 1px);
    background-size: 100% 24px;
  }
`;
