import React from "react";
import { MasonryPagiableList } from "~/components/molecules/lists/MasonryPagiableList";
import { ListItemProps } from "~/components/molecules/lists/MasonryPagiableList/types";
import { MasonryPagiableListProps } from "~/components/molecules/lists/MasonryPagiableList/types";
import { BookInfoCardProps } from "~/components/molecules/BookInfoCard";
import { BookInfoCard } from "~/components/molecules/BookInfoCard";

type BaseBookReviewListProps = MasonryPagiableListProps<BookInfoCardProps>;

export type BookReviewListProps = Omit<
  BaseBookReviewListProps,
  "renderListItem"
>;

export const BookReviewList: React.FC<BookReviewListProps> = ({
  currentCount,
  items,
  itemTotal,
  itemsPerPage,
  scrollToOffset,
  isLoading,
  onPageChange,
  renderEmptyComponent,
}) => {
  return (
    <MasonryPagiableList
      currentCount={currentCount}
      items={items}
      itemTotal={itemTotal}
      itemsPerPage={itemsPerPage}
      onPageChange={onPageChange}
      isLoading={isLoading}
      scrollToOffset={scrollToOffset}
      renderListItem={({ item }: ListItemProps<BookInfoCardProps>) => {
        const { title, imageUrl, isInYomimashoka, yl, onClick, review, date } =
          item;
        return (
          <div style={{ paddingBottom: "8px", width: "100%" }}>
            <BookInfoCard
              title={title}
              imageUrl={imageUrl}
              isInYomimashoka={isInYomimashoka}
              yl={yl}
              onClick={onClick}
              review={review}
              date={date}
            />
          </div>
        );
      }}
      renderEmptyComponent={renderEmptyComponent}
    />
  );
};
