import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  Operation,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { onError } from "@apollo/link-error";

import { config } from "config";

import * as Sentry from "@sentry/react";
import { Kind } from "graphql";
import { clientSchemaQueryFieldPolicies } from "~/schema/fieldPolicies";
import { extendedResolver, extendedSchema } from "~/store/schema/talk_cache";
import { sentryErrorTrackingID } from "~/utils/sentry";
import { getAccessToken } from "./contexts/Auth0Context";
import { getSelectedUserId } from "./contexts/SwitchUserContext";

const ERROR_TRACKING_ID_HEADER = "X-Error-Tracking-ID";

const findMutation = (operation: Operation) => {
  return operation.query.definitions.find(
    (def) =>
      def.kind === Kind.OPERATION_DEFINITION && def.operation === "mutation"
  );
};

const httpLink = createHttpLink({
  uri: config.API_HOST + "graphql",
  fetchOptions: {
    mode: "cors",
  },
  credentials: "include",
});

const headerLink = setContext(async () => {
  const selectedUserId = getSelectedUserId();
  const token = await getAccessToken();

  return {
    headers: {
      [ERROR_TRACKING_ID_HEADER]: sentryErrorTrackingID,
      ...(selectedUserId ? { "X-User-ID": selectedUserId } : {}),
      ...(token?.token ? { Authorization: `Bearer ${token.token}` } : {}),
    },
  };
});

const errorLink = onError(
  ({ networkError, graphQLErrors, operation, forward }) => {
    const hasMutation = findMutation(operation);
    if (networkError) {
      const repeat = operation.getContext().repeat ?? 0;
      if (!hasMutation && repeat + 1 < config.REQUEST_REPEAT) {
        operation.setContext({
          repeat: repeat === undefined ? 1 : repeat + 1,
        });
        return forward(operation);
      } else {
        return;
      }
    }

    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const tmpError = err.extensions.tmp_error;

        if (tmpError.includes("session_wornout")) {
          operation.setContext({ resetSession: true });
          return forward(operation);
        }

        Sentry.withScope((scope) => {
          scope.setExtra("エラー詳細", JSON.stringify(err, null, 4));
          scope.setExtra("operationName", operation.operationName);
          scope.setExtra(
            "variables",
            JSON.stringify(operation.variables, null, 4)
          );
          scope.setLevel(Sentry.Severity.Error);
          Sentry.captureMessage(
            `GraphQL Error: ${operation.operationName} - ${err.message}`
          );
        });

        if (
          err.extensions === undefined ||
          tmpError === undefined ||
          typeof tmpError !== "string"
        ) {
          continue;
        }

        if (err.message == "temporary_error") {
          const repeat = operation.getContext().repeat ?? 0;
          if (!hasMutation && repeat + 1 < config.REQUEST_REPEAT) {
            operation.setContext({
              repeat: repeat === undefined ? 1 : repeat + 1,
            });
            return forward(operation);
          } else {
            return;
          }
        }
      }
    }
  }
);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          ...clientSchemaQueryFieldPolicies,
        },
      },
      Account: {
        // AccountのキャッシュはシングルトンなのでkeyFieldsを空にする
        keyFields: [],
        fields: {
          general: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(existing = {}, incoming: any) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
    },
  }),
  link: ApolloLink.from([errorLink, headerLink, httpLink]),
  typeDefs: extendedSchema,
  connectToDevTools: config.ENV === "development",
  resolvers: extendedResolver,
});
