import React from "react";
import {
  BookshelfDisplayType,
  BookshelfRecordTemplate,
  displayTypeImageList,
  displayTypeReviewList,
} from "~/components/templates/RecordBook/Bookshelf";
import { useGetDoneRecommendBookshelfQuery } from "~/generated/graphql";
import { config } from "../../../../config";
import { LoadingSection } from "~/components/templates/Loading/LoadingSection";
import { useLocation } from "react-router-dom";

export const BOOKSHELF_DISPLAY_TYPE_QUERY_STRING_KEY =
  "book_shelf_display_type";

export const BookshelfRecord: React.FC = () => {
  const [begin, setBegin] = React.useState(0);
  const { data, loading, error } = useGetDoneRecommendBookshelfQuery({
    variables: {
      begin: begin,
      end: begin + config.MID_LIST_LENGTH,
    },
    fetchPolicy: "no-cache",
  });
  const search = useLocation().search;
  const displayType: BookshelfDisplayType | undefined = React.useMemo(() => {
    const params = new URLSearchParams(search);
    const _tmpDisplayType = params.get(BOOKSHELF_DISPLAY_TYPE_QUERY_STRING_KEY);
    if (_tmpDisplayType === displayTypeReviewList) {
      return displayTypeReviewList;
    } else if (_tmpDisplayType === displayTypeImageList) {
      return displayTypeImageList;
    } else {
      return undefined;
    }
  }, [search]);

  if (loading || !data) {
    return <LoadingSection />;
  }
  if (error) {
    return <>Error!</>;
  }
  return (
    <BookshelfRecordTemplate
      recommends={data.getDoneRecommends.get.map((recommend) => {
        return {
          bookId: String(recommend.id),
          recommendId: recommend.id,
          title: recommend.book.title || "",
          imageUrl: recommend.book.imageUrl,
          status: recommend.recommendStatus,
          review: recommend.readLog.get[0].review,
          columnsNumber: 3,
          showStatus: false,
          date: new Date(recommend.whenDone),
          yl: recommend.book.yl,
        };
      })}
      setBegin={setBegin}
      begin={begin}
      totalCount={data.getDoneRecommends.count}
      defaultDisplayType={displayType}
    />
  );
};
