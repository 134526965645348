import React from "react";
import { ConfirmEmotionsTemplate } from "~/components/templates/PostReadLog/ConfirmEmotions";
import { AllEmotionStates } from "../reducer";
import { usePostReadLogState } from "../usePostReadLogState";

type ConfirmEmotionsProps = {
  nickname: string;
};

export const ConfirmEmotions: React.FC<ConfirmEmotionsProps> = ({
  nickname,
}) => {
  const { state, dispatch } = usePostReadLogState();

  const { questionNumber, lastProgressNumber } = state;
  const { ...allEmotionsStates }: AllEmotionStates = state;
  const {
    wakuwaku,
    sikusiku,
    dokidoki,
    nikoniko,
    nattoku,
    waraeru,
    bikkuri,
    punpun,
    kowai,
    dousite,
  } = allEmotionsStates;

  const handleBack = (): void => {
    dispatch({
      type: "setQuestionNumber",
      payload: currentQuestionNumber - 1,
    });
    dispatch({
      type: "setFlow",
      payload: "confirmLength",
    });
  };

  const handleNext = (event: React.MouseEvent): void => {
    event.preventDefault();
    dispatch({
      type: "setQuestionNumber",
      payload: currentQuestionNumber + 1,
    });

    dispatch({
      type: "setFlow",
      payload: "kansouSelectWriting",
    });
  };

  const onClicks: Record<
    keyof AllEmotionStates,
    (event: React.MouseEvent) => void
  > = {
    wakuwaku: (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setWakuwaku",
        payload: !wakuwaku,
      });
    },
    sikusiku: (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setSikusiku",
        payload: !sikusiku,
      });
    },
    dokidoki: (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setDokidoki",
        payload: !dokidoki,
      });
    },
    nikoniko: (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setNikoniko",
        payload: !nikoniko,
      });
    },
    nattoku: (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setNattoku",
        payload: !nattoku,
      });
    },
    waraeru: (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setWaraeru",
        payload: !waraeru,
      });
    },
    bikkuri: (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setBikkuri",
        payload: !bikkuri,
      });
    },
    punpun: (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setPunpun",
        payload: !punpun,
      });
    },
    kowai: (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setKowai",
        payload: !kowai,
      });
    },
    dousite: (event: React.MouseEvent) => {
      event.preventDefault();
      dispatch({
        type: "setDousite",
        payload: !dousite,
      });
    },
  };

  const currentQuestionNumber = questionNumber ? questionNumber : 1;

  const progress = Math.floor(
    (currentQuestionNumber / lastProgressNumber) * 100
  );

  return (
    <ConfirmEmotionsTemplate
      progress={progress}
      handleBack={handleBack}
      nickname={nickname}
      onClicks={onClicks}
      onClickGoNext={handleNext}
      allEmotions={allEmotionsStates}
      isNoneSelected={
        !wakuwaku &&
        !dokidoki &&
        !nattoku &&
        !sikusiku &&
        !nikoniko &&
        !bikkuri &&
        !waraeru &&
        !punpun &&
        !kowai &&
        !dousite
      }
    />
  );
};
