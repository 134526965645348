import React from "react";
import styled from "styled-components";
import { Icon } from "~/components/atoms/Icon";
import { Text } from "~/components/atoms/texts/Text";
import { useTranslation } from "~/i18n";
import { KansouSelectWritingTemplateProps } from "./type";
import { CharacterIconTextButton } from "~/components/molecules/buttons/CharacterIconTextButton";
import { ProgressBar } from "~/components/atoms/ProgressBar";
import { Loading } from "~/components/atoms/Loading";
import { Stack } from "~/components/atoms/layout/Stack";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { NavContainer, Wrapper } from "../../Common";
import {
  PostReadLogHeading,
  PostReadLogBodyWrapper,
  PostReadLogMarginInterpolation,
} from "../Common/style";
import { unlockLevel } from "~/store/unlock/useUnlock";

export const KansouSelectWritingTemplate: React.FC<
  KansouSelectWritingTemplateProps
> = ({ progress, handleBack, onClicks, isLimited, isUnlock, loading }) => {
  const { t } = useTranslation();

  return (
    <>
      <NavContainer>
        <BackNavbar titleName="かんそうをかく" onClick={handleBack} />
      </NavContainer>
      <Wrapper>
        <PostReadLogBodyWrapper>
          <ProgressCon>
            <ProgressBar progress={progress} backgroundColor="base.gray" />
          </ProgressCon>
          <PostReadLogHeading fontSize={"LG"} lineHeight={"MD"} bold>
            {"かき方をえらんでね"}
          </PostReadLogHeading>
          <ButtonCon rowGap="16px" alignItems="center">
            <CharacterIconTextButton
              text={"じぶんでかく"}
              imageUrl={"/img/sensei/standup_1.svg"}
              onClick={onClicks[0]}
              fontSize="XXL"
              lineHeight="EQ"
              bold={true}
              disabled={loading}
            />
            {loading ? (
              <Loading />
            ) : (
              <CharacterIconTextButton
                text={"ハナシテミーでかく"}
                imageUrl={"/img/character/penguin.svg"}
                tagText={"テストばん"}
                onClick={onClicks[1]}
                fontSize="XXL"
                lineHeight="EQ"
                bold={true}
                disabled={isLimited || isUnlock}
              />
            )}
          </ButtonCon>
          {isUnlock && (
            <_FlameWrapper>
              <_FlameTextWrapper>
                <Text
                  fontSize="MD"
                  lineHeight="MD"
                  fontColor="tex.t500"
                  bold
                  style={{ paddingBottom: "12px" }}
                >
                  <_HighlightWrapper>
                    レベル{unlockLevel.kansouSupportChat}
                  </_HighlightWrapper>
                  になったら、
                  {t<TranslateKeys>("ハナシテミーが使えるよ")}
                </Text>

                <_TextWrapper>
                  <Icon
                    name="question"
                    color="tex"
                    style={{ width: "24px", height: "24px" }}
                  ></Icon>

                  <Text fontSize="SM" lineHeight="MD">
                    <b>{t<TranslateKeys>("レベルを上げるには？")}</b>
                    <br></br>
                    {t<TranslateKeys>(
                      "ミニレッスンを受けたり、本の感想を出したりすると、レベルが上がるよ"
                    )}
                  </Text>
                </_TextWrapper>
              </_FlameTextWrapper>
            </_FlameWrapper>
          )}
          {isLimited && (
            <Text fontSize="SM" lineHeight="MD">
              {t<TranslateKeys>(
                "今日のぶんのハナシテミーは使いおわったよ。また明日きてね！"
              )}
            </Text>
          )}
        </PostReadLogBodyWrapper>
      </Wrapper>
    </>
  );
};

const ProgressCon = styled.div`
  margin-bottom: 20px;
`;

const ButtonCon = styled(Stack)`
  ${PostReadLogMarginInterpolation.Narrow}
`;

const _FlameWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: ${({ theme }) => theme.size.s2};
  position: relative;
  display: flex;
`;

const _FlameTextWrapper = styled.div`
  padding: 16px;
`;

const _TextWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

const _HighlightWrapper = styled.span`
  color: ${({ theme }) => theme.colors.pri.p300};
`;
