// openaiのresponseをそのまま返しているため整形するために使用
import {
  MESSAGE,
  MESSAGE_ROLE,
  MessageType,
  MessageEnum,
  MessageRoleType,
  MessageRoleEnum,
  messageTypeRegex,
  choiceRegex,
  kansouRegex,
} from "~/helpers/constants/kansouSupportChat";
import {
  ChoiceItem,
  KansouSupportChatMessageFragment,
  KansouSupportChatContentType,
} from "~/generated/graphql";

export type KansouSupportChatMessages = {
  id: string;
  value: string;
  role: MessageRoleType;
  type?: MessageType;
  choices?: ChoiceItem[];
  kansou?: string;
};

export const messageFormat = (
  message: KansouSupportChatMessageFragment
): KansouSupportChatMessages => {
  const format = {} as KansouSupportChatMessages;
  format.id = message.id;
  switch (message.role) {
    case MESSAGE_ROLE.USER:
      format.role = MessageRoleEnum.user;
      break;
    case MESSAGE_ROLE.ASSISTANT:
      format.role = MessageRoleEnum.assistant;
      break;
  }

  const messageContent = message.content[0];
  const messageValue =
    messageContent.type === KansouSupportChatContentType.ImageFile
      ? "しゃしんを おくったよ"
      : messageContent.text?.value;
  if (!messageValue) {
    return format;
  }

  const matchList = messageValue.match(messageTypeRegex);
  if (matchList && matchList.length > 0) {
    const messageType = matchList[0];
    switch (messageType) {
      case MESSAGE.IMAGE:
        format.type = MessageEnum.image;
        break;
      case MESSAGE.KANSOU:
        format.type = MessageEnum.kansou;
        break;
      case MESSAGE.CHOICE:
        format.type = MessageEnum.choice;
        break;
    }

    let replaceMessage = messageValue.replace(messageTypeRegex, "");
    if (format.type === MessageEnum.choice) {
      const choiceList = replaceMessage.match(choiceRegex);
      replaceMessage = replaceMessage.replace(choiceRegex, "");

      format.choices = choiceList?.map((c) => {
        return { choice: c } as ChoiceItem;
      });
    } else if (format.type === MessageEnum.kansou) {
      const kansouMatchResult = replaceMessage.match(kansouRegex);
      if (kansouMatchResult) {
        format.kansou = kansouMatchResult[1];
      }
    }
    format.value = replaceMessage.trimEnd();
  } else {
    format.value = messageValue;
  }
  return format;
};
