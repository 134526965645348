import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { IndicateFeedbackTemplate } from "~/components/templates/PostReadLog/IndicateFeedback";
import { PromiseDate, useMakePromiseMutation } from "~/generated/graphql";
import { usePostReadLogState } from "../usePostReadLogState";
import { useGetNowRecommendQuery } from "~/generated/graphql";
import { BOOKSHELF_DISPLAY_TYPE_QUERY_STRING_KEY } from "~/pages/RecordBook/Bookshelf";
import {
  displayTypeImageList,
  displayTypeReviewList,
} from "~/components/templates/RecordBook/Bookshelf";
import { SKIP_OPEN_ANIMATION_SESSION_STORAGE_KEY } from "~/pages/RecordBook";

const NEXT_BOOK_LIST_LENGTH = 4;

export const IndicateFeedback: React.FC = () => {
  const { state } = usePostReadLogState();
  const [makePromise] = useMakePromiseMutation();
  const navigate = useNavigate();

  //「次はどの本を読む？」のモーダル用
  const [page, setPage] = useState<number>(0);
  const begin = useMemo(
    () => (page === 0 ? 0 : page * NEXT_BOOK_LIST_LENGTH - 1),
    [page]
  );

  //begin == 0 の時は本を三冊しか取ってこないため、 - 1 をしています
  const { loading, error, data } = useGetNowRecommendQuery({
    variables: {
      begin: begin,
      end:
        begin === 0
          ? begin + NEXT_BOOK_LIST_LENGTH - 1
          : begin + NEXT_BOOK_LIST_LENGTH,
    },
  });

  const itemTotal = useMemo(() => data?.me.recommends.count || 0 + 1, [data]);
  const maxPage = Math.floor(itemTotal / NEXT_BOOK_LIST_LENGTH);

  if (!state.postReadLogResult) {
    return null;
  }
  if (error) return <>Error! {error.message}</>;

  // このコンポーネントでは、「自分で選んで読む」も本の内の一つとして扱う
  // NOTE「自分で選んで読む」をリストに含めるかどうかはここで決める (含める場合hasOtherBook = Trueに)
  return (
    <IndicateFeedbackTemplate
      modalProps={{
        selectNextBookImgListWithoutOnClickProps: {
          data: data?.me.recommends.get || [],
          renderEmptyComponent: EmptyComponent,
          hasOtherBook: begin === 0,
          loading: loading,
          hasNext: page < maxPage,
          hasPrev: page > 0,
          onChangePageToNext: () => setPage((p) => p + 1),
          onChangePageToPrev: () => setPage((p) => p - 1),
        },
        isOpen: true,
        readLogResult: state.postReadLogResult,
        onExit: () => {
          try {
            sessionStorage.setItem(
              SKIP_OPEN_ANIMATION_SESSION_STORAGE_KEY,
              "true"
            );
          } catch (e) {
            console.error(e);
          }

          if (!state.postReadLogResult?.readLog.review) {
            navigate(
              `/record_book/bookshelf?${BOOKSHELF_DISPLAY_TYPE_QUERY_STRING_KEY}=${displayTypeImageList}`
            );
          } else {
            navigate(
              `/record_book/bookshelf?${BOOKSHELF_DISPLAY_TYPE_QUERY_STRING_KEY}=${displayTypeReviewList}`
            );
          }
        },
        onMakePromise: (
          date: PromiseDate,
          chooseNext: boolean,
          nextISBN: string | undefined
        ) => {
          return new Promise((resolve) => {
            makePromise({
              variables: {
                promiseDate: date,
                chooseNext: chooseNext,
                nextISBN: nextISBN,
              },
            })
              .then((res) => {
                if (res?.data?.createReadLogPromise.id) {
                  resolve(true);
                } else {
                  resolve(false);
                }
              })
              .catch(() => {
                resolve(false);
              });
          });
        },
      }}
    />
  );
};

const EmptyComponent = (): JSX.Element => {
  return <></>;
};
