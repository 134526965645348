import React, { useCallback, useMemo, useState } from "react";
import { KansouSupportChatTemplate } from "~/components/templates/PostReadLog/KansouSupportChat";
import { usePostReadLogState } from "../usePostReadLogState";
import { lastMessageSentReactiveVar } from "~/store/message";
import {
  MessageEnum,
  MESSAGE_ROLE,
  MessageRoleEnum,
} from "~/helpers/constants/kansouSupportChat";
import {
  KansouSupportChatMessages,
  messageFormat,
} from "~/utils/kansouSupportChat";
import { ImageUtil } from "~/utils/image";
import {
  useSendToKansouSupportChatMessageMutation,
  useGetKansouSupportChatMessagesQuery,
  KansouSupportChatContentType,
  KansouSupportChatMessage,
  useUploadImageFileMutation,
} from "~/generated/graphql";
import { ImagePreviewModal } from "~/components/organisms/modals/KansouSupportChat/ImagePreviewModal";

export const KansouSupportChat: React.FC = () => {
  const {
    state: { kansouSupportChatId, review, bookISBN },
    dispatch,
  } = usePostReadLogState();

  const [inputMessage, setInputMessage] = useState<string>("");
  const [sendMessage, setSendMessage] = useState<string>();
  const [fileID, setFileID] = useState<string>();
  const [kansouSupportChatMessages, setKansouSupportChatMessages] = useState<
    KansouSupportChatMessages[]
  >([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isOpenImagePreviewModal, setIsOpenImagePreviewModal] =
    useState<boolean>(false);
  const [latestMessageID, setLatestMessageID] = useState<string>();
  const [imageUploadLoading, setImageUploadLoading] = useState<boolean>(false);

  const [sendToKansouSupportChatMessage, { loading }] =
    useSendToKansouSupportChatMessageMutation();

  const [uploadImageFile] = useUploadImageFileMutation();

  useGetKansouSupportChatMessagesQuery({
    skip: !kansouSupportChatId,
    variables: {
      kansouSupportChatId: kansouSupportChatId,
    },
    fetchPolicy: "no-cache",
    onCompleted(res) {
      setLatestMessageID(res.getKansouSupportChatMessages[0].id);
      const sortMessages = res.getKansouSupportChatMessages.sort((a, b) =>
        new Date(b.createdAt) < new Date(a.createdAt) ? 1 : -1
      );
      const formatSortMessages = sortMessages.map((s) => {
        return messageFormat(s);
      });
      setKansouSupportChatMessages(formatSortMessages);
    },
  });

  const placeholder = () => {
    return "ここにかいて  こたえてもいいよ📝";
  };

  const preSendMessage = (messageValue?: string) => {
    const standbyText = ["えーっと……💭", "カキカキ……✍"];
    const userMessage = {
      role: MESSAGE_ROLE.USER,
      content: [
        {
          type: KansouSupportChatContentType.Text,
          text: { value: messageValue },
        },
      ],
    } as KansouSupportChatMessage;
    const preAssistantMessage = {
      role: MESSAGE_ROLE.ASSISTANT,
      content: [
        {
          type: KansouSupportChatContentType.Text,
          text: {
            value: standbyText[Math.floor(Math.random() * standbyText.length)],
          },
        },
      ],
    } as KansouSupportChatMessage;
    const userMessageFormat = messageFormat(userMessage);
    const preAssistantMessageFormat = messageFormat(preAssistantMessage);
    const preKansouSupportChatMessages = [
      ...kansouSupportChatMessages,
      userMessageFormat,
      preAssistantMessageFormat,
    ];
    setKansouSupportChatMessages(preKansouSupportChatMessages);
    setLatestMessageID(undefined);
    setTimeout(() => {
      lastMessageSentReactiveVar(new Date().getTime());
    }, 0);
    return [...kansouSupportChatMessages, userMessageFormat];
  };

  const send = (event: React.MouseEvent, message?: string) => {
    event.preventDefault();
    const currentMessage = message ? message : inputMessage;
    setSendMessage(currentMessage);
  };

  const handleBack = (): void => {};

  const handleNext = (): void => {
    dispatch({
      type: "setFlow",
      payload: "kansouPresent",
    });
  };

  const onClicks = [
    (event: React.MouseEvent) => {
      event.preventDefault();
      handleNext();
    },
  ];

  const onCloseImagePreviewModal = useCallback(() => {
    setIsOpenImagePreviewModal(false);
  }, []);
  const onSubmitImagePreviewModal = useCallback(
    async (event: React.MouseEvent) => {
      event.preventDefault();
      if (selectedFile) {
        setImageUploadLoading(true);
        try {
          const compressedFile = await ImageUtil.getCompressImageFileAsync(
            selectedFile
          );
          const compressedDataUrlFromFile = await ImageUtil.getDataUrlFromFile(
            compressedFile
          );
          const replaceCompressedDataUrlFromFile =
            compressedDataUrlFromFile.replace(/^data:\w+\/\w+;base64,/g, "");

          uploadImageFile({
            variables: {
              kansouSupportChatId: kansouSupportChatId,
              fileName: selectedFile.name,
              base64Data: replaceCompressedDataUrlFromFile,
              bookISBN: bookISBN,
            },
            onCompleted(res) {
              const fileID = res.uploadImageFile?.fileId;
              setFileID(fileID);
              setImageUploadLoading(false);
            },
          });
        } catch (error) {
          setImageUploadLoading(false);
        }
        setIsOpenImagePreviewModal(false);
      }
    },
    [selectedFile]
  );

  useMemo(() => {
    if (!sendMessage && !fileID) {
      return;
    }
    const currentKansouSupportChatMessages = preSendMessage(
      fileID ? "しゃしんを おくったよ" : sendMessage
    );
    sendToKansouSupportChatMessage({
      variables: {
        kansouSupportChatId: kansouSupportChatId,
        messages: [
          fileID
            ? {
                type: KansouSupportChatContentType.ImageFile,
                imageFile: { id: fileID },
              }
            : { type: KansouSupportChatContentType.Text, text: sendMessage },
        ],
      },
      onCompleted(res) {
        const assistantMessages = res.sendToKansouSupportChatMessage
          .map((m) => messageFormat(m))
          .filter((message) => message.role === MessageRoleEnum.assistant);
        setKansouSupportChatMessages(
          currentKansouSupportChatMessages?.concat(assistantMessages)
        );
        setLatestMessageID(assistantMessages[0].id);

        setTimeout(() => {
          lastMessageSentReactiveVar(new Date().getTime());
        }, 0);

        const assistantMessage = assistantMessages[0];
        if (assistantMessage.type === MessageEnum.kansou) {
          dispatch({
            type: "setReview",
            payload: assistantMessage.kansou,
          });
        }
      },
    });
    setInputMessage("");
    setSendMessage(undefined);
    setFileID(undefined);
  }, [sendMessage, fileID]);

  return (
    <>
      <ImagePreviewModal
        isOpen={isOpenImagePreviewModal}
        file={selectedFile}
        loading={imageUploadLoading}
        onClose={onCloseImagePreviewModal}
        onSubmit={onSubmitImagePreviewModal}
      />
      <KansouSupportChatTemplate
        handleBack={handleBack}
        onClicks={onClicks}
        isKansou={!!review}
        kansouSupportChatListProps={{
          data: kansouSupportChatMessages,
          latestMessageID: latestMessageID,
          fetchAll: true,
          firstItemIndex: 0,
          setIsScrolling: undefined,
          onUpload() {
            const input = document.createElement("input");
            input.type = "file";
            input.accept = "image/*";
            input.capture = "environment";
            input.hidden = true;
            input.addEventListener(
              "change",
              (e) => {
                const target = e.target as HTMLInputElement;
                const file = (target.files as FileList)[0];
                setSelectedFile(file);
                setIsOpenImagePreviewModal(true);
              },
              false
            );
            input.click();
          },
          onSubmit: send,
        }}
        footerMessageProps={{
          value: inputMessage,
          disabledSubmit: !inputMessage,
          disabledInput: loading,
          placeholder: placeholder(),
          onChange: (value) => setInputMessage(value),
          onSubmit: send,
        }}
      />
    </>
  );
};
