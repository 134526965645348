import React, { FC } from "react";
import styled from "styled-components";
import { ConfirmEmotionsTemplateProps, emotions } from "./type";
import { ProgressBar } from "~/components/atoms/ProgressBar";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { NavContainer, Wrapper } from "../../Common";
import {
  PostReadLogHeading,
  PostReadLogBodyWrapper,
  PostReadLogMarginInterpolation,
} from "../Common/style";
import { EmojiSolidRadioButton } from "~/components/molecules/forms/EmojiRadioButton";
import { PrimaryButton } from "~/components/molecules/buttons/PrimaryButton";
import { useTranslation } from "~/i18n";

export const ConfirmEmotionsTemplate: FC<ConfirmEmotionsTemplateProps> = ({
  progress,
  handleBack,
  nickname,
  onClickGoNext,
  onClicks,
  allEmotions,
  isNoneSelected,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <NavContainer>
        <BackNavbar titleName="かんそうをかく" onClick={handleBack} />
      </NavContainer>
      <Wrapper>
        <PostReadLogBodyWrapper>
          <ProgressCon>
            <ProgressBar progress={progress} backgroundColor="base.gray" />
          </ProgressCon>
          <PostReadLogHeading fontSize={"LG"} lineHeight={"MD"} bold>
            {t<TranslateKeys>("この本を読んだときの、")}
            <br />
            {nickname + t<TranslateKeys>("さんの気持ちに近いものは？")}
          </PostReadLogHeading>
          <ButtonsCon>
            {Object.keys(onClicks).map((key, index) => (
              <Con key={index}>
                <EmojiSolidRadioButton
                  onClick={onClicks[key]}
                  selected={allEmotions[key]}
                  color={"pri"}
                  text={emotions[key].ja}
                  emoji={emotions[key].emoji}
                />
              </Con>
            ))}
          </ButtonsCon>
          <GoNextButtonCon>
            <PrimaryButton
              onClick={onClickGoNext}
              text={isNoneSelected ? "スキップ" : "すすむ"}
              color={"pri"}
              fontSize={"LG"}
              lineHeight={"MD"}
              bold
              withArrow
            />
          </GoNextButtonCon>
        </PostReadLogBodyWrapper>
      </Wrapper>
    </>
  );
};

const ProgressCon = styled.div`
  margin-bottom: 20px;
`;

const ButtonsCon = styled.div`
  ${PostReadLogMarginInterpolation.Wide}
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: ${({ theme }) => theme.size.M};
`;

const GoNextButtonCon = styled.div`
  ${PostReadLogMarginInterpolation.Wide}
`;

const Con = styled.div`
  width: 47%;
`;
