import React from "react";
import styled from "styled-components";
import { Row } from "~/components/atoms/layout/Row";

export type PresentImageProps = {
  imageUrl: string;
};

export const PresentImage: React.FC<PresentImageProps> = ({ imageUrl }) => {
  return (
    <_Wrapper alignItems="center" justifyContent="center">
      <img src={imageUrl} width={"230px"} height={"230px"} />
    </_Wrapper>
  );
};

const _Wrapper = styled(Row)`
  width: 320px;
  height: 320px;
  background-image: url("/img/origami/frame.svg");
  background-size: contain;
`;
