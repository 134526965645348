import React, { useState } from "react";
import styled from "styled-components";
import {
  BaseModal,
  BaseModalFooter,
  BaseModalHeader,
} from "~/components/atoms/wrappers/BaseModal";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { TextArea } from "~/components/atoms/forms/TextArea";

export type KansouEditModalProps = {
  isOpen: boolean;
  review: string;
  onSubmit: (review: string) => void;
  onClose: () => void;
};

export const KansouEditModal: React.FC<KansouEditModalProps> = ({
  isOpen,
  review,
  onSubmit,
  onClose,
}) => {
  const [kansouEdit, setKansouEdit] = useState<string>(review);
  return (
    <BaseModal
      open={isOpen}
      onClose={onClose}
      header={
        <BaseModalHeader>
          <Text fontSize="LG" lineHeight="MD" bold>
            かきなおす
          </Text>
        </BaseModalHeader>
      }
      footer={
        <BaseModalFooter>
          <Stack alignItems="center">
            <LargeButton onClick={() => onSubmit(kansouEdit)}>
              ほぞん
            </LargeButton>
            <LargeButton onClick={onClose} color="tertiary" variant="blank">
              やめる
            </LargeButton>
          </Stack>
        </BaseModalFooter>
      }
    >
      <_Wrapper>
        <TextArea
          onChange={(value) => setKansouEdit(value.target.value)}
          value={kansouEdit}
          style={{ width: "280px", height: "200px" }}
        />
      </_Wrapper>
    </BaseModal>
  );
};

const _Wrapper = styled(Stack)`
  width: 100%;
  max-width: 400px;
`;
