import React from "react";
import styled from "styled-components";
import { KansouSupportChatMessage } from "../../../KansouSupportChatMessage";
import { MessageItemText } from "~/components/molecules/texts/MessageItemText";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { KansouSupportChatMessages } from "~/utils/kansouSupportChat";
import {
  MessageEnum,
  MessageRoleEnum,
} from "~/helpers/constants/kansouSupportChat";

export type RemarkMessageProps = {
  message: KansouSupportChatMessages;
  latestMessageID?: string;
  onUpload: () => void;
};

export const RemarkMessage: React.FC<RemarkMessageProps> = ({
  message,
  latestMessageID,
  onUpload,
}) => {
  return (
    <KansouSupportChatMessage role={message.role}>
      <MessageItemText
        textType="plain"
        showAll={true}
        onCompleted={() => {}}
        text={message.value}
      />
      {message.role === MessageRoleEnum.assistant &&
        message.type === MessageEnum.image && (
          <_ButtonWrapper>
            <LargeButton
              onClick={onUpload}
              disabled={latestMessageID !== message.id}
            >
              しゃしんを おくる
            </LargeButton>
          </_ButtonWrapper>
        )}
    </KansouSupportChatMessage>
  );
};

const _ButtonWrapper = styled.div`
  margin-top: 12px;
`;
