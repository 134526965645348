import { MissionOrder, MissionState, UserIconType } from "~/generated/graphql";
import { TranslateKeys } from "~/i18n";
import { UnlockIconStatus } from "~/components/templates/EditProfile/index";

type IconDescription = {
  name: TranslateKeys;
  description: TranslateKeys;
};
export const userIconProfile: Record<UserIconType | "LOCKED", IconDescription> =
  {
    APPLE: {
      name: "りんごちゃん",
      description: "その昔、人間に 知恵をさずけてくれた、伝説のりんご。",
    },
    PENGUIN: {
      name: "ペンギンせんぱい",
      description:
        "ふだんは、南極に住んでいる。冬にはむれで、音読会を するらしい。",
    },
    WORM: {
      name: "本の虫",
      description:
        "さいしょは 本を食べてばかりいたが、ヨンデミーで 本を読むことを おぼえた。",
    },
    LION: {
      name: "ベジタライオン",
      description:
        "本を読んで、なぜ自分は 肉を食べるのか わからなくなり、ベジタリアンになった。",
    },
    DINOSAUR: {
      name: "太古のきょうりゅう",
      description:
        "ながいねむりから目が覚めたあと、ヨンデミーのおすすめ本で自分の仲間がぜつめつしたことを知った。",
    },
    WOLF: {
      name: "オオカミスミス",
      description:
        "紙職人の家に生まれたが、家出した一匹オオカミ。オオカミがお話の中でワルモノになりがちなことを実は少し気にしている。",
    },
    GIRAFFE: {
      name: "よみキリン",
      description:
        "生まれつきピンクのキリン。長い首を伸ばして長い本を読みきるのが得意なようだ。",
    },
    SHEEP: {
      name: "モコひつじ",
      description:
        "本を読んで想像力を膨らませるとモコモコが膨らむ。眠れないときに数えるひつじの中に混ざっているらしい。",
    },
    LOCKED: {
      name: "？？？",
      description:
        "ヨンデミッションをクリアしてヨンデミーストーリーを進めると、新しいアイコンがもらえるよ！次は誰がもらえるかな？",
    },
  };

export const userLockedIconImage: Record<UserIconType, string> = {
  WORM: "/img/icon/book_worm_gray.jpg",
  DINOSAUR: "/img/icon/dinosaur_gray.jpg",
  APPLE: "/img/icon/apple_gray.jpg",
  LION: "/img/icon/lion_gray.jpg",
  PENGUIN: "/img/icon/penguin_gray.jpg",
  SHEEP: "/img/icon/sheep_gray.jpg",
  GIRAFFE: "/img/icon/giraffe_gray.jpg",
  WOLF: "/img/icon/wolf_gray.jpg",
};

export const missonNumber: Record<MissionOrder, number> = {
  MISSION_1: 1,
  MISSION_2: 2,
  MISSION_3: 3,
  MISSION_4: 4,
  MISSION_5: 5,
  MISSION_6: 6,
};

export const missionProgressNumber: Record<
  Exclude<MissionState, "BEFORE_FIRST_MISSION" | "ALL_OVER">,
  number
> = {
  WAITING_FOR_NEXT_MISSION: 1,
  NEED_START: 2,
  IN_PROGRESS: 3,
  NEED_COMPLETE: 4,
};

type missionUnlockProps = {
  mission: number;
  timing: number;
  index: number;
};

export const iconUnlockTiming: Record<UserIconType, missionUnlockProps> = {
  WORM: {
    mission: 0,
    timing: 0,
    index: 0,
  },
  DINOSAUR: {
    mission: missonNumber[MissionOrder.Mission_1],
    timing: missionProgressNumber[MissionState.NeedComplete],
    index: 1,
  },
  APPLE: {
    mission: missonNumber[MissionOrder.Mission_2],
    timing: missionProgressNumber[MissionState.NeedComplete],
    index: 2,
  },
  LION: {
    mission: missonNumber[MissionOrder.Mission_3],
    timing: missionProgressNumber[MissionState.InProgress],
    index: 3,
  },
  PENGUIN: {
    mission: missonNumber[MissionOrder.Mission_3],
    timing: missionProgressNumber[MissionState.InProgress],
    index: 3,
  },
  SHEEP: {
    mission: missonNumber[MissionOrder.Mission_5],
    timing: missionProgressNumber[MissionState.InProgress],
    index: 5,
  },
  GIRAFFE: {
    mission: missonNumber[MissionOrder.Mission_6],
    timing: missionProgressNumber[MissionState.NeedComplete],
    index: 6,
  },
  // TODO: アンロック: storyで確認しながら調整します
  WOLF: {
    mission: 99,
    timing: 99,
    index: 7,
  },
};

export const getUnlockStatuses = (
  missionOrder: MissionOrder,
  missionState: MissionState
): {
  icon: UserIconType;
  status: UnlockIconStatus;
}[] => {
  if (missionState === "BEFORE_FIRST_MISSION") {
    return Object.keys(iconUnlockTiming).map((icon: UserIconType) => {
      let status: UnlockIconStatus;
      switch (icon) {
        case UserIconType.Worm:
          status = "UNLOCKED";
          break;
        case UserIconType.Dinosaur:
          status = "UNLOCKED_NEXT";
          break;
        default:
          status = "LOCKED";
      }
      return { icon: icon, status: status };
    });
  }

  if (missionState === "ALL_OVER") {
    return Object.keys(iconUnlockTiming).map((icon: UserIconType) => {
      return { icon: icon, status: "UNLOCKED" };
    });
  }

  const currentMissionOrder = missonNumber[missionOrder];
  const currentMissionState = missionProgressNumber[missionState];

  let lastUnlockIconIndex = Object.keys(iconUnlockTiming).length;
  for (const icon of Object.keys(iconUnlockTiming)) {
    const unlockCondition = iconUnlockTiming[icon as UserIconType];
    if (
      currentMissionOrder < unlockCondition.mission ||
      (currentMissionOrder === unlockCondition.mission &&
        currentMissionState < unlockCondition.timing)
    ) {
      lastUnlockIconIndex = Object.keys(iconUnlockTiming).indexOf(icon);
      break;
    }
  }

  return Object.keys(iconUnlockTiming).map((icon: UserIconType) => {
    if (iconUnlockTiming[icon].index < lastUnlockIconIndex) {
      return { icon: icon, status: "UNLOCKED" };
    }
    if (iconUnlockTiming[icon].index > lastUnlockIconIndex) {
      return { icon: icon, status: "LOCKED" };
    }
    return { icon: icon, status: "UNLOCKED_NEXT" };
  });
};
