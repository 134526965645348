import React, { useCallback, useState } from "react";
import { KansouPreviewTemplate } from "~/components/templates/PostReadLog/KansouPreview";
import { usePostReadLogState } from "../usePostReadLogState";
import {
  ReadStatusForPostReadLog,
  TimeRange,
  useGetMyRecordQuery,
} from "~/generated/graphql";
import { AllEmotionStates } from "../reducer";
import { REVIEW_DATA_KEY } from "./EnterReview";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { KansouEditModal } from "~/components/organisms/modals/KansouSupportChat/KansouEditModal";

type KansouPreviewProps = {
  post: () => void;
  loading: boolean;
  nickname: string;
};

type State = {
  bookISBN: string;
  readStatus: ReadStatusForPostReadLog;
  like: number;
  difficulty: number;
  length: number;
  review: string;
  title: string;
  imageUrl: string;
  questionNumber: number | null;
  lastProgressNumber: number;
} & AllEmotionStates;

export const KansouPreview: React.FC<KansouPreviewProps> = ({
  post,
  loading,
  nickname,
}) => {
  const { state } = usePostReadLogState();
  const { currentUser } = React.useContext(CurrentUserContext);

  const {
    bookISBN,
    readStatus,
    like,
    difficulty,
    length,
    review,
    title,
    imageUrl,
    questionNumber,
    lastProgressNumber,
    ...emotions
  }: State = state;

  const [isOpenKansouEditModal, setIsOpenKansouEditModal] =
    useState<boolean>(false);
  const [currentReview, setCurrentReview] = useState<string>(review);

  const myRecord = useGetMyRecordQuery({
    variables: { timeRange: TimeRange.All },
  });

  const handleBack = (): void => {
    //
  };

  const setRecordToLocalStorage = (): void => {
    localStorage.setItem(
      "TemporarilyStoredReadingRecords",
      JSON.stringify(myRecord.data)
    );
    window.localStorage.removeItem(
      REVIEW_DATA_KEY + "_" + String(currentUser?.general.id) + "_" + bookISBN
    );
  };

  const handlePost = (event: React.MouseEvent): void => {
    event.preventDefault();
    if (myRecord == null || myRecord.data == null) {
      return;
    }
    setRecordToLocalStorage();
    post();
  };

  const onOpenKansouEditModal = useCallback(() => {
    setIsOpenKansouEditModal(true);
  }, []);

  const onCloseKansouEditModal = useCallback(() => {
    setIsOpenKansouEditModal(false);
  }, []);
  const onSubmitKansouEditModal = useCallback((review) => {
    setCurrentReview(review);
    setIsOpenKansouEditModal(false);
  }, []);

  const currentQuestionNumber = questionNumber ? questionNumber : 1;

  const progress = Math.floor(
    (currentQuestionNumber / lastProgressNumber) * 100
  );

  return (
    <>
      <KansouEditModal
        isOpen={isOpenKansouEditModal}
        review={currentReview}
        onClose={onCloseKansouEditModal}
        onSubmit={onSubmitKansouEditModal}
      />
      <KansouPreviewTemplate
        handleBack={handleBack}
        onClickGoNext={handlePost}
        loading={loading}
        progress={progress}
        nickname={nickname}
        title={title}
        readStatus={readStatus}
        like={like}
        difficulty={difficulty}
        length={length}
        emotionsObj={emotions}
        review={currentReview}
        imageUrl={imageUrl}
        onClickReviewRewrite={onOpenKansouEditModal}
      />
    </>
  );
};
