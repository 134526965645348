import React, { useEffect } from "react";
import { EnterReviewTemplate } from "~/components/templates/PostReadLog/EnterReview";
import { usePostReadLogState } from "../../usePostReadLogState";
import { useUnlock } from "~/store/unlock/useUnlock";
import { LockedEnterReviewTemplate } from "~/components/templates/PostReadLog/LockedEnterReview";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import emojiRegex from "emoji-regex";
import {
  ReviewValidationMessage,
  reviewValidationMessages,
} from "~/pages/PostReadLog/PostReadLogFlows/EnterReview/const";

export const REVIEW_DATA_KEY = "review_data";

export const EnterReview: React.FC = () => {
  const { currentUser } = React.useContext(CurrentUserContext);
  const {
    state: {
      bookISBN,
      title,
      review,
      like,
      difficulty,
      length,
      questionNumber,
      lastProgressNumber,
      wakuwaku,
      sikusiku,
      dokidoki,
      nikoniko,
      nattoku,
      waraeru,
      bikkuri,
      punpun,
      kowai,
      dousite,
    },
    dispatch,
  } = usePostReadLogState();
  const [hintModalOpen, setHintModalOpen] = React.useState(false);
  const [voiceModalOpen, setVoiceModalOpen] = React.useState(false);
  const emotionsObj = {
    wakuwaku,
    sikusiku,
    dokidoki,
    nikoniko,
    nattoku,
    waraeru,
    bikkuri,
    punpun,
    kowai,
    dousite,
  };

  const handleBack = (): void => {
    dispatch({
      type: "setQuestionNumber",
      payload: currentQuestionNumber - 1,
    });
    dispatch({
      type: "setFlow",
      payload: "kansouSelectWriting",
    });
    dispatch({
      type: "setReview",
      payload: "",
    });
  };
  const { isLocked } = useUnlock();
  const isEnterReviewLocked = isLocked("enterFreeReview");

  const handleNext = (): void => {
    dispatch({
      type: "setQuestionNumber",
      payload: currentQuestionNumber + 1,
    });
    dispatch({
      type: "setFlow",
      payload: "submitReadLog",
    });
  };

  const handleClickGoNext = (event: React.MouseEvent): void => {
    event.preventDefault();
    handleNext();
  };

  const handleClickGoNextWhenLocked = (event: React.MouseEvent): void => {
    event.preventDefault();
    dispatch({
      type: "setReview",
      payload: "",
    });
    handleNext();
  };

  const handleClickSkip = (event: React.MouseEvent): void => {
    event.preventDefault();
    dispatch({
      type: "setReview",
      payload: "",
    });
    handleNext();
  };

  useEffect(() => {
    const numberOfReview = review
      .replace(emojiRegex(), "$")
      .replace(/\n/g, "").length;
    const newErrorMessages: ReviewValidationMessage[] = [];
    if (numberOfReview > 1024) {
      newErrorMessages.push(reviewValidationMessages.maxLength);
    }
    if (review.match(/\n{3,}/)) {
      newErrorMessages.push(reviewValidationMessages.emptyLines);
    }
    if ((review.match(emojiRegex()) ?? "").length > numberOfReview / 2) {
      newErrorMessages.push(reviewValidationMessages.tooManyEmojis);
    }
    setErrorMessages(newErrorMessages);
  }, [review]);

  const setReview = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    try {
      window.localStorage.setItem(
        REVIEW_DATA_KEY +
          "_" +
          String(currentUser?.general.id) +
          "_" +
          bookISBN,
        event.target.value
      );
    } catch (e) {
      console.error(e);
    }

    dispatch({
      type: "setReview",
      payload: event.target.value,
    });
  };

  const openHintModal = (): void => {
    setHintModalOpen(true);
  };
  const closeHintModal = (): void => {
    setHintModalOpen(false);
  };
  const openVoiceModal = (event: React.MouseEvent): void => {
    event.preventDefault();
    setVoiceModalOpen(true);
  };
  const closeVoiceModal = (event: React.MouseEvent): void => {
    event.preventDefault();
    setVoiceModalOpen(false);
  };

  const currentQuestionNumber = questionNumber ? questionNumber : 1;
  const progress = Math.floor(
    (currentQuestionNumber / lastProgressNumber) * 100
  );

  React.useEffect(() => {
    try {
      const reviewData = window.localStorage.getItem(
        REVIEW_DATA_KEY + "_" + String(currentUser?.general.id) + "_" + bookISBN
      );
      if (reviewData) {
        dispatch({
          type: "setReview",
          payload: reviewData,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const [errorMessages, setErrorMessages] = React.useState<
    ReviewValidationMessage[]
  >([]);

  return (
    <>
      {isEnterReviewLocked ? (
        <LockedEnterReviewTemplate
          progress={progress}
          bookTitle={title}
          like={like}
          difficulty={difficulty}
          length={length}
          emotionsObj={emotionsObj}
          handleBack={handleBack}
          onClickGoNext={handleClickGoNextWhenLocked}
        />
      ) : (
        <EnterReviewTemplate
          progress={progress}
          bookTitle={title}
          like={like}
          difficulty={difficulty}
          length={length}
          emotionsObj={emotionsObj}
          handleBack={handleBack}
          onClickGoNext={handleClickGoNext}
          goNextButtonDisabled={review == "" || errorMessages.length > 0}
          onClickSkip={handleClickSkip}
          skipButtonDisabled={review != ""}
          value={review}
          onChange={setReview}
          errorMessages={errorMessages}
          voiceModalOpen={voiceModalOpen}
          onClickVoiceModalOpen={openVoiceModal}
          onClickVoiceModalClose={closeVoiceModal}
          hintModalOpen={hintModalOpen}
          onClickHintModalOpen={openHintModal}
          onClickHintModalClose={closeHintModal}
        />
      )}
    </>
  );
};
