import { MissionOrder } from "~/generated/graphql";

type MissionDisplayMaterial = {
  number: number;
  storyTitle: TranslateKeys;
  storyImgUrl: string;
  missionImgUrl: string;
};

const baseDir = "/img/mission";

export const missionOrderToDisplayMaterial: Record<
  MissionOrder,
  MissionDisplayMaterial
> = {
  [MissionOrder.Mission_1]: {
    number: 1,
    storyTitle: "冒険のはじまり",
    storyImgUrl: `${baseDir}/start_story/story_1/scene_1.jpg`,
    missionImgUrl: `${baseDir}/mission_modal/mission_1.jpeg`,
  },
  [MissionOrder.Mission_2]: {
    number: 2,
    storyTitle: "おもいえがくのワザ",
    storyImgUrl: `${baseDir}/start_story/story_2/scene_5.jpg`,
    missionImgUrl: `${baseDir}/mission_modal/mission_2.jpeg`,
  },
  [MissionOrder.Mission_3]: {
    number: 3,
    storyTitle: "新たな仲間たち",
    storyImgUrl: `${baseDir}/start_story/story_3/scene_1.jpeg`,
    missionImgUrl: `${baseDir}/mission_modal/mission_3.jpeg`,
  },
  [MissionOrder.Mission_4]: {
    number: 4,
    storyTitle: "つなげるのワザ",
    storyImgUrl: `${baseDir}/start_story/story_4/scene_1.jpeg`,
    missionImgUrl: `${baseDir}/mission_modal/mission_4.jpeg`,
  },
  [MissionOrder.Mission_5]: {
    number: 5,
    storyTitle: "ピンクのキリン",
    storyImgUrl: `${baseDir}/complete_story/story_5/scene_7.jpeg`,
    missionImgUrl: `${baseDir}/mission_modal/mission_5.jpeg`,
  },
  [MissionOrder.Mission_6]: {
    number: 6,
    storyTitle: "しつもんするのワザ",
    storyImgUrl: `${baseDir}/start_story/story_6/scene_5.jpeg`,
    missionImgUrl: `${baseDir}/mission_modal/mission_6.jpeg`,
  },
};
