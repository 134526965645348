import { ReadStatus, UserIconType } from "~/generated/graphql";
import { ReadLogMessageProps } from "~/components/organisms/bookFriendMessage/BookFriendMessageListItem/ReadLogMessage";

export const dummyReadLogMessageFirstProps: ReadLogMessageProps = {
  onReaction: (): Promise<boolean> => Promise.resolve(true),
  onWantReadBook: (): Promise<boolean> =>
    new Promise((resolve) => {
      resolve(true);
    }),
  onCompleteShowSenseiMessage: (): void => {},
  item: {
    id: "0",
    createdAt: "2021-06-04T00:10:01Z",
    user: {
      id: 1,
      nickname: "ニックネーム",
      userIconType: UserIconType.Lion,
    },
    readLogInfo: {
      readLogId: 1,
      book: {
        title: "あたりかも",
        author: "きたじまこうき",
        isbn: "0",
        imageUrl: "https://m.media-amazon.com/images/I/91E+71cbXNL._SY522_.jpg",
      },
      review: "いえの れいぞうこも こおりのくにに つながっていたら いいのに。",
      like: 23,
      status: ReadStatus.Reading,
      isMyWantReadBook: false,
    },

    myReaction: {
      reactionType: [],
    },
  },
  user: {
    id: 0,
    nickname: "",
    userIconType: UserIconType.Lion,
    __typename: "User",
  },
  userType: "user",
  onOpenSendStarModal: (): void => {},
  canSendStar: false,
  isShowSendStarMessage: false,
  sendStarMessageProps: {
    onCompleted: (): void => {},
  },
};

export const dummyReadLogMessageSecondProps: ReadLogMessageProps = {
  onReaction: (): Promise<boolean> => Promise.resolve(true),
  onWantReadBook: (): Promise<boolean> =>
    new Promise((resolve) => {
      resolve(true);
    }),
  onCompleteShowSenseiMessage: (): void => {},
  item: {
    id: "111",
    createdAt: "2021-05-25T00:10:01Z",
    user: {
      id: 0,
      nickname: "ニックネーム",
      userIconType: UserIconType.Lion,
    },
    readLogInfo: {
      readLogId: 1,
      book: {
        title: "宇宙人のいる教室",
        author: "さとうまさこ",
        isbn: "0",
        imageUrl: "https://m.media-amazon.com/images/I/81uUjABWO4L._SY522_.jpg",
      },
      review:
        "なんで涙がもったいないっていったんだろう。短くなった7本の色鉛筆でわたしも虹をかいてみたい。",
      like: 23,
      status: ReadStatus.Reading,
      isMyWantReadBook: false,
    },
    myReaction: {
      reactionType: [],
    },
  },
  user: {
    id: 1,
    nickname: "",
    userIconType: UserIconType.Penguin,
    __typename: "User",
  },
  userType: "user",
  onOpenSendStarModal: (): void => {},
  canSendStar: false,
  isShowSendStarMessage: false,
  sendStarMessageProps: {
    onCompleted: (): void => {},
  },
};
