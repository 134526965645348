import React from "react";
import styled from "styled-components";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Row } from "~/components/atoms/layout/Row";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text } from "~/components/atoms/texts/Text";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { NavContainer, Wrapper } from "~/components/templates/Common";
import { PostReadLogBodyWrapper } from "../../Common/style";

export type SelectEnterMethodProps = {
  onClickScan: () => void;
  onClickManual: () => void;
  onBack: () => void;
};

export const SelectEnterMethod: React.FC<SelectEnterMethodProps> = ({
  onClickScan,
  onClickManual,
  onBack,
}) => {
  return (
    <>
      <NavContainer>
        <BackNavbar titleName="かんそうをかく" onClick={onBack} />
      </NavContainer>
      <Wrapper>
        <PostReadLogBodyWrapper>
          <Stack rowGap="24px" alignItems="center">
            <Text
              fontSize="LG"
              lineHeight="MD"
              bold
              fontColor="semantic.text.body"
            >
              本を追加しましょう
            </Text>
            <_ContentWrapper>
              <Text
                fontSize="SM"
                lineHeight="MD"
                fontColor="semantic.text.heading"
                bold
              >
                バーコードがあるとき
              </Text>
              <Row alignItems="center" columnGap="16px">
                <_Img src="/img/barcode.png" alt="バーコード" />
                <Text
                  fontSize="MD"
                  lineHeight="MD"
                  fontColor="semantic.text.body"
                >
                  カメラでバーコードを読み取って本を追加できます
                </Text>
              </Row>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LargeButton color="primary" onClick={onClickScan}>
                  バーコードで追加する
                </LargeButton>
              </div>
            </_ContentWrapper>
            <_ContentWrapper>
              <Text
                fontSize="SM"
                lineHeight="MD"
                fontColor="semantic.text.heading"
                bold
              >
                バーコードが使えないとき
              </Text>
              <Text
                fontSize="MD"
                lineHeight="MD"
                fontColor="semantic.text.body"
              >
                ISBN（本の裏に書いてある、9で始まる13桁の数字）を入力すると追加できます。
              </Text>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LargeButton
                  color="primary"
                  onClick={onClickManual}
                  variant="blank"
                >
                  自分で追加する
                </LargeButton>
              </div>
            </_ContentWrapper>
          </Stack>
        </PostReadLogBodyWrapper>
      </Wrapper>
    </>
  );
};

const _ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primitive.monotone.m0};
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-radius: 16px;
`;

const _Img = styled.img`
  width: 120px;
  height: 126px;
`;
