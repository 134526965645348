import styled, { css } from "styled-components";
import { Heading1 } from "~/components/atoms/texts/Heading1";
import { ColorTheme } from "~/styles/design_token/color";
import { FontLineHeight, FontSize } from "~/styles/design_token/font";

export const postReadLogEmojiButtonStyle = {
  fontSize: "MD" as FontSize,
  lineHeight: "MD" as FontLineHeight,
  bold: true,
  color: "pri" as ColorTheme,
};

export const PostReadLogBarWrapper = styled.div`
  padding: 16px;
  @media screen and (min-width: 768px) {
    padding: 16px 120px;
  }
`;

export const PostReadLogHeading = styled(Heading1)`
  color: ${({ theme }) => theme.colors.tex.t800};
  text-align: center;
`;

export const PostReadLogCharacterImg = styled.img`
  height: 100px;
  @media screen and (min-width: 768px) {
    height: 140px;
  }
`;

export const PostReadLogMarginInterpolation = {
  SuperWide: css`
    margin: 48px 0;
    @media screen and (min-width: 400px) {
      margin: 60px 0;
    }
    @media screen and (min-width: 768px) {
      margin: 72px 0;
    }
  `,
  Wide: css`
    margin: 30px 0;
    @media screen and (min-width: 400px) {
      margin: 40px 0;
    }
    @media screen and (min-width: 768px) {
      margin: 48px 0;
    }
  `,
  Middle: css`
    margin: 20px 0;
    @media screen and (min-width: 400px) {
      margin: 36px 0;
    }
    @media screen and (min-width: 768px) {
      margin: 48px 0;
    }
  `,
  Narrow: css`
    margin: 12px 0;
    @media screen and (min-width: 400px) {
      margin: 24px 0;
    }
    @media screen and (min-width: 768px) {
      margin: 48px 0;
    }
  `,
};

export const PostReadLogBodyWrapper = styled.div`
  padding-top: 80px;
`;

export const PostReadLogErrorMessageCon = styled.div`
  padding-top: 4px;
`;
