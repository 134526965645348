import React, { useMemo } from "react";
import styled from "styled-components";
import { ConfirmLikeTemplateProps } from "./type";
import { EmojiButton } from "~/components/molecules/buttons/EmojiButton";
import { ProgressBar } from "~/components/atoms/ProgressBar";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { NavContainer, Wrapper } from "../../Common";
import {
  postReadLogEmojiButtonStyle,
  PostReadLogHeading,
  PostReadLogBodyWrapper,
  PostReadLogMarginInterpolation,
  PostReadLogCharacterImg,
} from "../Common/style";
import { PostReadLogChoiceType } from "../Common/type";
import { TFunction, useTranslation } from "~/i18n";

export const useLikeChoices = (t: TFunction): PostReadLogChoiceType[] => {
  const choices = useMemo(
    (): PostReadLogChoiceType[] => [
      { ja: t<TranslateKeys>("あまり好きではない"), emoji: "😓" }, //stateに保持される値は1
      { ja: t<TranslateKeys>("ちょっと好き"), emoji: "😗" }, //stateに保持される値は2
      { ja: t<TranslateKeys>("好き"), emoji: "😉" }, //stateに保持される値は3
      { ja: t<TranslateKeys>("とても好き"), emoji: "😋" }, //stateに保持される値は4
      { ja: t<TranslateKeys>("もういちど読みたい"), emoji: "😍" }, //stateに保持される値は5
    ],
    [t]
  );

  return choices;
};

export const ConfirmLikeTemplate: React.FC<ConfirmLikeTemplateProps> = ({
  progress,
  handleBack,
  onClicks,
}) => {
  const { t } = useTranslation();
  const likeChoices = useLikeChoices(t);

  return (
    <>
      <NavContainer>
        <BackNavbar titleName="かんそうをかく" onClick={handleBack} />
      </NavContainer>
      <Wrapper>
        <PostReadLogBodyWrapper>
          <ProgressCon>
            <ProgressBar progress={progress} backgroundColor="base.gray" />
          </ProgressCon>
          <PostReadLogHeading fontSize={"LG"} lineHeight={"MD"} bold>
            {t<TranslateKeys>("この本はどうだった？")}
          </PostReadLogHeading>
          <CharacterCon>
            <PostReadLogCharacterImg src="/img/sensei/standup_4.svg" />
          </CharacterCon>
          <ButtonCon>
            {onClicks.map((onClick, index) => (
              <EmojiButton
                key={index}
                emoji={likeChoices[index].emoji}
                text={likeChoices[index].ja}
                onClick={onClick}
                {...postReadLogEmojiButtonStyle}
              />
            ))}
          </ButtonCon>
        </PostReadLogBodyWrapper>
      </Wrapper>
    </>
  );
};

const CharacterCon = styled.div`
  ${PostReadLogMarginInterpolation.Narrow}
  display: flex;
  justify-content: center;
`;

const ProgressCon = styled.div`
  margin-bottom: 20px;
`;

const ButtonCon = styled.div`
  ${PostReadLogMarginInterpolation.Narrow}
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  & > * {
    margin: ${({ theme }) => theme.size.XS} 0;
  }
`;
