import React, { FC } from "react";
import styled from "styled-components";
import { SelectBookTemplateProps } from "./type";
import { NavContainer, Wrapper } from "../../Common";
import { PostReadLogHeading, PostReadLogBodyWrapper } from "../Common/style";
import { BackNavbar } from "~/components/molecules/navbars/BackNavbar";
import { SelectBookImgList } from "~/components/organisms/PostReadLog/SelectBookImgList";
import { useTranslation } from "~/i18n";

export const SelectBookTemplate: FC<SelectBookTemplateProps> = ({
  handleBack,
  currentCount,
  recommendData,
  wantReadBookData,
  itemTotal,
  itemsPerPage,
  items,
  onPageChange,
  renderEmptyComponent,
  onClickRecommend,
  onClickWantReadBook,
  onClickOtherBooks,
}) => {
  const { t } = useTranslation();
  const getColumnsNumber = (): number => {
    if (window.innerWidth > 550) {
      return 3;
    } else {
      return 2;
    }
  };
  return (
    <>
      <NavContainer>
        <BackNavbar titleName="かんそうをかく" onClick={handleBack} />
      </NavContainer>
      <Wrapper>
        <PostReadLogBodyWrapper>
          <PagiableListWrapper>
            <SelectBookHeading fontSize={"LG"} lineHeight={"MD"} bold>
              {t<TranslateKeys>("どの本の感想をかきますか？")}
            </SelectBookHeading>
            <SelectBookImgList
              currentCount={currentCount}
              recommendData={recommendData}
              wantReadBookData={wantReadBookData}
              itemTotal={itemTotal}
              itemsPerPage={itemsPerPage}
              items={items}
              onPageChange={onPageChange}
              renderEmptyComponent={renderEmptyComponent}
              onClickRecommend={onClickRecommend}
              onClickWantReadBook={onClickWantReadBook}
              onClickOtherBooks={onClickOtherBooks}
              columnsNumber={getColumnsNumber()}
            />
          </PagiableListWrapper>
        </PostReadLogBodyWrapper>
      </Wrapper>
    </>
  );
};

const SelectBookHeading = styled(PostReadLogHeading)``;

const PagiableListWrapper = styled.div`
  padding: 20px 0;
`;
