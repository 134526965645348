import React, { useCallback } from "react";
import { RemoveRecommendTemplate } from "~/components/templates/RemoveRecommend";
import { RemoveReason, useRemoveRecommendMutation } from "~/generated/graphql";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ReadStatusForPostReadLog } from "~/generated/graphql";
import { makeVar, useReactiveVar } from "@apollo/client";
import { isShowGotoYlReassesmentToastVar } from "../YLReassesmentEnquete";

export type EntryPageOfRemoveRecommend = "bookShelf" | "bookListViewer";
const linkRecord: Record<EntryPageOfRemoveRecommend, string> = {
  bookListViewer: "/",
  bookShelf: "/",
};

export const entryPageOfRemoveRecommendVar =
  makeVar<EntryPageOfRemoveRecommend>("bookShelf");

export const RemoveRecommend: React.FC = () => {
  const entryPage = useReactiveVar(entryPageOfRemoveRecommendVar);
  const [removeRecommend, { loading }] = useRemoveRecommendMutation({
    onCompleted: () => {
      navigate(linkRecord[entryPage]);
    },
  });

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const ISBN = searchParams.get("ISBN");
  const title = searchParams.get("title");

  const { recommendId } = useParams<{
    recommendId: string;
  }>();

  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  const handleRemoveRecommend = useCallback(
    (removeReason: RemoveReason): Promise<boolean> => {
      return new Promise((resolve) => {
        removeRecommend({
          variables: {
            recommendID: Number(recommendId),
            removeReason: removeReason,
          },
        })
          .then((res) => {
            if (res.errors || !res.data) {
              resolve(false);
            }
            if (
              removeReason === "TOO_EASY" ||
              removeReason === "TOO_DIFFICULT"
            ) {
              isShowGotoYlReassesmentToastVar(true);
            }
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    [recommendId]
  );

  if (!ISBN || !recommendId) {
    navigate(entryPage);
    return null;
  }

  const handleGotoPostReadLog = useCallback(() => {
    const searchParams = new URLSearchParams({
      recommendID: recommendId,
      title: title ? title : "",
      ISBN: ISBN,
      readStatus: ReadStatusForPostReadLog.Reading,
      flow: "confirmLike",
    });
    navigate(`/postreadlog/init?${searchParams.toString()}`);
  }, [recommendId, title, ISBN]);

  return (
    <RemoveRecommendTemplate
      handleBack={handleBack}
      handleRemoveRecommend={handleRemoveRecommend}
      handleGotoPostReadLog={handleGotoPostReadLog}
      loading={loading}
    />
  );
};
