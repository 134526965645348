import React from "react";
import styled from "styled-components";
import { Interpolation } from "~/styles/interpolation";
import { LargeButton } from "~/components/atoms/buttons/Button";
import { Stack } from "~/components/atoms/layout/Stack";
import { Text as BaseText } from "~/components/atoms/texts/Text";
import { PresentImage } from "~/components/atoms/images/PresentImage";
import { KansouSupportChatMessage } from "~/components/organisms/PostReadLog/KansouSupportChatMessage";
import { MessageRoleEnum } from "~/helpers/constants/kansouSupportChat";
import { useTranslation } from "~/i18n";

export type KansouSupportChatPresentProps = {
  imageUrl: string;
  kansou: string;
  nickname: string;
  onNext: () => void;
};

export const KansouSupportChatPresent: React.FC<
  KansouSupportChatPresentProps
> = ({ imageUrl, kansou, nickname, onNext }) => {
  const { t } = useTranslation();

  return (
    <>
      <Header alignItems="center" rowGap="12px">
        <PresentImage imageUrl={imageUrl} />
        <Text
          fontSize="LG"
          lineHeight="MD"
          bold
          fontColor="primitive.monotone.m900"
        >
          {t<TranslateKeys>("{{nickname}}さんに折り紙のプレゼント！", {
            nickname,
          })}
        </Text>
        <Text fontSize="SM" lineHeight="MD" fontColor="primitive.monotone.m500">
          {t<TranslateKeys>("感想をもとに、こんな折り紙を作ってみたよ！")}
        </Text>
      </Header>
      <Content alignItems="center" rowGap="16px">
        <_Container rowGap="12px">
          <Text
            fontSize="LG"
            lineHeight="EQ"
            bold
            fontColor="primitive.monotone.m900"
          >
            {t<TranslateKeys>("ペンギンせんぱいと書いた感想")}
          </Text>
          <KansouSupportChatMessage role={MessageRoleEnum.assistant}>
            {t<TranslateKeys>("一緒にお話しながら作った感想だよ！")}
          </KansouSupportChatMessage>
          <Text
            fontSize="SM"
            lineHeight="MD"
            fontColor="primitive.monotone.m500"
          >
            {kansou}
          </Text>
        </_Container>
        <LargeButton onClick={onNext}>つぎへ</LargeButton>
      </Content>
    </>
  );
};

const Header = styled(Stack)`
  padding: ${({ theme }) => theme.size.XL};
  background-color: ${({ theme }) => theme.colors.base.white};
`;
const Content = styled(Stack)`
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.size.L} 0;
  background: ${({ theme }) => theme.colors.base.background};
`;

const Text = styled(BaseText)`
  white-space: break-spaces;
`;

const _Container = styled(Stack)`
  ${Interpolation.Border}
  width: 100%;
  border-radius: ${({ theme }) => theme.deprecatedBorderRadius.MD};
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: ${({ theme }) => theme.size.M};
`;
